import React from 'react';
import PropTypes from 'prop-types';

const HiddenField = ({ source, record }) => (
  <span
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: `<!-- ${record && record[source]} -->`,
    }}
  />
);

HiddenField.propTypes = {
  record: PropTypes.any,
  source: PropTypes.string,
};

export default HiddenField;

import CompleteIcon from '@material-ui/icons/AssignmentInd';
import NotStatusedIcon from '@material-ui/icons/Assignment';
import VerifiedIcon from '@material-ui/icons/AssignmentTurnedIn';

export default {
  apiUrl: 'http://w3.complan.net/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
};

const siteConfig = {
  siteName: 'WinPCS',
  siteIcon: 'ion-flash',
  dashboard: '/dashboard',
  footerText: 'WinPCS ©2020 Created by Complan USA, LLC',
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';
const langDir = 'ltr';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: '',
};
const jwtConfig = {
  enabled: true,
  fetchUrl: 'http://localhost:5000/login',
  secretKey: 'secretKey',
};
const googleConfig = {
  apiKey: '',
};
const youtubeSearchApi = '';

const getDomain = () => {
  if (process.env.APP_DOMAIN || process.env.RENDER_EXTERNAL_HOSTNAME) {
    return (process.env.APP_DOMAIN || process.env.RENDER_EXTERNAL_HOSTNAME)
      .replace('https://', '')
      .replace('http://', '');
  }

  return process.env.NODE_ENV === 'production'
    ? 'winpcs3.complan.net'
    : `dev.complan.net:${process.env.PORT || 3000}`;
};

const couchConfig = {
  // 3001 for websocket, 3000 for http
  // domain: 'e7ee40c5-d7de-4abb-991c-0034c77d39ae-bluemix:a50528bf653bd7e1a0a9eb92eca797535b624580484eada4c8f0e7c355d63219@e7ee40c5-d7de-4abb-991c-0034c77d39ae-bluemix.cloudant.com',
  domain: getDomain(),
  // ws for websocket --- http for http
  couchType: 'http',
  transport: process.env.LOCAL_SERVER_TRANSPORT || 'https',
  // no postfix for websocket
  // postfix: '',
  postfix: '/db',
};

const coreAppServiceConfig = {
  host: process.env.CORE_APP_SERVICE_HOST,
  port: process.env.CORE_APP_SERVICE_PORT,
  transport: process.env.CORE_APP_SERVICE_TRANSPORT || 'https', // Defaults to 'https' may override it with `http` or other.
  rootDomain: process.env.CORE_APP_SERVICE_ROOT_DOMAIN || undefined,
};

const awsCoudWatchLogConfig = {
  enable: process.env.AWS_CLOUDWATCH_LOG_ENABLE || false,
};

// TODO: manual url build - see below.
const coreAppServiceUrl = (path, querystring = '') => {
  const { transport, host, rootDomain, port } = coreAppServiceConfig;

  // Template : transport://host.root-domain:port/path/query
  const retval = `${transport}://${host}${rootDomain ? `.${rootDomain}` : ''}${
    port !== '-1' ? `:${port}` : ''
  }${path ? `/${path}` : ''}${querystring ? `?${querystring}` : ''}`;

  console.log(`coreAppServiceUrl retval: ${retval}`);
  return retval;
};

/**
 * TODO : proper code using URLSearchParams, did not work probably because of webpack targeting an older browser version where this api has not yet been introduced - review.
 * const coreAppServiceUrl = (path, query = {}) => {
  const { transport, host, rootDomain, port } = coreAppServiceConfig;

  // Template : transport://host.root-domain:port/path/query
  const baseUrlString = `${transport}://${host}${
    rootDomain ? `.${rootDomain}` : ''
  }`;
  const url = new URL(`${baseUrlString}`);

  if (port !== '-1') {
    url.port = port;
  }

  url.pathname = path;

  const URLSearchParams = new URLSearchParams(query);
  url.search = URLSearchParams.toString();

  const urlString = url.toString();
  console.log(`coreAppServiceUrl retval: ${urlString}`);
  return urlString;
};
 */

const winpcsConfig = {
  dates: {
    dateFormat: 'MM/DD/YYYY',
  },
  hierarchy: {
    defaultDisplayStyle: 'list',
    displayChoices: [
      {
        id: 'list',
        name: 'pos.hierarchy.displayChoices.list',
      },
      {
        id: 'tile',
        name: 'pos.hierarchy.displayChoices.tile',
      },
      {
        id: 'tree',
        name: 'pos.hierarchy.displayChoices.tree',
      },
    ],
    displayPrefix: {
      autopl: 'Punch',
      checksheet: 'Checksheet',
    },
  },
  droneDeploy: {
    dashboardIframe: {
      component: 'Drone Status',
      width: '100%',
      height: '700px',
      frameBorder: '0',
      src:
        process.env.DRONEDEPLOY_IFRAME_URL ||
        'https://www.dronedeploy.com/app2/data/5c017725036681333ff7c6cc;jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1YzAxNzcyNTAzNjY4MTMzM2ZmN2M2Y2MiLCJzY29wZSI6WyI4NTQwYjFiZDRlXzBGMjYyRkIyNDlPUEVOUElQRUxJTkUiXSwidHlwZSI6IlJlYWRPbmx5UGxhbiJ9.CQmPtTl90GHOmITGKpyIBEw8XvLckKnmq1jWGa2JZJKenthbesbMKkqY33gDkG_oVD7kz3zTtWJVmwP4uiD_kw',
    },
  },
  powerBIDeploy: {
    powerIframe: {
      component: 'Power BI',
      width: '100%',
      height: '700px',
      frameBorder: '0',
      allowFullScreen: true,
      src:
        'https://app.powerbi.com/view?r=eyJrIjoiYTYwZTlmOTUtNmU4Zi00YmY2LWIzNjEtNzIwZDI3MzgzNjkwIiwidCI6ImZlZjQ5MTU3LTRlZDItNDA3Mi05YWFkLTRjM2E2MGIwNTAyNSIsImMiOjN9',
    },
  },
  preservation: {
    defaultDisplayStyle: 'due',
    phistory: {
      fieldCompleteAction: 'DONE',
    },
    dates: {
      donetimeFormat: 'HH:mm:ss.SSS',
    },
    statusCodes: {
      stop: 0,
      start: 1,
      done: 2,
      doneAndStopped: 3,
      fieldComplete: 4,
    },
    statusCodesByInt: {
      0: 'stop',
      1: 'start',
      '1-late': 'start',
      '1-future': 'start',
      2: 'done',
      3: 'doneAndStopped',
      4: 'fieldComplete',
    },
    statusColors: {
      0: 'grey',
      1: 'green',
      '1-late': 'yellow',
      '1-future': 'white',
      2: 'orange',
      3: 'grey',
      4: 'orange',
    },
    icons: {
      // TODO: Update icons for stop, start, done, doneAndStopped
      stop: 'BMPRESNS',
      start: 'BMPRESMC',
      done: 'BMPRESSC',
      doneAndStopped: 'BMPRESCS',
      fieldComplete: 'BMPRESFC',
    },
    reactIcons: {
      0: NotStatusedIcon,
      1: NotStatusedIcon,
      '1-late': NotStatusedIcon,
      '1-future': NotStatusedIcon,
      2: CompleteIcon,
      3: VerifiedIcon,
      4: NotStatusedIcon,
    },
    statusOptions: [
      // Date is not past due
      { id: 0, name: 'resources.conpmr.status.stop' },
      { id: 1, name: 'resources.conpmr.status.start' },
      { id: 2, name: 'resources.conpmr.status.done' },
      { id: 3, name: 'resources.conpmr.status.doneAndStopped' },
      { id: 4, name: 'resources.conpmr.status.fieldComplete' },
    ],
    displayChoices: [
      {
        id: 'due',
        name: 'pos.preservation.displayChoices.due',
      },
      {
        id: 'future',
        name: 'pos.preservation.displayChoices.future',
      },
      {
        id: 'all',
        name: 'pos.preservation.displayChoices.all',
      },
    ],
  },
  checksheet: {
    defaultDisplayStyle: 'library',
    displayChoices: [
      {
        id: 'library',
        name: 'pos.checksheet.displayChoices.library',
      },
      {
        id: 'custom-question',
        name: 'pos.checksheet.displayChoices.custom',
      },
      {
        id: 'all',
        name: 'pos.checksheet.displayChoices.all',
      },
    ],
    statusOptions: [
      { id: 0, name: 'resources.autopl.status.incomplete' },
      { id: 3, name: 'resources.autopl.status.complete' },
    ],
    statusCodes: {
      punched: 0,
      verified: 9,
      complete: 3,
    },
    statusColors: {
      0: 'red',
      // TODO: Use yellow for complete but not verified?
      3: 'green',
      9: 'green',
    },
  },
  punch: {
    // TODO: Numbers instead of characters???
    statusCodes: {
      punched: 0,
      verified: 9,
      complete: 3,
    },
    statusColors: {
      0: 'red',
      // TODO: Use yellow for complete but not verified?
      3: 'green',
      9: 'green',
    },
    icons: {
      completeIcon: 'AUTOPL3+',
    },
    reactIcons: {
      0: NotStatusedIcon,
      3: CompleteIcon,
      9: VerifiedIcon,
    },
    typeOptions: [
      { id: 'A', name: 'A' },
      { id: 'B', name: 'B' },
      { id: 'C', name: 'C' },
    ],
    statusOptions: [
      { id: 0, name: 'resources.autopl.status.incomplete' },
      { id: 3, name: 'resources.autopl.status.complete' },
    ],
  },
  sync: {
    masterTimeoutMs: 240000,
  },
  conChecksheetEquipment: {
    displayChoices: [
      {
        id: 'checksheet',
        name: 'pos.conChecksheetEquipment.displayChoices.checksheet',
      },
      {
        id: 'tag_no',
        name: 'pos.conChecksheetEquipment.displayChoices.tagNo',
      },
    ],
  },
  tables: {
    punch: {
      name: 'autopl',
    },
    system: {
      name: 'certsys',
    },
    subsystem: {
      name: 'certac1',
      itemfld: 'system_no',
    },
    core: {
      name: 'core',
      itemfld: 'itemid',
    },
    conpmr: {
      name: 'conpmr',
    },
    phistory: {
      name: 'phistory',
    },
    refpmr: {
      name: 'refpmr',
    },
  },
  repositoriesPriorities: {
    default: 1500,
    autopl: 1100,
    preservation: 1100,
    checksheet_: 1100,
    core: 1000,
    symt: 1000,
  },
};

const checksheetConfig = {
  tables: {
    checksheetQuestions: {
      name: 'checksheet_questions',
      title: 'Questions',
    },
    checksheetHeaders: {
      name: 'checksheet_headers',
      title: 'Headers',
    },
    checksheetFooters: {
      name: 'checksheet_footers',
      title: 'Footers',
    },
    checksheetForm: {
      name: 'checksheet_form',
      title: 'Forms',
    },
    conChecksheetForm: {
      name: 'con_checksheet_form',
    },
    conChecksheet: {
      name: 'con_checksheet',
    },
    checksheet: {
      name: 'checksheet',
      title: 'Checksheet',
    },
    checksheetValue: {
      name: 'checksheet_values',
    },
    engequip: {
      name: 'engequip',
    },
    rconn: {
      name: 'rconn',
    },
    conChecksheetEquipment: {
      name: 'con_checksheet_equipment',
    },
  },
  itemType: {
    signature: 'Signature',
    camera: 'Camera',
    download: 'Download',
    datePicker: 'DatePicker',
    checkboxes: 'Checkboxes',
    radioButtons: 'RadioButtons',
    rating: 'Rating',
    tags: 'Tags',
    textInput: 'TextInput',
    numberInput: 'NumberInput',
    textArea: 'TextArea',
    dropdown: 'Dropdown',
    range: 'Range',
    header: 'Header',
    label: 'Label',
    paragraph: 'Paragraph',
    lineBreak: 'LineBreak',
    hyperLink: 'HyperLink',
    all: 'all',
    custom_question: 'custom-question',
    library: 'library',
    name: 'name',
    due: 'due',
    future: 'future',
    list: 'list',
    tree: 'tree',
    tile: 'tile',
  },
  viewEditHideType: {
    show: 'show',
    edit: 'edit',
    hide: 'hide',
  },
  dataExportHeaders: {
    checksheet: 'Checksheet',
    tag: 'Tag',
    status: 'Status',
    name: 'Name',
    created_On: 'Created_On',
    created_By: 'Created_By',
    updated_On: 'Updated_On',
    updated_By: 'Updated_By',
    element_type: 'Element_Type',
  },
  labelSourceType: {
    connect: 'Connect',
    status: 'Status',
    checksheet: 'Checksheet',
    left_rserial: 'left_rserial',
    name: 'name',
    right_rserial: 'right_rserial',
    tag: 'Tag',
    tag_no: 'tag_no',
    search_name: '__search__name',
    display_style: '__display_style__',
    element_type: 'element_type',
    search: 'search',
    start: 'start',
    tagNum: 'Tag No.',
    type: 'type',
  },
  sourceType: {
    name: 'name',
    created_On: 'created_on',
    created_By: 'created_by',
    updated_On: 'updated_on',
    updated_By: 'updated_by',
    element_type: 'element_type',
    left_rserial: 'left_rserial',
  },
  labelType: {
    signature: 'Signature',
    icon: 'Icons list',
    relatedIcon: 'Related Icon',
    placeholder_label: 'Placeholder Label',
    display_name: 'Display Name',
  },
  targetType: {
    right_rserial: 'right_rserial',
    refId: 'refId',
  },
  contentType: {
    placeholderText: 'Placeholder Text...',
  },
  nameType: {
    signature: 'Signature',
    camera: 'Camera',
    download: 'Download',
    datePicker: 'DatePicker',
    checkboxes: 'Checkboxes',
    radioButtons: 'RadioButtons',
    rating: 'Rating',
    header_text: 'Header Text',
    label: 'Label',
    paragraph: 'Paragraph',
    line_break: 'Line Break',
    dropdown: 'Dropdown',
    tags: 'Tags',
    multiple_choice: 'Multiple Choice',
    single_selection: 'Single Selection',
    text_input: 'Text Input',
    number_input: 'Number Input',
    multi_line_input: 'Multi-line Input',
    web_site: 'Web site',
    file_attachment: 'File Attachment',
    range: 'Range',
  },
  fieldType: {
    input: 'input',
  },
  changeText: {
    element_type: 'element_type',
    type: 'type',
    description: 'description',
  },
  headingType: {
    header: 'Header',
    question: 'Question',
    footer: 'Footer',
    form: 'Form',
    checksheet: 'Checksheet',
  },
};

const getChecksheetConfigTablesNames = () => {
  const { tables } = checksheetConfig;
  const checksheetTables = tables ? Object.keys(tables) : [];

  const checksheetTablesNames = checksheetTables.map(tableKey => {
    const table = tables[tableKey];
    const { name } = table;
    return name;
  });

  return checksheetTablesNames;
};

const checksheetUrlConfig = [
  {
    displayName: 'question',
    pathname: '/checklist/',
  },
  {
    displayName: 'header',
    pathname: '/checklist/',
    search: '?type=header',
  },
  {
    displayName: 'footer',
    pathname: '/checklist/',
    search: '?type=footer',
  },
  {
    displayName: 'form',
    pathname: '/checklist/',
    search: '?type=form',
  },
  {
    displayName: 'checksheet',
    pathname: '/checklist/',
    search: '?type=checksheet',
  },
];

const checksheetSubModules = {
  HEADER: 'header',
  FOOTER: 'footer',
  QUESTION: 'question',
  FORM: 'form',
  CHECKSHEET: 'checksheet',
  LIBRARY: 'library',
  CUSTOM: 'custom-question',
};

export {
  siteConfig,
  language,
  themeConfig,
  AlgoliaSearchConfig,
  jwtConfig,
  googleConfig,
  youtubeSearchApi,
  langDir,
  couchConfig,
  coreAppServiceConfig,
  coreAppServiceUrl,
  awsCoudWatchLogConfig,
  winpcsConfig,
  checksheetConfig,
  checksheetUrlConfig,
  checksheetSubModules,
  getChecksheetConfigTablesNames,
  getDomain,
};

import noop from 'lodash/noop';

import { winpcsConfig } from '../../../isomorphic/config/index';
import { isLowerCase } from '../../../../utils/isLowerCase';

export const isHierarchyRootId = id =>
  id && !id.includes('+') && !id.includes(' ');

export const composeWinpcsId = (parent, table, rserial, id) => {
  const parentString = parent ? `${parent}_` : '';
  const finalId = id ? `+${id}` : '';
  isLowerCase(table, 'composeWinpcsId table');
  return `${parentString}${rserial}+${table.toLowerCase()}${finalId}`;
};

export const getHierPublishId = (doc, docTable, parent, itemfld) =>
  composeWinpcsId(parent, docTable, doc.id, doc[itemfld]);

export const createHierPublishDoc = (
  parent,
  doc,
  docTable,
  additionalFields = {},
) => {
  console.debug(
    `createHierPublishDoc: parent: ${parent} docTable: ${docTable} doc: `,
    doc,
    ' additionalFields: ',
    additionalFields,
  );
  isLowerCase(doc.itemfld, 'createHierPublishDoc doc.itemfld', true);
  isLowerCase(
    additionalFields.itemfld,
    'createHierPublishDoc additionalFields.itemfld',
    true,
  );
  isLowerCase(doc.itemdesfld, 'createHierPublishDoc doc.itemdesfld', true);
  isLowerCase(doc.itemdesflds, 'createHierPublishDoc doc.itemdesflds', true);
  isLowerCase(
    additionalFields.itemdesfld,
    'createHierPublishDoc additionalFields.itemdesfld',
    true,
  );
  isLowerCase(
    additionalFields.itemdesflds,
    'createHierPublishDoc additionalFields.itemdesflds',
    true,
  );
  const itemfld = (doc.itemfld || additionalFields.itemfld).toLowerCase();
  const id = getHierPublishId(doc, docTable, parent, itemfld);
  const desfld = (
    doc.itemdesflds ||
    doc.itemdesfld ||
    additionalFields.itemdesflds ||
    additionalFields.itemdesfld
  ).toLowerCase();
  if (!doc[itemfld]) {
    console.error(
      'createHierPublishDoc: doc[itemfld] is empty: ',
      doc,
      ' docTable: ',
      docTable,
      ' additionalFields: ',
      additionalFields,
    );
  }
  if (!doc[desfld]) {
    console.error(
      'createHierPublishDoc: doc[desfld] is empty: doc: ',
      doc,
      ' docTable: ',
      docTable,
      ' additionalFields: ',
      additionalFields,
    );
  }

  const prefixes = winpcsConfig?.hierarchy?.displayPrefix;

  const nodePrefix = prefixes?.[docTable] || '';
  let nodeName = nodePrefix;
  nodeName += doc[itemfld] ? ` ${doc[itemfld]}` : ' Unknown';
  nodeName += doc[desfld] ? ` - ${doc[desfld]}` : '';

  const nodeDisplay = `${doc[itemfld]}`;
  const nodeParent = parent;
  return {
    ...doc,
    id,
    nodeName,
    nodeParent,
    nodeDisplay,
    ...additionalFields,
  };
};

export const getHiernodeFromHiertree = hiertree => hiertree.split('_').pop('_');

// X:tablename - Do not show anything from the table
function xFilter(param, chain /* , context */) {
  const tablename = param;
  return { ...chain, itembase: { $ne: tablename } };
  // return chain.whereNot('itembase', '=', tablename);
}

// E:tablename - Show the records from the table but do not open any further ones
function eFilter(param, chain /* , context */) {
  // TODO: Implement
  const tablename = param;
  console.debug(`
    eFilter not implemented: E:tablename\n
    tablename: ${tablename}
  `);
  return chain;
}

// S:tablename - Allow double-clicks, otherwise treat it much the same as the E: ones
function sFilter(param, chain /* , context */) {
  // TODO: Implement
  const tablename = param;
  console.debug(`
    sFilter not implemented: S:tablename\n
    tablename: ${tablename}
  `);
  return chain;
}

// Y:tablename1>tablename2 - If we are at an object on tablename1, then don't show tablename2
function yFilter(param, chain /* , context */) {
  // TODO: Implement
  const [tablename1, tablename2] = param.split('>');
  console.debug(`
    yFilter not implemented: Y:tablename1>tablename2:\n
    tablename1: ${tablename1} tablename2: ${tablename2}
  `);
  return chain;
}

// L:tablename1+fieldname1=tablename2+fieldname2 - If we are on, or have already seen an object from table
// tablename1 that has fieldname1 set to some non-blank value, and later on in the hierarchy encounter another
// object tablename2 which has a non-blank value in fieldname2, we do not show this second object unless both
// of them have the same contents of their fieldnames. (this one turned out to be the hardest one; we have to
// remember that we have seen an object of either of the tablename, and the corresponding fieldname to check
// the other one).
function lFilter(param, chain /* , context */) {
  // TODO: Implement
  const [tablename1fieldname1, tablename2fieldname2] = param.split('=');
  const [tablename1, fieldname1] = tablename1fieldname1.split('+');
  const [tablename2, fieldname2] = tablename2fieldname2.split('+');
  console.debug(`
    lFilter not implemented: L:tablename1+fieldname1=tablename2+fieldname2\n
    tablename1fieldname1: ${tablename1fieldname1} tablename2fieldname2: ${tablename2fieldname2}\n
    tablename1: ${tablename1} fieldname1: ${fieldname1}\n
    tablename2: ${tablename2} fieldname2: ${fieldname2}
  `);
  return chain;
}

const filterTypes = {
  x: xFilter,
  y: yFilter,
  e: eFilter,
  s: sFilter,
  l: lFilter,
};

// Returns an array of functions that accept the previous PG function and add onto the chain
// X:CERTAREA X:CERTMC1 X:CERTMILE E:REFPO E:CONTRACT S:REFITR1 S:REFITR2 S:REFITR3 S:REFITR4 S:REFITR5 S:REFITR6
// S:REFITR7 S:REFFTC1 S:REFFTC2 S:REFFTC3 S:REFFTC4 S:REFFTC5 S:REFCOM1 S:REFCOM2 S:REFCOM3 S:AUTOPL S:AUTOCOW
// S:AUTOCERT S:AUTOQURY S:AUTOCCN S:AUTONOTE S:REFPMR S:REFITRS S:REFITRV S:REFITRH S:REFITRN Y:ENGEQUIP>ENGMAST
// E:WORKPACK
export const parseHiertopFilterString = hiertopFilter => {
  // We convert to lowercase because it was decided all tables should be lowercase in winpcs
  isLowerCase(hiertopFilter, 'parseHiertopFilterString hiertopFilter');
  const filters = hiertopFilter ? hiertopFilter.toLowerCase().split(' ') : [];
  const filterFunctions = filters.map(f => {
    const [type, param] = f.split(':');
    return (filterTypes[type] || noop).bind(null, param);
  });
  return filterFunctions;
};

export const getHiertopAndFilters = async (id, hiertopDb) => {
  // Get hiertop filters
  const beginningOfTableIndex = id.indexOf('+') + 1;
  const partial = id.substring(beginningOfTableIndex);
  const endOfTableIndex = partial.indexOf('+');
  const hiertop = partial.substring(
    0,
    endOfTableIndex === -1 ? partial.length - 1 : endOfTableIndex,
  );
  isLowerCase(hiertop, 'getHiertopAndFilters hiertop');
  const hiertopFilter = await hiertopDb
    .find({
      selector: { root: hiertop.toLowerCase() },
      limit: 1,
    })
    .then(({ warning, docs }) => {
      if (warning) {
        console.warn(warning);
      }

      if (docs && docs.length) {
        return docs[0].items;
      }

      return '';
    });
  const filters = parseHiertopFilterString(hiertopFilter);
  return {
    hiertop,
    filters,
  };
};

export const getRecordStartIndex = id => {
  if (!id) {
    return 0;
  }

  const index = id.lastIndexOf('_');
  return index === -1 ? 0 : index + 1;
};

export const getRemainingEndIndex = id => {
  if (!id) {
    return 0;
  }

  const index = id.lastIndexOf('_');
  return index === -1 ? 0 : index;
};

export const decomposeWinpcsHierarchyId = async (
  fullId,
  skipHiertop = false,
  getDatabase,
) => {
  console.debug(`decomposeWinpcsHierarchyId: ${fullId}`);
  // const { currentTableName, remainingIdAfterTableName } = getCurrentTableName(fullId);
  // const { rserial, remainingIdAfterRserial } = getCurrentRserial(remainingIdAfterTableName);
  // const remainingCurrent = remainingIdAfterRserial;
  const hiertopDb = getDatabase('hiertop').db;
  if (!fullId) {
    return {};
  }

  const current = fullId || '';
  const record = current.substring(
    getRecordStartIndex(current),
    current.length,
  );
  const split =
    record.match(/\+/g).length === 2 ? record.split('+') : [null, null, null];
  const [rserial, currentTableName, id] = split;
  const remainingCurrent = current.substring(0, getRemainingEndIndex(current));
  const { hiertop, filters } = skipHiertop
    ? { hiertop: '', filters: [] }
    : await getHiertopAndFilters(current, hiertopDb);
  console.debug(`getParentRecords: ${remainingCurrent}`);
  const parentRecords = remainingCurrent
    ? (remainingCurrent.includes('_')
        ? remainingCurrent.split('_')
        : [remainingCurrent]
      ).map(async i => decomposeWinpcsHierarchyId(i, true, getDatabase))
    : [];

  const retval = {
    currentTableName,
    rserial,
    id,
    remainingCurrent,
    hiertop,
    filters,
    parentRecords,
  };
  return retval;
};

// TODO: Refactor to not publish records 1-at-a-time or have the need for deferred publishing per document

// Returns true if there were 0 futures to log
export const logPublishFutures = (futures, prefix, skipEmptyLog) => {
  const futureCount = (futures || []).length;
  const recordList = (futures || []).map(f => f && f.id).join(' --- ');
  if ((skipEmptyLog && futureCount > 0) || !skipEmptyLog) {
    console.debug(
      `published ${futureCount} ${prefix}${
        futureCount > 0 ? `: ${recordList}` : ''
      }`,
    );
  }
  return !recordList || futureCount <= 0;
};

export const processItembaseRecord = (parent, rconnDoc, doc) => {
  isLowerCase(rconnDoc.itemfld, 'processItembaseRecord rconnDoc.itemfld', true);
  isLowerCase(
    rconnDoc.itemdesfld,
    'processItembaseRecord rconnDoc.itemdesfld',
    true,
  );
  isLowerCase(
    rconnDoc.itembase,
    'processItembaseRecord rconnDoc.itembase',
    true,
  );
  const itemfld = rconnDoc.itemfld.toLowerCase().trim();
  const itemdesfld = rconnDoc.itemdesfld.toLowerCase().trim();
  const newDoc = { ...doc, itemfld, itemdesfld };
  const docTable = rconnDoc.itembase.toLowerCase().trim();
  const publishDoc = createHierPublishDoc(parent, newDoc, docTable);
  return publishDoc;
};

export const findRserialTableMatch = (
  parentRecord,
  itembaseRserial,
  itembasePgTableName,
) => {
  // console.log('parentRecord: ', parentRecord);
  const { rserial, currentTableName } = parentRecord;
  isLowerCase(currentTableName, 'findRserialTableMatch currentTableName');
  isLowerCase(itembasePgTableName, 'findRserialTableMatch itembasePgTableName');
  return (
    rserial === itembaseRserial &&
    currentTableName.toLowerCase() === itembasePgTableName.toLowerCase()
  );
};

export const filterItembaseRserialRecords = (
  parentRecords,
  rserial,
  itembasePgTableName,
) =>
  parentRecords.length > 0
    ? parentRecords.findIndex(p =>
        findRserialTableMatch(p, rserial, itembasePgTableName),
      ) === -1
    : true;

export const batchQueryConbaseRecords = async (
  parent,
  rconnDoc,
  conbaseRecords,
  parentRecords,
  { boundRunGetMany },
) => {
  const prArray = await Promise.all(parentRecords);
  isLowerCase(rconnDoc.itembase, 'batchQueryConbaseRecords rconnDoc.itembase');
  isLowerCase(
    rconnDoc.conitemsecond,
    'batchQueryConbaseRecords rconnDoc.conitemsecond',
  );
  const itembasePgTableName = rconnDoc.itembase.toLowerCase().trim();
  const conitemsecond = rconnDoc.conitemsecond.toLowerCase().trim();
  const itembaseRserialArray = (
    conbaseRecords.map(c => c[conitemsecond]) || []
  ).filter(i => filterItembaseRserialRecords(prArray, i, itembasePgTableName));

  console.debug(
    `batchQueryConbaseRecords: itembasePgTableName: ${itembasePgTableName} conitemsecond: ${conitemsecond} itembaseRserialArray: `,
    itembaseRserialArray,
    ' for parentRecords: ',
    prArray,
    ' and rconnDoc: ',
    rconnDoc,
    ' and conbaseRecords: ',
    conbaseRecords,
    ' and parent: ',
    parent,
  );

  if (itembaseRserialArray.length === 0) {
    console.debug(
      `batchQueryConbaseRecords: itembasePgTableName: ${itembasePgTableName}: result was empty: `,
      itembaseRserialArray,
      ' for itembasePgTableName: ',
      itembasePgTableName,
      ' with conbaseRecords: ',
      conbaseRecords,
      ' and parent: ',
      parent,
    );
    return [];
  }

  // const orArray = itembaseRserialArray.map(_id => ({ _id }));

  // const itembaseSelector = {
  //   selector: {
  //     // _id: {
  //     //   $in: itembaseRserialArray,
  //     // },
  //     $or: orArray,
  //   },
  // };
  // const { data: itembaseRecords } = await boundRunFindQuery(
  //   itembasePgTableName,
  //   itembaseSelector,
  // );
  // getDatabase(itembasePgTableName);
  const itembaseGetManyParams = { ids: itembaseRserialArray };
  const { data: itembaseRecords } = await boundRunGetMany(
    itembasePgTableName,
    itembaseGetManyParams,
  );

  console.debug(
    `batchQueryConbaseRecords: itembasePgTableName: ${itembasePgTableName}: itembaseRecords: `,
    itembaseRecords,
    ' with itembaseRserialArray: ',
    itembaseRserialArray,
    ' and parentRecords: ',
    prArray,
    ' and rconnDoc: ',
    rconnDoc,
    ' and conbaseRecords: ',
    conbaseRecords,
    ' and parent: ',
    parent,
  );

  const resultDoc = itembaseRecords.map(data => {
    const { doc: relDoc } = data;
    const retval = processItembaseRecord(parent, rconnDoc, relDoc);
    return retval;
  });
  const sortedResult = resultDoc.sort((a, b) =>
    `${a.nodeName}`.localeCompare(b.nodeName),
  );
  console.debug(
    `batchQueryConbaseRecords: itembasePgTableName: ${itembasePgTableName}: sortedResult: `,
    sortedResult,
    ' for itembasePgTableName: ',
    itembasePgTableName,
    ' with itembaseRserialArray: ',
    itembaseRserialArray,
    ' and parentRecords: ',
    prArray,
    ' and rconnDoc: ',
    rconnDoc,
    ' and conbaseRecords: ',
    conbaseRecords,
    ' and parent: ',
    parent,
  );
  return sortedResult;
};

export const processRconnRecord = async (
  parent,
  table0,
  table0Rserial,
  rconnDoc,
  parentRecords,
  { boundRunFindQuery, boundRunGetMany },
  getDatabase,
) => {
  console.debug(
    'processRconnRecord: rconnDoc: ',
    rconnDoc,
    ' parent: ',
    parent,
    ' table0: ',
    table0,
    ' table0Rserial: ',
    table0Rserial,
  );
  isLowerCase(rconnDoc.conbase, 'processRconnRecord rconnDoc.conbase');
  const conbasePgTableName = rconnDoc.conbase.toLowerCase();

  isLowerCase(rconnDoc.conrelfld, 'processRconnRecord rconnDoc.conrelfld');
  isLowerCase(rconnDoc.itembase, 'processRconnRecord rconnDoc.itembase');
  isLowerCase(
    rconnDoc.conrelsecond,
    'processRconnRecord rconnDoc.conrelsecond',
  );
  isLowerCase(rconnDoc.conitemfld, 'processRconnRecord rconnDoc.conitemfld');

  const itembase = rconnDoc.itembase.toLowerCase().trim();
  const conrelfld = rconnDoc.conrelfld.toLowerCase().trim();
  const conrelsecond = rconnDoc.conrelsecond.toLowerCase().trim();
  const conitemfld = rconnDoc.conitemfld.toLowerCase().trim();

  // const tempConbaseTimerId = Random.id();
  // console.time(`conbase${tempConbaseTimerId}`);
  isLowerCase(table0, 'processRconnRecord table0');
  const conbaseSelector = {
    selector: {
      [conitemfld]: itembase,
      [conrelfld]: table0.toLowerCase(),
      [conrelsecond]: table0Rserial,
    },
  };
  const dbWrapper = getDatabase(conbasePgTableName);
  const db = dbWrapper && dbWrapper.db;
  console.debug(
    `Trying to create conbase query for ${conbasePgTableName} on fields: `,
    [conitemfld, conrelfld, conrelsecond],
    ' with selector: ',
    conbaseSelector,
    ' and db: ',
    db,
  );
  if (!db) {
    console.error(
      `processRconnRecord: No database for conbasePgTableName: ${conbasePgTableName}. This might occur if those dbs are not defined in SYMT.`,
    );
  } else {
    await db.createIndex({
      index: { fields: [conitemfld, conrelfld, conrelsecond] },
    });
  }
  const findQueryResult = await boundRunFindQuery(
    conbasePgTableName,
    conbaseSelector,
  );
  if (!findQueryResult) {
    console.error(
      `processRconnRecord: findQueryResult NOT FOUND likely due to missing database for ${conbasePgTableName}: `,
      findQueryResult,
      ' using selector: ',
      conbaseSelector,
    );
    return [];
  }
  const { data: conbaseRecords = [] } = findQueryResult;
  console.debug(
    'processRconnRecord: conbaseRecords: ',
    conbaseRecords,
    ' for conbasePgTableName: ',
    conbasePgTableName,
    ' with conbaseSelector: ',
    conbaseSelector,
    ' and rconnDoc: ',
    rconnDoc,
    ' and parent: ',
    parent,
  );
  const conbaseResults = await batchQueryConbaseRecords(
    parent,
    rconnDoc,
    conbaseRecords,
    parentRecords,
    { boundRunFindQuery, boundRunGetMany },
  );
  console.debug(
    'processRconnRecord: conbaseResults: ',
    conbaseResults,
    ' for conbasePgTableName: ',
    conbasePgTableName,
    ' with conbaseRecords: ',
    conbaseRecords,
    ' and parent: ',
    parent,
  );
  return conbaseResults;
};

import LinkedList from './LinkedList';

export default class LinkedQueue extends LinkedList {
  constructor() {
    super();
    this._size = 0;
  }

  /**
   * Add a new element to the end of the queue (the tail of the linked list).
   * This element will be processed after all elements ahead of it.
   * @param {*} value
   */
  enqueue(value) {
    this.append(value);
  }

  append(value) {
    super.append(value);
    this._size++;
  }

  /**
   * Remove the element at the front of the queue (the head of the linked list).
   * If the queue is empty, return null.
   * @return {*}
   */
  dequeue({ condition } = {}) {
    if (!condition) {
      const removedHead = this.deleteHead();
      this._size = this._size > 0 ? this._size - 1 : 0;
      return removedHead ? removedHead.value : null;
    }
    return this.$dequeue({ condition });
  }

  /**
   * @param {Object} findParams
   * @param {*} findParams.value
   * @param {function} [findParams.condition] The value to be dequeud, only if passes condition.
   * @return {LinkedListNode}
   */
  $dequeue({ condition = undefined } = {}) {
    if (!this.head) {
      return null;
    }

    let currentNode = this.head;
    let previousNode = this.head;
    let retval = null;

    while (currentNode && retval === null) {
      // If callback is specified then try to find node by callback.
      if (condition && condition(currentNode.value)) {
        retval = currentNode.value;
      } else {
        previousNode = currentNode;
        currentNode = currentNode.next;
      }
    }

    if (retval) {
      if (currentNode === this.head) {
        this.deleteHead();
      } else if (currentNode === this.tail) {
        this.deleteTail();
      } else {
        previousNode.next = currentNode.next; // We remove the node from the linked list. There must be a previous node otherwise we would be referncing the head.
      }
      this._size = this._size > 0 ? this._size - 1 : 0;
    }

    return retval;
  }

  get size() {
    return this._size;
  }

  /**
   * Read the element at the front of the queue without removing it.
   * @return {*}
   */
  peek() {
    return this.head;
  }
}

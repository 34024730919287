import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CouchDbReplicaProviderAppContext } from '../CouchDbReplicaProviderAppContext';

const createHiertreeFromURL = pathname => {
  console.debug(`createHiertreeFromURL: ${pathname}`);
  let hiertree = '';
  let otherParams;
  let hiernode;
  const originalSplit = pathname.replace('/hier', '').split('/');

  if (!originalSplit.length) {
    console.warn('createHiertreeFromURL: original split failed');
    return hiertree;
  }

  if (!originalSplit[originalSplit.length - 1]) {
    // Remove trailing slash
    originalSplit.pop();
  }

  if (!originalSplit[0]) {
    // Remove leading slash
    originalSplit.shift();
  }

  console.debug('createHiertreeFromURL: originalSplit: ', originalSplit);

  const hierroot = originalSplit.shift();

  if (!hierroot) {
    return hiertree;
  }

  const hierpath = pathname.replace(
    `/hier/${hierroot}${originalSplit.length ? '/' : ''}`,
    '',
  );

  console.debug(
    `createHiertreeFromURL: hierroot: ${hierroot} hierpath: ${hierpath} pathname: ${pathname}`,
  );

  if (hierpath) {
    const split = hierpath.split('/');
    if (!split[split.length - 1]) {
      // Removing trailing slash
      split.pop();
    }
    hiernode = split.pop();
    otherParams = split;
  }
  // If no :hiernode, treat :hierroot as entire hiertree
  if (!hiernode) {
    hiertree = hierroot;
  } else {
    // else, create hiertree by concatenating route paths
    const other = otherParams.join('_');
    hiertree = `${other ? `${other}_` : ''}${hiernode}`
      .trim()
      .replace(/\s/g, '+');
  }
  console.debug(`createHiertreeFromURL: hiertree: ${hiertree}`);
  return hiertree;
};

const useHierarchy = externalPath => {
  // useDb('cxdict');
  // useHierPublish(hiertree);
  const { pathname } = useLocation();
  const { allDatabases } = useContext(CouchDbReplicaProviderAppContext);
  const [allDbsSynced, setAllDbsSynced] = useState(false);

  // let
  const hiertree = useMemo(
    () => {
      if (externalPath) {
        console.debug('useHierarchy: on external path: ', externalPath);
        return '';
      }
      return createHiertreeFromURL(pathname);
    },
    [externalPath, pathname],
  );

  useEffect(
    () => {
      if (allDatabases) {
        setAllDbsSynced(true);
      }
    },
    [allDatabases],
  );

  return { hierarchyReady: allDbsSynced, hiertree };
};

export default useHierarchy;

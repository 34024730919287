/**
 * A simple config wrapper for any external config change (ENV, js file etc)
 */
import { couchConfig as appCouchConfig } from '../isomorphic/config';

const { domain, couchType, postfix } = appCouchConfig;


const url = `${appCouchConfig.couchType}${
  (!process.env.LOCAL_SERVER_TRANSPORT &&
    process.env.NODE_ENV === 'production') ||
    appCouchConfig.transport === 'https'
    ? 's'
    : ''
}://${appCouchConfig.domain}${postfix}`;
export const couchConfig = {
  domain,
  couchType,
  postfix,
  url,
};

export default couchConfig;

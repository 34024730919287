import React from 'react';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';

// TODO : Had some issues with custom routes, currently disabled loadable - will review - might be mandatory for offline app and service worker.
import BootstrapProgress from './BootstrapProgress';
import { WithAppSettingsThemeProvider } from './layout/AppSettingsThemeProvider';
const Configuration = loadable(() => import('./configuration/Configuration'));
const Segments = loadable(() => import('./segments/Segments'));
const ChangeLog = loadable(() => import('./ChangeLog'));
const SignUp = loadable(() => import('./layout/Signup'));
const ForgottenPassword = loadable(() => import('./layout/resetPassword'));

const ThemedSignUp = WithAppSettingsThemeProvider({
  Component: SignUp,
  defaultTheme: true,
});

const ThemedForgottenPassword = WithAppSettingsThemeProvider({
  Component: ForgottenPassword,
  defaultTheme: true,
});

export default [
  <Route exact path="/bootstrap" component={BootstrapProgress} />,
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route exact path="/segments" render={() => <Segments />} />,
  <Route exact path="/changelog" render={() => <ChangeLog />} />,
  <Route exact path="/signup" render={() => <ThemedSignUp />} noLayout />,
  <Route
    exact
    path="/reset-password"
    render={() => <ThemedForgottenPassword />}
    noLayout
  />,
];

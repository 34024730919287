import http from 'http';
import https from 'https';

import axios from 'axios';
import get from 'lodash/get';

import { logger } from '../utils/logger';
import { coreAppServiceUrl } from '../isomorphic/config';

const { e } = logger({ source: 'couchdb-api' });

/**
 * The function retrieves a list of db names and using direct couch api retrieves their docs count on couch in batches
 * which it returns in a Map in addition to total documents counted for all dbs.
 * @returns {Promise<object>} a map of db name and doc count + total docs count for all dbs their docs count was fetched.
 * @param {string[]} dbs The list of dbs to receive info for.
 *
 * */
export const getDbsDocCount = async () => {
  try {
    const instance = axiosInstance();
    const infoCallUrl = coreAppServiceUrl(`sync/dbsInfo`);
    const callConfig = getCallConfig();

    // TODO : put this in a promises array.
    // eslint-disable-next-line no-await-in-loop
    const response = await instance.get(infoCallUrl, callConfig);
    const docsCount = get(response, 'data.data.docsCount', []);

    const dbsDocsCountMap = new Map(docsCount);
    return { dbsDocsCount: dbsDocsCountMap, total: 0 };
  } catch (error) {
    e({ msg: 'Failed to retreive db infos', error });
    throw error;
  }
};

export const online = async () => true; // TODO: examine if couchdb _up api is required.

// try {
//   const httpAgent = new http.Agent({ keepAlive: false });
//   const httpsAgent = new https.Agent({ keepAlive: false });

//   // on the instance
//   const instance = axios.create({
//     httpAgent, // httpAgent: httpAgent -> for non es6 syntax
//     httpsAgent,
//   });

//   const { url } = couchConfig;
//   const couchdbUrl = `${url}/_up`;

//   const dbInfoCallResponse = await instance.get(couchdbUrl, {
//     httpAgent,
//     httpsAgent,
//   });
//   const { data } = dbInfoCallResponse && dbInfoCallResponse;
//   const { status } = data && data;
//   const retval = status === 'ok';
//   return retval;
// } catch (error) {
//   e({ msg: 'Failed to perform online/health check', error });
//   return false;
// }

// TODO: create slim axios util wrapper.
const axiosInstance = () => {
  const httpAgent = new http.Agent({ keepAlive: false });
  const httpsAgent = new https.Agent({ keepAlive: false });

  const instance = axios.create({
    httpAgent,
    httpsAgent,
  });
  return instance;
};

const getCallConfig = () => {
  const accessToken = localStorage.getItem('access_token');
  const retval = accessToken
    ? {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    : {};
  return retval;
};

import React from 'react';

import { winpcsConfig } from '../../isomorphic/config';

import { isCordova, isIOS } from './detectDevice';

export const coreName = winpcsConfig.tables.core.name;

export const symtName = 'symt';

export const CouchDbReplicaProviderAppContext = React.createContext({
  [`${symtName}Docs`]: null,
  [`${symtName}SimplSchemaByTable`]: null,
  [`${symtName}SyncDone`]: false,
  [`${symtName}LocalDocCount`]: null,
  [`${symtName}RemoteDocCount`]: null,
  [`${coreName}Docs`]: null,
  [`${coreName}SyncDone`]: null,
  [`${coreName}LocalDocCount`]: null,
  [`${coreName}RemoteDocCount`]: null,
  allDatabases: {},
  allDbsSyncDone: isIOS && !isCordova,
  allTables: null,
  originalAllTables: null,
});

export default CouchDbReplicaProviderAppContext;

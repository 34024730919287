import { logger } from '../../utils/logger';

/**
 * Simple events emitter implementation to be wrapped internally by other classes with the intetion that dispatch and subscribe are
 * not exposed directly but wrapped with meaningful APIs - e.g onEventName(callback)
 *
 * TODO: needs to be placed in project common utils.
 * @param {*} eventEmitterName
 * @param {*} eventTypes
 * @returns
 */
const eventsEmitter = ({ eventsEmitterName, eventTypes }) => {
  if (!eventsEmitterName) {
    throw new Error('event emitter has to has a name');
  }

  if (!eventTypes) {
    throw new Error('events emitter eventTypes must defined');
  }

  const { e, d } = logger({ source: eventsEmitterName });

  d({
    msg: 'creating events emitter',
    data: { eventEmitterName: eventsEmitterName, eventTypes },
  });

  const events = {};

  /**
   *
   * @param {string} dispatcher - dispatcher identifier
   * @param {string}  eventType - event type
   * @param {object} data - event custom data
   */
  const dispatch = ({ dispatcher, eventType, data }) => {
    d({
      msg: 'Dispatching event',
      data: { dispatcher, eventType },
    });
    if (!eventTypes[eventType]) {
      const msg = 'No such event type';
      const error = { msg, data: { dispatcher, eventType, data } };
      e({ ...error });
      throw new Error(msg);
    }
    if (events[eventType]) {
      events[eventType].forEach(({ callback, subscriber }) => {
        try {
          callback({ dispatcher, eventType, data });
        } catch (error) {
          e({
            msg: 'Failed dispatching error',
            error,
            data: { eventType, dispatcher, subscriber, data },
          });
        }
      });
    }
  };

  /**
   * eventsEmitter callback typedef
   *
   * @callback eventsEmitterEventHandler
   * @param {string} dispatcher
   * @param {string} eventType
   * @param {Object} data
   * @returns {void}
   */

  /**
   * @typedef eventsEmitterEventSubscribe
   * @param {string} subscriber
   * @param {string} eventType
   * @param {eventsEmitterEventHandler} callback
   */

  /**
   * On works in two modes, if callback is supplied, it will be called once event of type eventType occurs if callback not supplid it returns a promise
   * that will be fulfilled once the event is dispatched.
   * @param {eventsEmitterEventSubscribe} param
   * @returns
   */
  const on = ({ subscriber, eventType, callback }) => {
    d({
      msg: 'Subscribing to event',
      data: { subscriber, eventType },
    });
    const paramsValidationError = !subscriber || !eventType;

    if (paramsValidationError) {
      const msg = 'Params validation error';
      const error = {
        msg,
        data: { subscriber, eventType, callback },
      };
      e({ ...error });
      throw new Error(msg);
    }

    const eventTypeError = !eventTypes[eventType];
    if (eventTypeError) {
      const msg = 'No such event type';
      const error = {
        msg,
        data: { event: eventType, callback, eventTypes },
      };
      e({ ...error });
      throw new Error(msg);
    }

    const undefinedEventCallbacksList = !events[eventType];
    if (undefinedEventCallbacksList) {
      events[eventType] = [];
    }

    let promise;

    if (callback) {
      events[eventType].push({ callback, subscriber });
    } else {
      // Returns a promise fullfilled a single time on next event call - enabling await for the caller - then clears that callback.
      promise = new Promise(resolve => {
        // eslint-disable-next-line no-shadow
        const promiseCallback = ({ dispatcher, eventType, data }) => {
          clearCallback({ eventType, callback: promiseCallback });
          resolve({ dispatcher, eventType, data });
        };
        events[eventType].push({ subscriber, callback: promiseCallback });
      });
    }

    return promise;
  };

  const clearCallback = ({ eventType, callback }) => {
    const eventTypeCallbacksArray = events[eventType];
    // In case of promise fulfill, there will be no multiple calls to that callback hence we remove it from our array.
    const eventTypeCallbacksArrayFilteredPromiseCallback = eventTypeCallbacksArray.filter(
      callbackCompare => callbackCompare !== callback,
    );
    events[eventType] = eventTypeCallbacksArrayFilteredPromiseCallback;
  };

  return {
    dispatch,
    on,
  };
};

export default eventsEmitter;

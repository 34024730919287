import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  CLEAR_STATE,
} from 'react-admin';

import { SET_AUTH } from './configuration/actions';

// eslint-disable-next-line no-unused-vars
export default (previousState = { isLoggedIn: false }, { type, payload }) => {
  if (type === USER_LOGIN_FAILURE) {
    return { isLoggedIn: false };
  }
  if (type === USER_LOGIN_SUCCESS) {
    return { isLoggedIn: true };
  }
  if (type === CLEAR_STATE) {
    return { isLoggedIn: false };
  }

  if (type === SET_AUTH && payload) {
    const { isLoggedIn = false } = payload;
    return { isLoggedIn };
  }

  return previousState;
};

import loadable from '@loadable/component';

const ChecksheetList = loadable(() => import('./AppList'));
const ChecksheetEdit = loadable(() => import('./ChecksheetEdit'));
const ChecksheetCreate = loadable(() => import('./ChecksheetCreate'));

require('../../custom-form-builder/scss/application.scss');

export default {
  list: ChecksheetList,
  edit: ChecksheetEdit,
  create: ChecksheetCreate,
};

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import posthog from 'posthog-js';
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router/immutable';
// import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'rc-progress/assets/index.css';

// Import root app
// import App from 'containers/App';

import { listener } from './utils/eventshub';
import {
  AUTH_CHANNEL,
  AUTH_EVENT_TYPE,
  AUTH_PROVIDER_EVENT_SOURCE,
  buildUserId,
} from './containers/AdminApp/authProvider';

import App from 'containers/AdminApp';

// Import Language Provider
// import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

// import configureStore from './configureStore';

// Import i18n messages
// import { translationMessages } from './i18n';

/**
 * Posthog analytics & session recording - https://us.posthog.com/project/84176
 */
if (process.env.POSTHOG_API_KEY) {
  // eslint-disable-next-line no-console
  console.log('initializing posthog');
  posthog.init(process.env.POSTHOG_API_KEY, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
  });

  // Check existing user
  const localStorageUsername = localStorage.getItem('username');
  const permissions = localStorage.getItem('permissions');
  const authValid = localStorageUsername && permissions;

  if (authValid) {
    const userId = buildUserId({ username: localStorageUsername });
    // eslint-disable-next-line no-console
    console.log(
      'posthog identify existing user from page load: ',
      userId,
      localStorageUsername,
      permissions,
    );
    posthog.identify(userId, {
      name: localStorageUsername,
      permissions,
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      `Not identifying posthog user on page load: localStorageUsername: ${localStorageUsername} permissions: ${permissions}`,
    );
  }

  // Events are emitted by the authProvider.js when a user logs in or logs out
  listener.listen({
    channel: AUTH_CHANNEL,
    listenerName: 'posthog',
    callback: event => {
      if (!event) {
        // eslint-disable-next-line no-console
        console.error(
          `auth event is undefined for posthog listener: channel: ${AUTH_CHANNEL}`,
        );
        return;
      }

      // Check if event is an object
      if (typeof event !== 'object') {
        // eslint-disable-next-line no-console
        console.error(
          `auth event is not an object for posthog listener: channel: ${AUTH_CHANNEL} event: `,
          event,
        );
        return;
      }

      const { data, channel, source, type } = event;

      if (
        channel !== AUTH_CHANNEL ||
        source !== AUTH_PROVIDER_EVENT_SOURCE ||
        type !== AUTH_EVENT_TYPE
      ) {
        // eslint-disable-next-line no-console
        console.error(
          `auth event is not of the correct type for posthog listener: channel: ${AUTH_CHANNEL} source: ${AUTH_PROVIDER_EVENT_SOURCE} type: ${AUTH_EVENT_TYPE}`,
        );
      }

      const { loginState, username, permissions, userId } = data;

      if (loginState === 'loggedIn') {
        // eslint-disable-next-line no-console
        console.log(
          'posthog identify after log in: ',
          userId,
          username,
          permissions,
        );
        // https://posthog.com/docs/product-analytics/identify
        // When a user starts browsing your website or app, PostHog automatically assigns them an anonymous ID, which is stored locally. This enables us to track anonymous users – even across different sessions.
        // Note: depending on your persistence configuration, the anonymous ID may not be stored, and thus regenerated across sessions.
        // By calling identify with a distinct_id of your choice (usually the user's ID in your database, or their email), you link the anonymous ID and distinct ID together.
        // Thus all past and future events made with that anonymous ID are now associated with the distinct ID. This enables you to do things like associate events with a user from before they log in for the first time, or associate their events across different devices or platforms.
        // Note: As of Aug 2024, we are only collecting events for identified users
        // https://posthog.com/docs/libraries/js#persistence
        // By default, we store all this information in a cookie, which means PostHog can identify your users across subdomains. By default, this cookie is set to expire after 365 days and is named with your Project API key e.g. ph_<project_api_key>_posthog.
        posthog.identify(userId, {
          name: username,
          permissions,
        });
      } else if (loginState === 'loggedOut') {
        // eslint-disable-next-line no-console
        console.log('posthog reset after log out');
        // If a user logs out on your frontend, you should call reset to unlink any future events made on that device with that user.
        posthog.reset();
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `auth event loginState is not recognized for posthog listener: channel: ${AUTH_CHANNEL} source: ${AUTH_PROVIDER_EVENT_SOURCE} type: ${AUTH_EVENT_TYPE} loginState: ${loginState} data: `,
          data,
        );
      }
    },
  });
} else {
  // eslint-disable-next-line no-console
  console.warn('POSTHOG_API_KEY not set');
}

// Create redux store with history
// let initialState = {};
// if (window) {
//   initialState = window.initialState || {};
// }
// const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.render(<App />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/AdminApp'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });

  // module.hot.dispose(() => {
  //   window.initialState = store.getState();
  // });
}

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise(resolve => {
//     resolve(import('intl'));
//   })
//     .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     });
// } else {
//   render(translationMessages);
// }

render();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production' && !process.env.DISABLE_OFFLINE) {
  // eslint-disable-next-line no-console
  console.log('loading offline plugin');
  // eslint-disable-next-line global-require
  const runtime = require('offline-plugin/runtime');
  runtime.install({
    onUpdating: () => {
      // eslint-disable-next-line no-console
      console.log('SW Event:', 'onUpdating');
    },

    onUpdateReady: () => {
      // eslint-disable-next-line no-console
      console.log('SW Event:', 'onUpdateReady');
      // Tells to new SW to take control immediately
      runtime.applyUpdate();
    },

    onUpdated: () => {
      // eslint-disable-next-line no-console
      console.log('SW Event:', 'onUpdated');
      // Reload the webpage to load into the new version
      window.location.reload();
    },

    onUpdateFailed: () => {
      // eslint-disable-next-line no-console
      console.log('SW Event:', 'onUpdateFailed');
    },
  }); // eslint-disable-line global-require
}

import PunchIcon from '@material-ui/icons/Assignment';
import loadable from '@loadable/component';

const PunchList = loadable(() => import('./PunchList'));
const PunchCreate = loadable(() => import('./PunchCreate'));
const PunchEdit = loadable(() => import('./PunchEdit'));
const PunchShow = loadable(() => import('./PunchShow'));

export default {
  list: PunchList,
  create: PunchCreate,
  edit: PunchEdit,
  icon: PunchIcon,
  show: PunchShow,
};

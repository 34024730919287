import React, { useRef, useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { REPOSITORY_STATE } from '../../../couch/sync-manager';
import SyncRefreshNotificationBar from '../layout/SyncRefreshNotificationBar';

import useDb, { ProgressBar } from './useDb';

/**
 * @param {*} props = {Component,dbNames,repositoryState}
 * @returns
 */

export const DbGate = props => {
  const {
    dbNames,
    repostitoryState,
    core = false,
    syncRefreshNotificationBar = true,
  } = props;

  const dbCachedResult = useRef(undefined);

  const { db, error: dbError, syncState } = useDb(
    dbNames,
    repostitoryState,
    core,
  );

  const {
    localDocsCount,
    remoteDocsCount,
    repositoriesSynced,
    repositoriesCount,
    dataChangesCount,
    syncPullCount,
    syncPushCount,
    coreData: {
      localDocsCount: coreLocalDocsCount,
      remoteDocsCount: coreRemoteDocsCount,
    },
    syncStatsTrack,
    text: {
      docsSyncStatusText,
      repositoriesSyncStatusText,
      coreSyncStatusText,
    },
  } = syncState;

  useEffect(
    () => {
      if (!dbCachedResult.current) {
        dbCachedResult.current = db;
      }
    },
    [db],
  );

  /**
   * Occurs on remounts, cache will be refreshed by  db=useDb(...) state, in in case it is undefined it means useDb has not yet returned or in case it is false no need for an update as we only transition from false to true not vice versa */

  useEffect(() => {
    if (db) {
      dbCachedResult.current = db;
    }
  }, []);

  if (dbError) {
    return <></>; // TODO : implement db error display.
  }

  // db has three states, true, false and undefined, if either db or its cache are true we display the component, if both are false we display the progress bar.
  if (db || dbCachedResult.current) {
    if (props.children && React.isValidElement(props.children)) {
      // We pass the sync state to the displayed component.
      return (
        <>
          {React.Children.map(props.children, child =>
            React.cloneElement(child, { syncState }),
          )}
          {syncRefreshNotificationBar && (
            <SyncRefreshNotificationBar
              dbNames={dbNames}
              syncState={syncState}
            />
          )}
        </>
      );
    }
    return props.children;
  }
  const progressBarProps = {
    localDocsCount,
    remoteDocsCount,
    repositoriesSynced,
    repositoriesCount,
    coreRemoteDocsCount,
    coreLocalDocsCount,
    showCoreProgressBar: core,
    name: dbNames,
    text: {
      docsSyncStatusText,
      repositoriesSyncStatusText,
      coreSyncStatusText,
    },
    onFullLoadProgressCompleted: () => {},
  };
  return <ProgressBar {...progressBarProps} />;
};

/**
 * A function that retrives a component and dbNames and returns a functional component that wraps
 * the original component retrieved and displays a progress bar while the dbNames parameter has not been synced.
 * @param {object} Component - A react component.
 * @param {string[]} dbNames - An array of db names to be synced prior to displaying the screen excluding 'core' db which has a param of its own.
 * @param {REPOSITORY_STATE} repositoryState - A custom enum typedef which repository sync state to wait before displaying the screen.
 * @param {boolean} core - Either to wait for 'core' db to sync or not.
 * @returns
 */
export const withDbGate = ({
  Component,
  dbNames,
  repostitoryState = REPOSITORY_STATE.REPOSITORY_PARTIALLY_SYNCED,
  core = false,
  syncRefreshNotificationBar = false,
}) => props => {
  const determineDbNames = () => {
    let retval = dbNames; // Initial state it is a single value string.

    const isFunctionResult = isFunction(dbNames);
    const isArrayResult = isArray(dbNames);
    const isStringResult = isString(dbNames);

    if (isArrayResult || isStringResult) {
      retval = dbNames;
    } else if (isFunctionResult) {
      retval = dbNames(props);
    } else {
      throw new Error(
        'dbNames must be a string value, array of strings or a function returning array of strings',
      );
    }

    return retval;
  };
  // eslint-disable-next-line no-nested-ternary
  const dbNamesDet = determineDbNames();
  // We currently turn off the notification bar till fully QAed for new version.
  return (
    <DbGate
      dbNames={dbNamesDet}
      repostitoryState={repostitoryState}
      core={core}
      syncRefreshNotificationBar={syncRefreshNotificationBar}
    >
      <Component {...props} />
    </DbGate>
  );
};

export default withDbGate;

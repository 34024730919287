import { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import UppyWebcam from '@uppy/webcam';

export const useUppy = uppyParams => {
  const [uppy, setUppy] = useState();

  useEffect(
    () => {
      // if (uppy) {
      //   uppy.close();
      // }

      setUppy(Uppy(uppyParams));
    },
    [uppyParams],
  );

  useEffect(
    () => () => {
      if (uppy) {
        uppy.close();
      }
    },
    [uppy],
  );

  return uppy;
};

const usePluginFactory = (id, plugin) => (uppy, params, disabled) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(
    () => {
      if (uppy && !disabled) {
        const existingPlugin = uppy.getPlugin(id);

        if (existingPlugin) {
          uppy.removePlugin(existingPlugin);
        }

        uppy.use(plugin, {
          ...params,
          id,
        });

        setLoading(false);
      }

      if (disabled) {
        setLoading(false);
      }

      return () => {
        if (uppy) {
          const existingPlugin = uppy.getPlugin(id);

          if (existingPlugin) {
            uppy.removePlugin(existingPlugin);
          }

          setLoading(true);
        }
      };
    },
    [uppy, params, disabled],
  );

  return { id, loading };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useUppyWebcam = usePluginFactory('Webcam', UppyWebcam);

import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAuth } from '../configuration/actions';
import authProvider from '../authProvider';

const useAuth = (checkAuth = true) => {
  const [checkingAuth, setCheckingAuth] = useState(true);
  const dispatch = useDispatch();
  const setAuthCallback = useCallback(auth => dispatch(setAuth(auth)), [
    dispatch,
  ]);
  const isAuthed = useSelector(({ auth }) => auth.isLoggedIn);

  useEffect(
    () => {
      async function check() {
        try {
          await authProvider.checkAuth();
          setAuthCallback(true);
        } catch (e) {
          setAuthCallback(false);
        } finally {
          setCheckingAuth(false);
        }
      }

      if (checkAuth) {
        setCheckingAuth(true);
        check();
      }
    },
    [setCheckingAuth, checkAuth, setAuthCallback],
  );

  return { isAuthed, setAuth: setAuthCallback, checkingAuth };
};

export default useAuth;

/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import ChangelogIcon from '@material-ui/icons/Redeem';
import HierarchyIcon from '@material-ui/icons/AccountTree';
import CertSysIcon from '@material-ui/icons/Extension';
import CertUnitIcon from '@material-ui/icons/Widgets';
import CertSiteIcon from '@material-ui/icons/ViewQuilt';
import CertAreaIcon from '@material-ui/icons/LocationCity';
import CertZoneIcon from '@material-ui/icons/DomainDisabled';
import CertMileIcon from '@material-ui/icons/Assessment';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import useDarkMode from 'use-dark-mode';
import DescriptionIcon from '@material-ui/icons/Description';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListIcon from '@material-ui/icons/List';
import LabelIcon from '@material-ui/icons/Label';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

import punches from '../punches';
import preservation from '../preservation';
import { isIOS, isCordova } from '../detectDevice';
import { checksheetConfig } from '../../../isomorphic/config';

import SubMenu from './SubMenu';
import Logo from './Logo';

const Menu = ({ onMenuClick, dense, logout }) => {
  const [menuHierarchy, setMenuHierarchy] = useState(false);
  const [menuChecksheet, setMenuChecksheet] = useState(false);
  const translate = useTranslate();
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const open = useSelector(reduxState => reduxState.admin.ui.sidebarOpen);
  useDarkMode(false); // force rerender on theme change

  const showHierarchy = isCordova || !isIOS;

  return (
    <div>
      {' '}
      {isXsmall && <Logo onClick={onMenuClick} />}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink
        to="/autopl"
        primaryText={translate(`resources.autopl.name`, {
          smart_count: 2,
        })}
        leftIcon={<punches.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/conpmr"
        primaryText={translate('resources.conpmr.name')}
        leftIcon={<preservation.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {showHierarchy && (
        <SubMenu
          handleToggle={() => setMenuChecksheet(!menuChecksheet)}
          isOpen={menuChecksheet}
          sidebarIsOpen={open}
          name="pos.menu.checksheetAdmin"
          icon={<DescriptionIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/${checksheetConfig.tables.checksheetQuestions.name}`}
            primaryText={translate('resources.checksheet_question.name', {
              smart_count: 2,
            })}
            leftIcon={<QuestionAnswerIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/${checksheetConfig.tables.checksheetHeaders.name}`}
            primaryText={translate(`resources.checksheet_header.name`, {
              smart_count: 2,
            })}
            leftIcon={<LabelImportantIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/${checksheetConfig.tables.checksheetFooters.name}`}
            primaryText={translate(`resources.checksheet_footer.name`, {
              smart_count: 2,
            })}
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/${checksheetConfig.tables.checksheetForm.name}`}
            primaryText={translate(`resources.checksheet_form.name`, {
              smart_count: 2,
            })}
            leftIcon={<ListIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/${checksheetConfig.tables.checksheet.name}`}
            primaryText={translate(`resources.checksheet.name`, {
              smart_count: 2,
            })}
            leftIcon={<ListAltIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}
      {showHierarchy && (
        <SubMenu
          handleToggle={() => setMenuHierarchy(!menuHierarchy)}
          isOpen={menuHierarchy}
          sidebarIsOpen={open}
          name="pos.menu.hierarchy"
          icon={<HierarchyIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/hier/CERTSITE"
            primaryText={translate('resources.certsite.name', {
              smart_count: 2,
            })}
            leftIcon={<CertSiteIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/hier/CERTUNIT"
            primaryText={translate(`resources.certunit.name`, {
              smart_count: 2,
            })}
            leftIcon={<CertUnitIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/hier/CERTSYS"
            primaryText={translate(`resources.certsys.name`, {
              smart_count: 2,
            })}
            leftIcon={<CertSysIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/hier/CERTAC1"
            primaryText={translate(`resources.certac1.name`, {
              smart_count: 2,
            })}
            leftIcon={<CertSysIcon color="secondary" />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/hier/CERTAREA"
            primaryText={translate(`resources.certarea.name`, {
              smart_count: 2,
            })}
            leftIcon={<CertAreaIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/hier/CERTMC1"
            primaryText={translate(`resources.certmc1.name`, {
              smart_count: 2,
            })}
            leftIcon={<CertZoneIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/hier/CERTMILE"
            primaryText={translate(`resources.certmile.name`, {
              smart_count: 2,
            })}
            leftIcon={<CertMileIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}
      <MenuItemLink
        to={`/${checksheetConfig.tables.conChecksheetEquipment.name}`}
        primaryText={translate('resources.checksheet.name')}
        leftIcon={<preservation.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {isXsmall && (
        <Fragment>
          <MenuItemLink
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to="/changelog"
            primaryText={translate('pos.changelog')}
            leftIcon={<ChangelogIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </Fragment>
      )}
      {isXsmall && logout}
    </div>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
  dense: PropTypes.bool,
};

export default Menu;

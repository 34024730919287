import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { logger } from '../../../utils/logger';

import AppSettingsContext from './AppSettingsContext';
import { lightTheme, darkTheme } from './AppThemes';
import useAppIdentity from './useAppIdentity';

const { i } = logger({ source: 'App Settings Manager' });

const getLocalStroageThemeName = username => {
  // Initial version with a simple username key.
  const appThemeLocalStorageKey = `config/${username}/appTheme`;

  const cachedThemeName = localStorage.getItem(appThemeLocalStorageKey);
  let cachedTheme;

  if (cachedThemeName) {
    cachedTheme = cachedThemeName === 'light' ? lightTheme : darkTheme;
  }
  return cachedTheme;
};

const AppSettingsProvider = props => {
  const { username, permissions, loginState } = useAppIdentity();

  const defaultTheme = useRef(lightTheme);

  const getCachedTheme = useCallback(() => {
    const defaultThemeFallback =
      !username || !permissions || loginState !== 'loggedIn';

    if (defaultThemeFallback) {
      return defaultTheme.current;
    }

    const cachedTheme = getLocalStroageThemeName(username);

    return cachedTheme;
  });

  const initialTheme = useMemo(() => getCachedTheme(), [loginState, username]);

  const [currentTheme, setCurrentTheme] = useState(
    initialTheme || defaultTheme.current,
  );

  const contextToggleTheme = useCallback(() => {
    const nextTheme = currentTheme === lightTheme ? darkTheme : lightTheme;
    setCurrentTheme(nextTheme);

    if (loginState === 'loggedIn' && username) {
      const appThemeLocalStorageKey = `config/${username}/appTheme`;

      localStorage.setItem(appThemeLocalStorageKey, nextTheme);
    }
    return nextTheme;
  });

  const contextSetTheme = useCallback(themeName => {
    const selectedTheme = themeName === 'light' ? lightTheme : darkTheme;
    i({ msg: 'Theme Mode Changed', data: { themeName } });
    setCurrentTheme(selectedTheme);

    if (loginState === 'loggedIn' && username) {
      const appThemeLocalStorageKey = `config/${username}/appTheme`;
      localStorage.setItem(appThemeLocalStorageKey, themeName);
    }
  });

  useEffect(
    () => {
      const cachedThemeName = getCachedTheme(); // Works for logged in/out - default on out.
      if (cachedThemeName) {
        setCurrentTheme(cachedThemeName);
      }
    },
    [loginState],
  );

  return (
    <AppSettingsContext.Provider
      value={{
        theme: currentTheme,
        defaultTheme: defaultTheme.current,
        toggleTheme: contextToggleTheme,
        setTheme: contextSetTheme,
      }}
    >
      {props.children}
    </AppSettingsContext.Provider>
  );
};

AppSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppSettingsProvider;

export const WithAppSettingsProvider = () => WrappedComponent => props => (
  <AppSettingsProvider>
    <WrappedComponent {...props} />
  </AppSettingsProvider>
);

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import authReducer from './authReducer';
import dbReducer from './dbReducer';

const adminPersistConfig = {
  key: 'admin',
  storage,
  whitelist: ['theme', 'ui'],
};

const dbPersistConfig = {
  key: 'db',
  storage,
  stateReconciler: hardSet,
};

const persistedAdminReducer = persistReducer(adminPersistConfig, adminReducer);
const persistedDbReudcer = persistReducer(dbPersistConfig, dbReducer);

export default ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: persistedAdminReducer,
    router: connectRouter(history),
    auth: authReducer,
    db: persistedDbReudcer,
    // { /* add your own reducers here */ },
  });
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork),
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
      ),
      // add your own enhancers here
    ),
  );
  sagaMiddleware.run(saga);

  const persistor = persistStore(store);

  return { store, persistor };
};

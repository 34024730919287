import http from 'http';
import https from 'https';

import axios from 'axios';
import _ from 'lodash';

import { coreAppServiceUrl } from '../../../isomorphic/config';

export const register = async userData => {
  try {
    const instance = axiosInstance();

    const url = coreAppServiceUrl('auth/register');

    const response = await instance.post(
      url,
      {
        ...userData,
      },
      {
        timeout: 60 * 1000,
      },
    );
    console.log(response);
    const { ok, data } = response?.data || { ok: false };
    if (!ok) {
      const message = data?.[0]?.message
        ? data?.[0]?.message
        : 'Registration failed';
      throw new Error(message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const login = async ({ username, password }) => {
  let result = { ok: false, message: 'Authentication failed' };
  try {
    const instance = axiosInstance();

    const url = coreAppServiceUrl(`auth/login`);

    const response = await instance.post(url, {
      username,
      password,
    });
    console.log(response);

    // TODO: TEMP PATCH  : severe issue with Axios as Error was thrown empty without custom message, had to return 200 with internal ok:false and custom message -
    if (response?.status === 200) {
      // Axios/Node wraps our data object with theirs - so it comes out data.data will review.
      const data = response?.data?.data;
      const ok = data?.ok;
      if (ok) {
        const jwt = data?.jwt;
        const name = data?.user?.name;
        const roles = data?.user?.roles;
        if (jwt && name && roles) {
          // COMMENT : it is more than reasonable to let the api store the JWT in the local storage and not offload the behavior for another wrapping class.
          localStorage.setItem('access_token', jwt);
          result = { ok: true, name, roles };
        }
        if (data.awsAccessToken) {
          result = { ...result, awsAccessToken: data.awsAccessToken };
        }
      } else if (response?.data?.message) {
        result = { ok: false, message: response?.data?.message };
      }
    } else if (response?.message) {
      result = { ok: false, message: response?.message };
    }

    return result;
  } catch (error) {
    result = error?.response?.message
      ? { ok: false, message: error?.response?.message }
      : result;
    return result;
  }
};

export const session = async () => {
  try {
    const instance = axiosInstance();

    const url = coreAppServiceUrl(`auth/session`);

    const callConfig = getCallConfig();
    const response = await instance.post(url, {}, callConfig);
    let result = { ok: false };
    if (response?.status === 200) {
      // Axios/Node wraps our data object with theirs - so it comes out data.data will review.
      const data = response?.data?.data;
      const jwt = data?.jwt;
      const name = data?.user?.name;
      const roles = data?.user?.roles;
      if (jwt && name && roles) {
        // COMMENT : it is more than reasonable to let the api store the JWT in the local storage and not offload the behavior for another wrapping class.
        localStorage.setItem('access_token', jwt);
        result = { ok: true, name, roles };
      }
    }
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const logout = async () => {
  try {
    if (window.navigator.onLine) {
      const instance = axiosInstance();

      const url = coreAppServiceUrl(`auth/logout`);

      const callConfig = getCallConfig();
      await instance.post(url, {}, callConfig);
    }
    return { ok: true };
  } catch (error) {
    // TODO: handle loutout failure.
    console.log(error);
    throw error;
  } finally {
    const exists = localStorage.getItem('access_token');
    if (exists) {
      localStorage.removeItem('access_token');
    }
  }
};

export const validateUserData = async userData => {
  try {
    const instance = axiosInstance();

    const url = coreAppServiceUrl(`auth/validateuserdata`);

    instance.get();
    const response = await instance.get(url, {
      params: {
        userData,
      },
    });
    console.log(response);
    const result = _.get(response, 'data.data');
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const resetPasswordEmailValidation = async ({ email }) => {
  try {
    const url = coreAppServiceUrl(`auth/resetPasswordEmailValidation`);

    const instance = axiosInstance();

    const response = await instance.post(url, {
      email,
    });
    console.log(response);
    const statusCode = _.get(response, 'data.statusCode');

    // eslint-disable-next-line eqeqeq
    const result = { ok: statusCode == 200 };
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validatePasscode = async ({ email, passcode }) => {
  try {
    const url = coreAppServiceUrl(`auth/validatePasscode`);

    const instance = axiosInstance();

    const response = await instance.post(url, {
      email,
      passcode,
    });
    console.log(response);
    const statusCode = _.get(response, 'data.statusCode');

    // eslint-disable-next-line eqeqeq
    const result = { ok: statusCode == 200 };
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const resetPassword = async ({ email, passcode, newPassword }) => {
  try {
    const url = coreAppServiceUrl(`auth/resetpassword`);

    const instance = axiosInstance();

    const response = await instance.post(url, {
      email,
      passcode,
      newPassword,
    });
    console.log(response);
    const statusCode = _.get(response, 'data.statusCode');

    // eslint-disable-next-line eqeqeq
    const result = { ok: statusCode == 200 };
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// TODO: create slim axios util wrapper.
const axiosInstance = () => {
  const httpAgent = new http.Agent({ keepAlive: false });
  const httpsAgent = new https.Agent({ keepAlive: false });

  const instance = axios.create({
    httpAgent,
    httpsAgent,
  });
  return instance;
};

const getCallConfig = () => {
  const accessToken = localStorage.getItem('access_token');
  const retval = accessToken
    ? {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    : {};
  return retval;
};

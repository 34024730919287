/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react';
import isEqual from 'lodash/isEqual';
import { makeStyles } from '@material-ui/core/styles';

import CouchDbReplicaProviderAppContext from '../CouchDbReplicaProviderAppContext';
import { REPOSITORY_STATE } from '../../../couch/sync-manager';
import { logger } from '../../../utils/logger';
import RecordSyncIndicator from '../RecordSyncIndicator';
// TODO : For some reason import for REPOSITORY_STATE directly imported it wrapped, will find out why later.
const { REPOSITORY_EMPTY, REPOSITORY_FULLY_SYNCED } = REPOSITORY_STATE;

export { REPOSITORY_STATE };

const repositoryOrDefaultStats = ({
  dbName,
  repositoriesSyncStats: syncContextRepositoriesStats,
  requiredSyncState,
}) => {
  const {
    localDocsCount,
    remoteDocsCount,
    repositoryState,
    dataChangesCount,
    syncPullCount,
    syncPushCount,
  } = syncContextRepositoriesStats.has(dbName)
    ? syncContextRepositoriesStats.get(dbName)
    : {
        localDocsCount: 0,
        remoteDocsCount: 0,
        repositoryState: REPOSITORY_EMPTY,
        dataChangesCount: 0,
        syncPullCount: 0,
        syncPushCount: 0,
      };
  const repositoriesSynced = repositoryState === requiredSyncState ? 1 : 0;
  return {
    localDocsCount,
    remoteDocsCount,
    repositoryState,
    repositoriesSynced,
    repositoriesCount: 1,
    dataChangesCount,
    syncPullCount,
    syncPushCount,
  };
};

/**
 *
 * @param {*} dbNames
 */
const aggregateRepositoryStats = ({
  dbNames,
  repositoriesSyncStats: syncContextRepositoriesStats,
  requiredSyncState = REPOSITORY_FULLY_SYNCED,
  statsLogger,
}) => {
  const emptyDbNames = (dbNames && dbNames.length === 0) || !dbNames;
  const emptyRepositoriesStats =
    (syncContextRepositoriesStats && syncContextRepositoriesStats.size === 0) ||
    !syncContextRepositoriesStats;
  const emptyResultStats = emptyDbNames || emptyRepositoriesStats;
  if (emptyResultStats) {
    return {
      localDocsCount: 0,
      remoteDocsCount: 0,
      repositoryState: REPOSITORY_EMPTY,
    };
  }

  const { d } = statsLogger;

  d({
    msg: 'aggregateRepositroyStats init',
    data: { dbNames, syncContextRepositoriesStats, requiredSyncState },
  });

  const {
    reduceTotalLocalDocsCount,
    reduceTotalRemoteDocsCount,
    reducedDataChangesCount,
    reducedSyncPullCount,
    reducedSyncPushCount,
    reducedRepositoriesState,
    reducedRepositoriesSynced,
    repositoriesCount,
  } = dbNames.reduce(
    (reducedStats, dbName) => {
      const {
        // eslint-disable-next-line no-shadow
        reduceTotalLocalDocsCount,
        // eslint-disable-next-line no-shadow
        reduceTotalRemoteDocsCount,
        // eslint-disable-next-line no-shadow
        reducedDataChangesCount,
        // eslint-disable-next-line no-shadow
        reducedSyncPullCount,
        // eslint-disable-next-line no-shadow
        reducedSyncPushCount,
        // eslint-disable-next-line no-shadow
        reducedRepositoriesState,
        // eslint-disable-next-line no-shadow
        reducedRepositoriesSynced,
        // eslint-disable-next-line no-shadow
        repositoriesCount,
      } = reducedStats;

      const {
        localDocsCount,
        remoteDocsCount,
        repositoryState,
        dataChangesCount,
        syncPullCount,
        syncPushCount,
      } = repositoryOrDefaultStats({
        dbName,
        repositoriesSyncStats: syncContextRepositoriesStats,
        requiredSyncState,
      });

      const updatedReduceTotalLocalDocsCount =
        reduceTotalLocalDocsCount + localDocsCount;

      const updatedReduceTotalRemoteDocsCount =
        reduceTotalRemoteDocsCount + remoteDocsCount;

      const updatedDataChangesCount =
        reducedDataChangesCount + dataChangesCount;

      const updatedSyncPullCount = reducedSyncPullCount + syncPullCount;

      const updatedSyncPushCount = reducedSyncPushCount + syncPushCount;

      const updatedReducedRepositoriesState = Math.min(
        reducedRepositoriesState,
        repositoryState,
      );

      // eslint-disable-next-line eqeqeq, no-shadow
      const repositorySynced = repositoryState >= requiredSyncState ? 1 : 0;

      const updatedReducedRepositoriesSynced =
        reducedRepositoriesSynced + repositorySynced;

      const updatedRepositoriesCount = repositoriesCount + 1;

      const updatedReducedStats = {
        reduceTotalLocalDocsCount: updatedReduceTotalLocalDocsCount,
        reduceTotalRemoteDocsCount: updatedReduceTotalRemoteDocsCount,
        reducedDataChangesCount: updatedDataChangesCount,
        reducedSyncPullCount: updatedSyncPullCount,
        reducedSyncPushCount: updatedSyncPushCount,
        reducedRepositoriesState: updatedReducedRepositoriesState,
        reducedRepositoriesSynced: updatedReducedRepositoriesSynced,
        repositoriesCount: updatedRepositoriesCount,
      };
      // d({msg:"Reduce stats iterration",data:{
      //   reducedStats,db:{iteration:repositoriesCount,dbName,localDocsCount,remoteDocsCount,repositoryState,requiredSyncState,repositorySynced},updatedReducedStats
      // }})
      return updatedReducedStats;
    },
    {
      reduceTotalLocalDocsCount: 0,
      reduceTotalRemoteDocsCount: 0,
      reducedDataChangesCount: 0,
      reducedSyncPullCount: 0,
      reducedSyncPushCount: 0,
      reducedRepositoriesState: Number.MAX_SAFE_INTEGER,
      reducedRepositoriesSynced: 0,
      repositoriesCount: 0,
      unsyncedRepositories: [],
      syncedRepositories: [],
    },
  );

  d({
    msg: 'Repository stats aggregation',
    data: {
      dbNames,
      repositoriesCount,
      localDocsCount: reduceTotalLocalDocsCount,
      remoteDocsCount: reduceTotalRemoteDocsCount,
      dataChangesCount: reducedDataChangesCount,
      syncPullCount: reducedSyncPullCount,
      syncPushCount: reducedSyncPushCount,
      repositoryState: reducedRepositoriesState,
      repositoriesSynced: reducedRepositoriesSynced,
    },
  });

  const retval = {
    localDocsCount: reduceTotalLocalDocsCount,
    remoteDocsCount: reduceTotalRemoteDocsCount,
    dataChangesCount: reducedDataChangesCount,
    syncPullCount: reducedSyncPullCount,
    syncPushCount: reducedSyncPushCount,
    repositoryState:
      reducedRepositoriesState !== Number.MAX_SAFE_INTEGER
        ? reducedRepositoriesState
        : 0,
    repositoriesSynced: reducedRepositoriesSynced,
    repositoriesCount,
  };

  return retval;
};

/**
 * @typedef useDbSyncState
 * @type object
 * @property {number} localDocsCount
 * @property {number} remoteDocsCount
 * @property {REPOSITORY_STATE} repositoryState
 */

/**
 *
 * @param {{dbNames:string[],repositoriesSyncStats:Map<string,any>,setSyncState:(useDbSyncState)=>void,setDb:(boolean)=>void,core:boolean}} args
 */
const calculateSyncState = ({
  currentSyncState,
  dbNames,
  allTables,
  repositoriesSyncStats,
  setSyncState,
  setDb,
  requiredSyncState,
  core,
  setDbStateCache,
  statsLogger,
}) => {
  const { d } = statsLogger;
  let calculatedDbNames =
    dbNames !== '*' ? dbNames || undefined : allTables || undefined;

  // If dbNames does not equal *, take dbNames as long as it is not undefined (otherwise undefined), if it does equal *, take allTables list as long as it is does not equal undfined (otherwise undefined).
  const calculateStats = (calculatedDbNames || core) && repositoriesSyncStats; // If we have both dbNames and repositoriesSyncStats we will perform stats calculation.
  if (calculateStats) {
    calculatedDbNames = Array.isArray(calculatedDbNames)
      ? [...calculatedDbNames]
      : [calculatedDbNames];
    // calculatedDbNames.push("core");
    const {
      localDocsCount,
      remoteDocsCount,
      dataChangesCount,
      syncPullCount,
      syncPushCount,
      repositoryState,
      repositoriesSynced,
      repositoriesCount,
    } = aggregateRepositoryStats({
      dbNames: calculatedDbNames,
      repositoriesSyncStats,
      requiredSyncState,
      statsLogger,
    });

    const progress =
      remoteDocsCount > 0 && localDocsCount >= 0
        ? (localDocsCount / remoteDocsCount) * 100
        : 0;

    const docsSyncStatusText =
      localDocsCount < remoteDocsCount ? 'Syncing...' : 'Completed.';

    // eslint-disable-next-line no-nested-ternary
    const repositoriesSyncStatusText =
      // eslint-disable-next-line no-nested-ternary
      repositoryState >= requiredSyncState
        ? 'Completed.'
        : localDocsCount < remoteDocsCount
          ? 'Syncing...'
          : 'Validating...';

    // TODO: There is an issue, if a page is browsed after already all its dbs been synced (completed), it will show
    // it will show 'Completed' status but variable repositoriesSynced will returned as 0 and dbs status bar will remain 0. Will review.
    // The progress bar do works well if page is entered right after app start.
    // const repositoriesSyncedHotFix =
    //   repositoryState >= requiredSyncState
    //     ? repositoriesCount
    //     : repositoriesSynced;
    const coreStats =
      core && repositoriesSyncStats.has('core')
        ? repositoriesSyncStats.get('core')
        : {
            localDocsCount: 0,
            remoteDocsCount: 0,
            repositoryState: REPOSITORY_EMPTY,
          };

    const {
      localDocsCount: coreLocalDocsCount,
      remoteDocsCount: coreRemoteDocsCount,
      repositoryState: coreRepositoryState,
    } = coreStats;

    const coreSyncStatusText =
      // eslint-disable-next-line no-nested-ternary
      coreRepositoryState >= REPOSITORY_STATE.REPOSITORY_PARTIALLY_SYNCED
        ? 'Completed.'
        : coreLocalDocsCount < coreRemoteDocsCount
          ? 'Syncing...'
          : 'Validating...';

    const syncStatsTrack =
      localDocsCount +
      remoteDocsCount +
      dataChangesCount +
      repositoryState +
      repositoriesSynced +
      repositoriesCount +
      coreLocalDocsCount +
      coreRemoteDocsCount +
      coreRepositoryState;

    const newSyncState = {
      localDocsCount,
      remoteDocsCount,
      dataChangesCount,
      syncPullCount,
      syncPushCount,
      docsSyncStatusText,
      repositoryState,
      progress,
      repositoriesSynced,
      repositoriesCount,
      repositoriesSyncStatusText,
      coreData: { ...coreStats, coreSyncStatusText },
      syncStatsTrack,
    };
    const syncStateUpdated = !isEqual(currentSyncState, newSyncState);
    d({
      msg: 'Calcaulted sync state status',
      data: {
        dbNames,
        syncStateUpdated,
        currentSyncState,
        newSyncState,
        coreData: coreStats,
      },
    });

    if (syncStateUpdated) {
      setSyncState(newSyncState);
      const coreSynced =
        !core ||
        coreRepositoryState >= REPOSITORY_STATE.REPOSITORY_PARTIALLY_SYNCED;
      const dbSynced = repositoryState >= requiredSyncState && coreSynced;
      setDbStateCache(dbNames, dbSynced);
      setDb(dbSynced);
    }
    // Backward comptability - in practice the screens do not access the db's / repositories themselves but only couchProvider, this is just a progress tracker / lock view.
  } else {
    setDb(false);
  }
};

const useStyles = makeStyles(
  () => ({
    progressBarsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
  }),
  { name: 'useDbProgressBar' },
);

const PROGRESS_STAGES = {
  UNKNOWN: 0,
  INTIALIZATION: 1,
  DOC_SYNC: 2,
  DBS_VALIDATION: 4,
  CORE_SYNC: 8,
  COMPLETED: 256,
};

const calculateProgressBarStage = ({
  localDocsCount = 0,
  remoteDocsCount = 0,
  repositoriesSynced = 0,
  repositoriesCount = 0,
  coreRemoteDocsCount = 0,
  coreLocalDocsCount = 0,
  showCoreProgressBar = false,
}) => {
  const {
    UNKNOWN,
    INTIALIZATION,
    DOC_SYNC,
    DBS_VALIDATION,
    CORE_SYNC,
    COMPLETED,
  } = PROGRESS_STAGES;

  let stage =
    remoteDocsCount === 0 ||
    repositoriesCount === 0 ||
    (showCoreProgressBar && coreRemoteDocsCount === 0)
      ? INTIALIZATION
      : UNKNOWN;
  stage =
    stage === UNKNOWN && localDocsCount < remoteDocsCount && remoteDocsCount > 0
      ? DOC_SYNC
      : stage;
  stage =
    stage === UNKNOWN &&
    repositoriesSynced < repositoriesCount &&
    repositoriesCount
      ? DBS_VALIDATION
      : stage;
  stage =
    stage === UNKNOWN &&
    showCoreProgressBar &&
    coreLocalDocsCount < coreRemoteDocsCount &&
    coreRemoteDocsCount > 0
      ? CORE_SYNC
      : stage;
  stage =
    stage === UNKNOWN &&
    localDocsCount === remoteDocsCount &&
    repositoriesSynced === repositoriesCount &&
    (!showCoreProgressBar || coreLocalDocsCount === coreRemoteDocsCount)
      ? COMPLETED
      : stage;
  // Or 'core' data sync is false or 'core' data sync is true (implicit) and data sync completed.

  return stage;
};

const isProgressUpdate = (currentStats, previousStats) => {
  const {
    localDocsCount,
    remoteDocsCount,
    repositoriesSynced,
    repositoriesCount,
    coreLocalDocsCount,
    coreRemoteDocsCount,
  } = currentStats;

  const {
    localDocsCount: previousLocalDocsCount,
    remoteDocsCount: previousRemoteDocsCount,
    repositoriesSynced: previousRepositoriesSynced,
    repositoriesCount: previousRepositoriesCount,
    coreLocalDocsCount: previousCoreLocalDocsCount,
    coreRemoteDocsCount: previousCoreRemoteDocsCount,
  } = previousStats;

  const progressBarStage = calculateProgressBarStage(currentStats);

  let difference = false;

  const {
    UNKNOWN,
    INTIALIZATION,
    DOC_SYNC,
    DBS_VALIDATION,
    CORE_SYNC,
    COMPLETED,
  } = PROGRESS_STAGES;

  switch (progressBarStage) {
    case UNKNOWN:
      break;
    case INTIALIZATION:
      break;
    case DOC_SYNC:
      difference =
        localDocsCount !== previousLocalDocsCount ||
        remoteDocsCount !== previousRemoteDocsCount;
      break;
    case DBS_VALIDATION:
      difference =
        repositoriesSynced !== previousRepositoriesSynced ||
        repositoriesCount !== previousRepositoriesCount;
      break;
    case CORE_SYNC:
      difference =
        coreLocalDocsCount !== previousCoreLocalDocsCount ||
        coreRemoteDocsCount !== previousCoreRemoteDocsCount;
      break;
    case COMPLETED:
      break;
    default:
      break;
  }

  return difference;
};

/**
 *
 * @param {*} props {
    localDocsCount=0,
    remoteDocsCount=0,
    repositoriesSynced=0,
    repositoriesCount=0,
    coreRemoteDocsCount = 0,
    coreLocalDocsCount = 0,
    showCoreProgressBar = false,
    onFullLoadProgressCompleted=undefined,
    text: {
      docsSyncStatusText = undefined,
      repositoriesSyncStatusText = undefined,
      coreSyncStatusText = undefined,
    },
  }
 * @returns
 */
export const ProgressBar = props => {
  const classes = useStyles();
  const onFullLoadProgressCompletedCalled = useRef(false);

  // 'name' var is used for debugging.
  const {
    localDocsCount = 0,
    remoteDocsCount = 0,
    repositoriesSynced = 0,
    repositoriesCount = 0,
    coreRemoteDocsCount = 0,
    coreLocalDocsCount = 0,
    showCoreProgressBar = false,
    onFullLoadProgressCompleted = undefined,
    name,
    text: {
      docsSyncStatusText = undefined,
      repositoriesSyncStatusText = undefined,
      coreSyncStatusText = undefined,
    } = {},
  } = props;

  const lastProps = useRef(props);

  const lastPropsUpdateTime = useRef(Date.now());
  const [progressStage, setProgressStage] = useState(0);
  const [progressHang, setProgressHang] = useState(false);
  const progressStageRef = useRef(0);

  useEffect(
    () => {
      const progressBarCompleted =
        localDocsCount > 0 &&
        localDocsCount === remoteDocsCount &&
        repositoriesSynced > 0 &&
        repositoriesSynced === repositoriesCount;

      const callOnAnimationCompleted =
        !onFullLoadProgressCompletedCalled.current &&
        progressBarCompleted &&
        onFullLoadProgressCompleted;

      if (callOnAnimationCompleted) {
        setTimeout(() => {
          if (!onFullLoadProgressCompletedCalled.current) {
            onFullLoadProgressCompletedCalled.current = true;
            onFullLoadProgressCompleted();
          }
        }, 1000 * 3.5);
      }
    },
    [props],
  );

  useEffect(
    () => {
      // TODO: currently simply pass props - should explicitly pass {localDocsCount,remoteDocsCount...etc}
      const progressUpdate = isProgressUpdate(props, lastProps.current);

      if (progressUpdate) {
        lastPropsUpdateTime.current = Date.now();
      }

      lastProps.current = props;

      const currentProgressStage = calculateProgressBarStage(props);
      if (progressStageRef.current !== currentProgressStage) {
        progressStageRef.current = currentProgressStage;
        setProgressStage(currentProgressStage);
        setProgressHang(false); // Upon progress stage change in case we had progress hang animation on we turn it off.
      }
    },
    [props],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const timeDelta = (now - lastPropsUpdateTime.current) / 1000;
      if (timeDelta > 5) {
        setProgressHang(true);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const {
    UNKNOWN,
    INTIALIZATION,
    DOC_SYNC,
    DBS_VALIDATION,
    CORE_SYNC,
    COMPLETED,
  } = PROGRESS_STAGES;
  // const translate = useTranslate();
  return (
    <div className={classes.progressBarsContainer}>
      <RecordSyncIndicator
        className={classes.recordSyncIndicator}
        fromRecords={localDocsCount}
        toRecords={remoteDocsCount}
        title="Docs"
        text={docsSyncStatusText}
        showHangLoading={progressStage === DOC_SYNC && progressHang}
        // text={translate('pos.hierarchy.loadingSecondary')}
      />
      <RecordSyncIndicator
        className={classes.recordSyncIndicator}
        fromRecords={repositoriesSynced}
        toRecords={repositoriesCount}
        title="Dbs"
        text={repositoriesSyncStatusText}
        showHangLoading={progressStage === DBS_VALIDATION && progressHang}

        // text={translate('pos.hierarchy.loadingSecondary')}
      />
      {showCoreProgressBar && (
        <RecordSyncIndicator
          className={classes.recordSyncIndicator}
          fromRecords={coreLocalDocsCount}
          toRecords={coreRemoteDocsCount}
          title="Core"
          text={coreSyncStatusText}
          showHangLoading={progressStage === CORE_SYNC && progressHang}

          // text={translate('pos.hierarchy.loadingSecondary')}
        />
      )}
      {/* {showHangLoadingAnimation && (

      )} */}
    </div>
  );
};

/**
 *
 * @param {*} props
 * @returns
 */
// eslint-disable-next-line no-unused-vars
const useDbProgressBar = props => progressBarProps => {
  const aggregatedProps = { ...props, progressBarProps };
  return <ProgressBar {...aggregatedProps} />;
};

// const lastStatsUpdateHash = ({dbNames,repositoriesSyncStats})=>{
//     const dbNamesStats = {};

//     dbNames =  dbNames.isArray() ? dbNames : [dbNames];

//     dbNames.reduce((previousValue,currentValue)=>{
//         const stats
//     },{})
// }

// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
function hashCode(str) {
  return (
    Array.from(str)
      // eslint-disable-next-line no-bitwise
      .reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0)
  );
}

// eslint-disable-next-line no-unused-vars
const dbStateCache = (() => {
  const dbStateCacheMap = new Map();

  const dbNamesHash = dbNames => {
    if (!dbNames) {
      return 0;
    }
    const dbNamesArray = Array.isArray(dbNames) ? dbNames : [dbNames];

    const dbNamesSorted = dbNamesArray.sort();

    const dbNamesConcat = dbNamesSorted.reduce((reducedValue, currentValue) => {
      const currentValueLowerCase = currentValue.toLowerCase();
      reducedValue += currentValueLowerCase;
      return reducedValue;
    }, '');

    const dbNamesHashValue = hashCode(dbNamesConcat);
    return dbNamesHashValue;
  };

  const setDbStateCache = async (dbNames, dbState) => {
    if (!dbNames) {
      return;
    }
    const dbNamesHashValue = dbNamesHash(dbNames);
    dbStateCacheMap.set(dbNamesHashValue, dbState);
  };

  const getDbStateCache = dbNames => {
    if (!dbNames) {
      return undefined;
    }
    const dbNamesHashValue = dbNamesHash(dbNames);
    const retval = dbStateCacheMap.has(dbNamesHashValue)
      ? dbStateCacheMap.get(dbNamesHashValue)
      : undefined;
    return retval;
  };

  return { setDbStateCache, getDbStateCache };
})();

const getRepositoriesStatsByNames = ({
  dbNames,
  repositoriesSyncStats,
  core,
}) => {
  const dbNamesArray = Array.isArray(dbNames) ? [...dbNames] : [dbNames];
  if (core) {
    dbNamesArray.push('core');
  }
  const dbNamesStats = dbNamesArray.reduce((extractedStatsMap, dbName) => {
    if (repositoriesSyncStats.has(dbName)) {
      const repositoryStats = repositoriesSyncStats.get(dbName);
      extractedStatsMap.set(dbName, repositoryStats);
    }
    return extractedStatsMap;
  }, new Map());
  return dbNamesStats;
};

export const __testAPI__ = {
  waitRepositoriesSyncState: calculateSyncState,
  getProgressBarStage: calculateProgressBarStage,
  aggregateRepositoryStats,
  PROGRESS_STAGES,
};

export default (
  // Can be an array of databases
  name,
  requiredSyncState = REPOSITORY_FULLY_SYNCED,
  core = false,
) => {
  const { setDbStateCache, getDbStateCache } = dbStateCache;
  const { repositoriesSyncStats, allTables, realTimeSync } = useContext(
    CouchDbReplicaProviderAppContext,
  );
  // TODO: get dbs from contextRepositoris and check their state, if data exists set useState(dbs) with existing dbs and not null as initial value - no need for two steps useEffect init.
  const dbStateCachedValue = getDbStateCache(name) || false; // The cache will return undefined in case none existing dbNames, in which case we set initial dbState (inited or not) to false.
  const [db, setDb] = useState(dbStateCachedValue);
  const [error, setError] = useState(null);
  const previousRepositoriesSyncStats = useRef(new Map());
  const previousDbNames = useRef(name);
  const [useDbLogger, setLogger] = useState(undefined);
  const [useDbInited, setUseDbInited] = useState(false);
  const realTimeSyncBegin = useRef(false);

  const [
    {
      localDocsCount,
      remoteDocsCount,
      docsSyncStatusText,
      progress,
      repositoryState,
      repositoriesSynced,
      repositoriesCount,
      repositoriesSyncStatusText,
      dataChangesCount,
      syncPullCount,
      syncPushCount,
      coreData: {
        localDocsCount: coreLocalDocsCount,
        remoteDocsCount: coreRemoteDocsCount,
        repositoryState: coreRepositoryState,
        coreSyncStatusText,
      },
      syncStatsTrack,
    },
    setSyncState,
  ] = useState({
    localDocsCount: 0,
    remoteDocsCount: 0,
    docsSyncStatusText: 'Initializing...',
    progress: 0,
    repositoryState: REPOSITORY_EMPTY,
    repositoriesSynced: 0,
    repositoriesCount: 0,
    dataChangesCount: 0,
    syncPullCount: 0,
    syncPushCount: 0,
    repositoriesSyncStatusText: 'Initializing...',
    coreData: {
      localDocsCount: 0,
      remoteDocsCount: 0,
      repositoryState: REPOSITORY_EMPTY,
      coreSyncStatusText: 'Initializing...',
    },
    syncStatsTrack: 0,
  });
  const updateSyncStats = () => {
    try {
      const waitRepositoriesSyncStateRequiredVariables =
        useDbLogger &&
        (name || core) &&
        repositoriesSyncStats &&
        repositoriesSyncStats.size > 0;
      if (waitRepositoriesSyncStateRequiredVariables) {
        // This flag signlas if the repositories stats have been updated compared to previous ones, begins with state true, will be changed to false if no,

        let repositoriesStatsUpdated = true;
        // eslint-disable-next-line no-undef-init
        if (name !== '*') {
          const updatedRepositoriesStats = getRepositoriesStatsByNames({
            dbNames: name,
            core,
            repositoriesSyncStats,
          });
          repositoriesStatsUpdated = !isEqual(
            previousRepositoriesSyncStats.current,
            updatedRepositoriesStats,
          );
          previousRepositoriesSyncStats.current = repositoriesStatsUpdated
            ? updatedRepositoriesStats
            : previousRepositoriesSyncStats.current;
        }
        // If repositories stats have been updated, we will calculate the sync state.
        if (repositoriesStatsUpdated) {
          calculateSyncState({
            currentSyncState: {
              localDocsCount,
              remoteDocsCount,
              progress,
              repositoryState,
              repositoriesSynced,
              repositoriesCount,
              dataChangesCount,
              syncPullCount,
              syncPushCount,
            },
            setDb,
            setSyncState,
            dbNames: name,
            repositoriesSyncStats,
            requiredSyncState,
            allTables,
            core,
            setDbStateCache,
            statsLogger: useDbLogger,
          });
        }
      }
    } catch (errorSyncState) {
      if (useDbLogger) {
        useDbLogger.e({
          msg: 'Failed to wait for repository to sync',
          errorSyncState,
        });
      } else {
        console.log(`Failed to wait for repository to sync ${errorSyncState}`);
      }
      setError(errorSyncState);
    }
  };

  const beginRealTimeSync = useCallback(async () => {
    if (name && !realTimeSyncBegin.current) {
      const isSymt = name === 'symt' || name?.includes('symt');

      const isAllDbs = name === '*' || name?.includes('*');

      if (isSymt || isAllDbs) {
        realTimeSyncBegin.current = false;
        return;
      }

      const nameArray = Array.isArray(name) ? name : [name];
      const result = await realTimeSync(nameArray);
      realTimeSyncBegin.current = result;
    }
  });
  // Initializtion effect when 'name' is set by calling component.
  useEffect(
    () => {
      if (!name || name?.length === 0) {
        return;
      }

      const nameArray = Array.isArray(name) ? name : [name];

      const newNames = !isEqual(previousDbNames.current, nameArray);

      if (newNames) {
        realTimeSyncBegin.current = undefined;
        previousDbNames.current = nameArray;
      }

      if (!useDbInited) {
        const nameString = nameArray.toString();
        // Each useDb has its own logger, using the identifier param, it enables critical
        // easy tracking of stats updates for specific screens, otherwise stats
        // of different screens would be console logged all with useDb identifier and would
        // be impossible to track.
        const newUseDbLogger = logger({
          source: 'useDb',
          identifier: nameString,
        });
        setLogger(newUseDbLogger);
        setUseDbInited(true);
      }
    },
    [name, useDbInited],
  );

  // Handls stats updates from CouchDbReplicaProvder sync context.
  useEffect(
    () => {
      if (name && useDbInited) {
        updateSyncStats();
        beginRealTimeSync();
      }
    },
    [name, repositoriesSyncStats, allTables, useDbInited],
  );

  useEffect(
    () => () => {
      realTimeSyncBegin.current = false;
    },
    [],
  );

  /**
   * TODO: add current latest update couch/pouch sequence id, this will enable re-rending upon changes for hook users.
   */
  return {
    db,
    sync: {},
    error,
    syncState: {
      localDocsCount,
      remoteDocsCount,
      dataChangesCount,
      syncPullCount,
      syncPushCount,
      progress,
      repositoryState,
      repositoriesSynced,
      repositoriesCount,
      coreData: {
        localDocsCount: coreLocalDocsCount,
        remoteDocsCount: coreRemoteDocsCount,
        repositoryState: coreRepositoryState,
      },
      syncStatsTrack,
      text: {
        docsSyncStatusText,
        repositoriesSyncStatusText,
        coreSyncStatusText,
      },
    },
  };
};

// TODO : design error page.
export const UseDbError = () => (
  <>"Error loading data, please contact support."</>
);

import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#00138E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#2034B2',
      contrastText: '#FFFFFF',
    },
    background: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    header: {
      main: '#000737',
      contrastText: '#FFFFFF',
    },
    type: 'light',
  },
});

export const darkTheme = createMuiTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#6E86FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#6E86FF',
      contrastText: '#FFFFFF',
    },
    background: {
      main: '#252525',
      contrastText: '#FFFFFF',
    },
    header: {
      main: '#3B3B3B',
      contrastText: '#FFFFFF',
    },
    type: 'dark',
  },
});

import AWS from 'aws-sdk';

/**
 * Creates a CloudWatch logger with methods to initialize, queue logs, flush the queue, and log events to CloudWatch.
 * @returns {Object} An object containing methods for interacting with the CloudWatch logger.
 */
export const cloudWatchLogStreamFactory = () => {
  /**
   * Initializes the CloudWatchLog stream factory.
   *
   * @param {Object} options - The options for initializing the logger.
   * @param {string} options.logStreamName - The name of the log stream.
   * @param {string} options.logGroupName - The name of the log group.
   * @param {Object} options.credentials - The credentials for accessing CloudWatchLogs.
   * @param {string} options.credentials.accessKeyId - The access key ID for accessing CloudWatchLogs.
   * @param {string} options.credentials.secretAccessKey - The secret access key for accessing CloudWatchLogs.
   * @param {string} options.region - The region for accessing CloudWatchLogs.
   * @throws {Error} If logGroupName or logStreamName is empty, or if credentials is empty.
   * @returns {Promise<void>} A promise that resolves when the initialization is complete.
   */
  const withCustomOptions = async ({
    logStreamName: logStreamNameParam,
    logGroupName: logGroupNameParam,
    credentials,
    region,
  }) => {
    console.log('cloud watch logger init');
    if (!logGroupNameParam || !logStreamNameParam) {
      const errorMessage = 'Failed to init CloudWatchLogs empty logStreamName';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    if (!credentials) {
      const errorMessage = 'Failed to init CloudWatchLogs empty credentials';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    // Temporary credentials
    const credentialsInstance = new AWS.Credentials(
      credentials.AccessKeyId,
      credentials.SecretAccessKey,
      credentials.SessionToken,
    );

    const cloudwatchLogClient = new AWS.CloudWatchLogs({
      region,
      credentials: credentialsInstance,
      apiVersion: '2014-03-28',
    });

    const cloudWatchLogStreamInstance = cloudWatchLogStream({
      logGroupName: logGroupNameParam,
      logStreamName: logStreamNameParam,
      cloudwatchLogClient,
    });
    await cloudWatchLogStreamInstance.initCloudWatchLogStream();
    return cloudWatchLogStreamInstance;
  };

  /**
   * Initializes the CloudWatchLogger.
   *
   * @param {Object} options - The options for initializing the logger.
   * @param {string} options.logStreamName - The name of the log stream.
   * @param {string} options.logGroupName - The name of the log group.
   * @throws {Error} If logGroupName or logStreamName is empty, or if credentials is empty.
   * @returns {Promise<void>} A promise that resolves when the initialization is complete.
   */
  const withDefaultOptions = async ({
    logStreamName: logStreamNameParam,
    logGroupName: logGroupNameParam,
  }) => {
    console.log('cloud watch logger init');
    if (!logGroupNameParam || !logStreamNameParam) {
      const errorMessage = 'Failed to init CloudWatchLogs empty logStreamName';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    // We assume AWS has been configured with the correct credentials and region
    const cloudwatchLogClient = new AWS.CloudWatchLogs({});
    const cloudWatchLogStreamInstance = cloudWatchLogStream({
      logGroupName: logGroupNameParam,
      logStreamName: logStreamNameParam,
      cloudwatchLogClient,
    });
    await cloudWatchLogStreamInstance.initCloudWatchLogStream();
    return cloudWatchLogStreamInstance;
  };

  return { withCustomOptions, withDefaultOptions };
};

/**
 * Creates a CloudWatch log stream with methods to queue logs, flush the queue, and log events to CloudWatch.
 * @param {Object} params - The params for initializing the log stream.
 * @param {string} params.logGroupName - The name of the log group.
 * @param {string} params.logStreamName - The name of the log stream.
 * @param {AWS.CloudWatchLogs} params.cloudwatchLogClient - The CloudWatchLogs client instance.
 * @throws {Error} If logGroupName, logStreamName, or cloudwatchLogClient is empty.
 * @returns {Object} An object containing methods for interacting with the CloudWatch log stream.
 */
const cloudWatchLogStream = ({
  logGroupName,
  logStreamName,
  cloudwatchLogClient,
}) => {
  if (!logGroupName || !logStreamName || !cloudwatchLogClient) {
    throw new Error('Invalid cloudwatch init parameters');
  }

  let logsQueue = [];
  let queueInterval;
  const CLOUD_WATCH_MAX_BATCH = 1000;

  /**
   * Initializes the CloudWatch log stream by creating the log stream if it doesn't exist.
   * @returns {Promise<void>} A promise that resolves when the log stream is initialized.
   */
  const initCloudWatchLogStream = () => {
    try {
      const createLogStreamParams = {
        logGroupName,
        logStreamName,
      };
      return new Promise((resolve, reject) => {
        cloudwatchLogClient.createLogStream(
          createLogStreamParams,
          (error, data) => {
            console.log(`data ${data} error ${error}`);
            if (error) {
              reject(error);
            } else if (data) {
              resolve(data);
            } else {
              reject(new Error('Failed to create log stream, empty data'));
            }
          },
        );
      });
    } catch (error) {
      // 'instanceof' is for JS && Typescript compatability
      if (
        error &&
        error instanceof Error &&
        error.name !== 'ResourceAlreadyExistsException'
      ) {
        console.error('Error creating log stream:', error);
        throw error;
      }
    }
  };

  /**
   * Starts the interval to flush the log queue at a specified interval.
   * @param {number} interval - The interval in milliseconds at which to flush the log queue. Default is 1ms.
   */
  const startFlushQueueInterval = (interval = 1) => {
    queueInterval = setInterval(() => {
      if (logsQueue.length > 0) {
        flushQueue();
      }
    }, interval);
  };

  /**
   * Stops the interval for flushing the log queue.
   */
  const stopQueueInterval = () => {
    if (queueInterval) {
      clearInterval(queueInterval);
    }
  };

  /**
   * Queues a log event to be logged to CloudWatch.
   * @param {Object} event - The log event to be queued.
   * @param {number} event.timestamp - The timestamp of the log event.
   * @param {string} event.message - The message of the log event.
   */
  const queueLog = event => {
    if (!event) {
      console.warn('Cloudwatch log event is empty');
      return;
    }
    const concatArray = Array.isArray(event) ? event : [event];

    if (logsQueue.length + concatArray.length <= CLOUD_WATCH_MAX_BATCH) {
      logsQueue = [...logsQueue, ...concatArray];
    } else {
      // TODO: do not write to console error directly but use log error to prevent overflowing the console.
      console.error(
        `Cloud watch max batch exceeded \n batch > ${CLOUD_WATCH_MAX_BATCH}`,
      );
    }
  };

  /**
   * Flushes the log queue by logging the queued events to CloudWatch.
   * @returns {Promise<void>} A promise that resolves when the log queue is flushed.
   */
  const flushQueue = async () => {
    try {
      if (logsQueue.length > 0) {
        const logsQueueClone = [...logsQueue];
        await log(logsQueueClone);
        logsQueue = [];
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Logs events to CloudWatch.
   * @param {Array} logEvents - An array of CloudWatch logger events.
   * @returns {Promise} A promise that resolves when the log events are successfully logged.
   */
  const log = logEvents => {
    let retval;

    if (!logEvents || logEvents.length === 0) {
      console.error('CloudWatchLogs logEvents empty');
      retval = Promise.resolve();
    }

    try {
      if (!retval) {
        const putLogEventsParams = {
          logEvents,
          logGroupName,
          logStreamName,
        };
        // We implement as promise to explicitly handle errors as part of the applicative flow - without exceptions.
        retval = new Promise(resolve => {
          cloudwatchLogClient.putLogEvents(
            putLogEventsParams,
            (error, data) => {
              console.log(`cloudwatch logs : \n data ${data} error ${error}`);
              // TODO: handle  408-timeout-error which can occur for connectivity issues with a retry.
              resolve({ data, error }); // We simply resolve the promise here, as we don't want to interrupt the execution of the code.
            },
          );
        });
      }
    } catch (error) {
      console.log(error);
      retval = Promise.resolve();
    }
    return retval;
  };

  return {
    queueLog,
    initCloudWatchLogStream,
    flushQueue,
    log,
    startFlushQueueInterval,
    stopQueueInterval,
  };
};

/**
 * Converts log events to CloudWatch log events.
 * @param {Object|Array} logEvents - The log events to be converted.
 * @returns {Array} An array of CloudWatch log events.
 */
export const cloudWatchEventsFromLogEvents = logEvents => {
  if (!logEvents) {
    return undefined;
  }
  const logEventsArray = Array.isArray(logEvents) ? logEvents : [logEvents];

  const cloudWatchLogEvents = logEventsArray
    .filter(event => event)
    .map(event => {
      const logData = event?.data?.logData;
      const logDataString = event?.data?.logDataString;
      const { epoch } = logData;
      const cloudWatchEvent = { timestamp: epoch, message: logDataString };
      return cloudWatchEvent;
    });
  return cloudWatchLogEvents;
};

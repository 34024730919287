import React, { useEffect } from 'react';

import { logger } from '../../utils/logger';
import {
  cloudWatchLogStreamFactory,
  cloudWatchEventsFromLogEvents,
} from '../../utils/cloudWatchLogger';
import { listener } from '../../utils/eventshub';
import { awsCoudWatchLogConfig } from '../../isomorphic/config';

const { listen, unlisten } = listener;

const { e } = logger({ source: 'app-cloud-watch-logger' });

const CloudWatchLogger = () => {
  useEffect(() => {
    initCloudWatchLogger();
  }, []);

  return null;
};

export default CloudWatchLogger;

const initCloudWatchLogger = () => {
  let cloudWatchLogStream;

  /**
   * COMMENT : we register to the auth event and not using useGetIdentity RA hook because
   * it did not work on RA 3.04- RA should be updated and then retry.
   */
  const onAuthEventHandler = async event => {
    try {
      const { enable: awsCloudWatchLogEnable } = awsCoudWatchLogConfig;
      console.log(`awsCloudWatchLogEnable: ${awsCloudWatchLogEnable}`);
      if (!awsCloudWatchLogEnable) {
        return;
      }
      // We first, clear any previous listener and then re-register the listener
      unlisten({ channel: 'log', callback: onLogEventHandler });
      console.log(`Unregistered  Cloudwatch log listener`);

      const { loginState, awsAccessToken } = event?.data || {};

      if (!loginState || loginState === 'loggedOut') {
        return;
      }

      const { credentials, cloudWatchLogs } = awsAccessToken || {};
      const { group, stream } = cloudWatchLogs || {};

      console.log(
        `Initing cloudwatch logger with group ${group} and stream ${stream}`,
      );
      if (credentials && group && stream) {
        const { withCustomOptions } = cloudWatchLogStreamFactory();
        cloudWatchLogStream = await withCustomOptions({
          logStreamName: stream,
          logGroupName: group,
          credentials,
          region: 'us-east-1',
        });
        console.log(`Cloudwatch log stream inited`);
        cloudWatchLogStream.startFlushQueueInterval();

        /** TODO :
         ** Reduced limit over AWS cloudwatch, in case exceeding, before further optimization
         ** would review log sections that can be reduced or would move sync logging
         ** into a single unified aggregted stats logger to reduce log calls.
         * */
        listen({
          channel: 'log',
          listenerName: 'aws-cloud-watch-logger',
          callback: onLogEventHandler,
          throttle: { milliThrottle: 2500, maxEventsThrottle: 500 },
        });

        console.log(`Registered Cloudwatch log listener`);
      } else {
        console.warn(
          `Did not receive sufficent data to init cloudwatch logger`,
        );
      }
    } catch (error) {
      e({ msg: 'Failed to init aws-cloud-watch logger', error });
    }
  };
  listen({
    channel: 'auth',
    callback: onAuthEventHandler,
    listenerName: 'awsCloudWatchLog',
  });

  const onLogEventHandler = events => {
    try {
      if (!cloudWatchLogStream) {
        e({
          msg: 'Cloud watch logger not inited',
          data: { logEvents: events },
        });
      }
      if (!events) {
        e({ msg: 'Empty log events', data: { logEvents: events } });
      }
      const cloudWatchLogEvents = cloudWatchEventsFromLogEvents(events);

      cloudWatchLogStream.queueLog(cloudWatchLogEvents);
    } catch (error) {
      console.error(
        `Failed to write events to aws-cloud-watch \n error ${error} \n ${events}`,
      );
    }
  };
};

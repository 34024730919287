import loadable from '@loadable/component';
const ChecksheetConList = loadable(() => import('./ConnectionList'));
const ChecksheetEdit = loadable(() => import('./ConnectionEdit'));
const ChecksheetConCreate = loadable(() => import('./ConnectionCreate'));

export default {
  list: ChecksheetConList,
  edit: ChecksheetEdit,
  create: ChecksheetConCreate,
};

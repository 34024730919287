import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';
import { Notification, useTranslate, useLogin, useNotify } from 'react-admin';

import { useAuth } from '../hooks';

import { appMakeStyles } from './AppThemeManager';
import complanLogo from './complan_logo_white.png';

const useStyles = appMakeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.header.main,
  },
  logo: {
    // paddingBottom: 20,
    marginTop: '3em',
    marginBottom: '3em',
  },
  card: {
    minWidth: 300,
    // marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  marginRight: {
    marginRight: '12px',
  },
  textCenter: {
    textAlign: 'center',
  },
  loginFormButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  linkButton: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    textDecoration: 'underline',
    padding: 0,
    minWidth: 0,
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
    },
  },
}));

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const Login = ({ location, history }) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const { setAuth, isAuthed } = useAuth();
  const [redirectToReferrer] = useState(location.state?.from?.pathname || '/');

  if (isAuthed) {
    history.push(redirectToReferrer);
  }

  const handleSubmit = auth => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/')
      .then(() => {
        setLoading(false);
        setAuth(true);
      })
      .catch(error => {
        setLoading(false);
        setAuth(false);
        let message;
        if (typeof error === 'string') {
          message = error;
        } else if (typeof error === 'undefined' || !error.message) {
          message = 'ra.auth.sign_in_error';
        } else {
          // eslint-disable-next-line prefer-destructuring
          message = error.message;
        }
        notify(message, 'warning');
      });
  };

  const validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit: handleSubmitRenderProp }) => (
        <form onSubmit={handleSubmitRenderProp} noValidate>
          <div className={classes.main}>
            <img
              src={complanLogo}
              alt="Complan Logo"
              className={classes.logo}
              style={{
                width: '500px',
                maxWidth: '90%',
              }}
            />
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                    inputProps={{ autoCapitalize: 'none' }}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  href="/signup"
                  fullWidth
                  className={classes.loginFormButton}
                >
                  {translate('resources.signup.signupText')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                  className={classes.loginFormButton}
                >
                  {loading && (
                    <CircularProgress
                      size={10}
                      thickness={2}
                      className={classes.marginRight}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  className={classes.linkButton}
                  href="/reset-password"
                >
                  {translate('resources.signup.forgotPassword')}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  // authProvider: PropTypes.func,
  // previousRoute: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default Login;

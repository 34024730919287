import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import PouchDBDebug from 'pouchdb-debug';
import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDbIndexedDb from 'pouchdb-adapter-indexeddb';

import PouchDBQuickSearch from '../../packages/pouchdb-quick-search/lib/index';
import log, { SEVERITY, logger } from '../utils/logger';
import { dispatcher } from '../utils/eventshub';

import PouchDBUndo from './pouchdb-undo';

/** For inpersistant memory db (e.g mainly used for unit testing) make sure you call the 'addPlugin' function like so in your code:
 *
 * import PouchDbMemoryDb from 'pouchdb-adapter-memory';
 * import {addPlugin} from './pouchDb'
 *
 * addPlugin(PouchDbMemoryDb);
 *
 * and in Jest :
 *
 * beforeAll(()=>{
 * addPlugin(PouchDbMemoryDb);
 * })
 *
 * You may also :
 * import {addPouchPlugin} from "./SyncManager"
 *
 * and do the same if your sole interface is the sync manager.
 */

// eslint-disable-next-line no-unused-vars
const { dispatch } = dispatcher;

PouchDB.plugin(PouchDbIndexedDb);
PouchDB.plugin(PouchDBAuthentication);
PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBUndo);
PouchDB.plugin(PouchDBDebug);
PouchDB.plugin(PouchDBQuickSearch);

PouchDB.debug.enable('pouchdb:find');

export const addPlugin = plugin => PouchDB.plugin(plugin);

/**
 * A unified interface API for CouchDB REST api and PouchDB js lib
 * enabling the repositories seamless/unified API when calling either PouchDB
 * or CouchDB in online or offline mode.
 *
 */
export const pouchDbApiInstance = ({
  localDbName,
  remoteDBServerAddress,
  remoteDBName,
  events,
  replicationTimeout,
  pouchAdapter,
  lazyInit = true,
}) => {
  const loggerIdentifier = `pouchdb/${localDbName || ''}`;
  const { d, e, v } = logger({
    source: 'pouchdb-api',
    identifier: loggerIdentifier,
  });

  d({
    msg: 'Creating pouch api',
    data: {
      localDbName,
      remoteDBServerAddress,
      remoteDBName,
      events,
      replicationTimeout,
      pouchAdapter,
    },
  });

  const validateInitParameters = localDbName && remoteDBServerAddress;

  if (!validateInitParameters) {
    e({
      msg: 'Missing pouch api init parameters',
      data: { localDbName, remoteDBServerAddress, remoteDBName },
    });
  }

  const {
    onPouchDBChange = () => {},
    onPouchDBPaused = () => {},
    onPouchDBDenied = () => {},
    onPouchDBCompleted = () => {},
    onPouchDBError = () => {},
    onPouchDBActive = () => {},
  } = events || {};

  // TODO : rewrite next line less verbose.
  let localPouchDb;
  let remotePouchDb;

  const pouchFetch = (url, opts) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && opts?.headers) {
      opts.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort();
    }, 60000);
    opts = { ...opts, signal: controller.signal };
    return PouchDB.fetch(url, opts)
      .then(response => {
        clearTimeout(timeout);
        return response;
      })
      .catch(error => {
        clearTimeout(timeout);
        if (error.name === 'AbortError') {
          e({ msg: 'Fetch request timed out', error });
          throw new Error('Fetch request timed out');
        }
        e({ msg: 'Fetch request error', error });
        throw error;
      });
  };

  // Skip_setup option avoids creating the indexeddb until it is required, part of our lazy-loading methdology, which is the prefered option in our scenario as we init 280+ repositories & thier pouch instances but would like to acctually use based on prioritiation and queues.
  const localInitCheck = () => {
    if (!localPouchDb) {
      localPouchDb = pouchAdapter
        ? new PouchDB(localDbName, {
            adapter: pouchAdapter,
            skip_setup: true,
            fetch: pouchFetch,
          })
        : new PouchDB(localDbName, {
            skip_setup: true,
            fetch: pouchFetch,
          });
    }
  };

  const remoteInitcheck = () => {
    if (!remotePouchDb) {
      remotePouchDb = new PouchDB(remoteDBServerAddress, {
        skip_setup: true,
        fetch: pouchFetch,
      });
    }
  };

  if (!lazyInit) {
    localInitCheck();
    remoteInitcheck();
  }

  let replication;
  let replicating;
  let replicationIdentifier;

  let localEditFlag = false;
  let localEditsCount = 0;

  // TODO : Check if state var is needed , active,fault,completed

  // TODO : Check how timeout error looks like ...

  // TODO: For large DBs we should use isolated batches replication fetch, where each replicate will
  // only replicate K*10^3 docs till completion to prevent too long sync time for a single db.

  // TODO : Test blobs/attachements replication for large MBs handling - can block replication over a single large blob.

  /**
   * We use an iterative periodic prioritized manaully scheduled sync proces and hence do not use the
   * live (infinte streaming) sync.
   * @param {*} direction "pull" or "push"
   */
  const replicate = ({
    direction,
    filter,
    replicaitonIdentifier: replicaitonIdentifierParam,
  }) => {
    localInitCheck();
    remoteInitcheck();
    d({
      context: replicaitonIdentifierParam,
      msg: 'pouchdb replication called',
      data: {
        direction,
        filter,
        replicaitonIdentifier: replicaitonIdentifierParam,
      },
    });
    // Using replicate, we only allow a single direction replication at a time to optimize performance
    // if bi directional sync is required we will simply expose the 'sync' pouchdb method.
    if (replicating) {
      const replicatingErrorMessage = 'Pouch db already replicating';
      e({
        context: replicaitonIdentifierParam,
        msg: replicatingErrorMessage,
        data: { replicaitonIdentifier: replicaitonIdentifierParam, direction },
      });
      throw new Error(replicatingErrorMessage);
    }

    const { filterName, queryParams } = filter || {};

    // Push replication options is the base for both push and pull replication options.
    // Mainly to enable Pouch to retry over temporary communication or db errors
    // replication failure will occur and determined and error will be thrown under timeout limit expcetion
    // hence multiple internal retries are allowed as long as replication is under time limits, if not successfull
    // exception will be thrown.
    const pushReplicationOptions = {
      live: false,
      retry: true,
    };

    let pullReplicationOptions = { ...pushReplicationOptions };

    // Simple util  to validate and add fields to the configuration object instead of copy & paste.
    const setConfigurationField = (key, value) => {
      // Values can be 0 hence we do not do simple truth/falsy check.
      const validKey = typeof key !== 'undefined' && key != null;
      const validValue = typeof value !== 'undefined' && value != null;
      const valid = validKey && validValue;

      if (valid) {
        pullReplicationOptions = { ...pullReplicationOptions, [key]: value };
      }

      return setConfigurationField;
    };
    setConfigurationField('filter', filterName)('query_params', queryParams)(
      'timeout',
      replicationTimeout,
    )('batch_size', 2500); // TODO : Make configurable per db as autopl has larger objects, 2500 seemed to work very well in-front of Render.com but must be tested for multiple users, perhaps can be further increased - need to check average return size.
    d({
      context: replicaitonIdentifierParam,
      msg: 'Pouch db configuration fields',
      data: { pullReplicationOptions },
    });

    if (direction === 'pull') {
      replication = localPouchDb.replicate.from(
        remotePouchDb,
        pullReplicationOptions,
      );
    } else if (direction === 'push') {
      replication = localPouchDb.replicate.to(
        remotePouchDb,
        pushReplicationOptions,
      );
    } else {
      throw new Error('Replication direction must be pull or push');
    }
    // eslint-disable-next-line no-unused-vars
    let lastChangeInfo; // Currently for debugging purposes
    replication
      .on('change', info => {
        const { docs_written = 0, docs_read = 0 } = info;

        // COMMENT :
        // Since we perform pull replication and push replication separately,
        // after edits and a subsequent push, the pull operation will trigger a change event.
        // If the change involves same document counts as counted in our add/update/delete db edits counts, it is identified as our own change, and we skip the event.
        // In the case of consecutive events occurring on the server simultaneously with same number of edits (e.g where 1 edit would be the common scneario ...), we need to ignore one of them,
        // It does not matter which one is ignored.
        // TODO: in case same doc is edited multiple times prior to replication, verify how pouch manages multiple edits prior to replication as  edits_counts !=?? docs_written, in that case
        // only solution would be to refresh page automatically after edit is performed to get latest data. TBD - verify.
        const replicationChangesEqualLocalEdits =
          docs_written === localEditsCount && docs_read === localEditsCount;
        const localEditsOnChange =
          localEditFlag && replicationChangesEqualLocalEdits;

        lastChangeInfo = info;

        const logInfo = { ...info, docs: undefined };

        const eventData = {
          logInfo,
          direction,
          filter,
          replicaitonIdentifier: replicaitonIdentifierParam,
          localEditsOnChange,
        };

        logPouchEvent('change', eventData);

        const fireEvent = !localEditsOnChange;

        localEditFlag = false;
        localEditsCount = 0;

        if (fireEvent) {
          onPouchDBChange(eventData);
        } else {
          v({
            context: replicaitonIdentifierParam,
            msg: 'Local edits detected, skipping event fire',
            data: eventData,
          });
        }
      })
      .on('paused', info => {
        // TODO: there might be multiple consequentive pause events
        const { error } = info || {};
        const eventData = {
          info,
          direction,
          filter,
          replicaitonIdentifier: replicaitonIdentifierParam,
        };
        const pouchEventType = !error ? 'paused' : 'error';
        logPouchEvent(pouchEventType, eventData);
        stoppedReplicatingCommonEventHandler();
        if (!error) {
          onPouchDBPaused(eventData);
        } else {
          onPouchDBError(eventData);
        }
      })
      .on('active', () => {
        const eventData = {
          direction,
          filter,
          replicaitonIdentifier: replicaitonIdentifierParam,
        };
        logPouchEvent('active', eventData);
        onPouchDBActive(eventData);
      })
      .on('denied', error => {
        const eventData = {
          direction,
          filter,
          replicaitonIdentifier: replicaitonIdentifierParam,
          error,
        };
        logPouchEvent('denied', eventData);
        stoppedReplicatingCommonEventHandler();
        onPouchDBDenied(eventData);
      })
      .on('complete', info => {
        const eventData = {
          info,
          direction,
          filter,
          replicaitonIdentifier: replicaitonIdentifierParam,
        };
        logPouchEvent('complete', eventData);
        stoppedReplicatingCommonEventHandler();
        onPouchDBCompleted(eventData);
      })
      .on('error', error => {
        const eventData = {
          direction,
          filter,
          replicaitonIdentifier: replicaitonIdentifierParam,
          error,
        };
        logPouchEvent('error', eventData);
        stoppedReplicatingCommonEventHandler();
        onPouchDBError(eventData);
      });
    replicating = true;
    replicationIdentifier = replicaitonIdentifierParam;
    // Check if should fire original pouch api error or should propogate other data.
  };

  const cancelReplication = () => {
    if (replication) {
      replication.cancel();
      v({ context: replicationIdentifier, msg: 'Replication cancled' });
    }
    replicating = false;
  };

  const logPouchEvent = (type, eventdata) => {
    let severity = SEVERITY.info;
    switch (type) {
      case 'error':
        severity = SEVERITY.error;
        break;
      case 'denied':
        severity = SEVERITY.error;
        break;
      case 'change':
        severity = SEVERITY.verbose;
        break;
      default:
        severity = SEVERITY.info;
        break;
    }
    const pouchLogMessage = {
      source: 'pouchdb-api',
      identifier: localDbName,
      context: replicationIdentifier,
      severity,
      data: {
        eventdata,
        pouchEventType: type,
        localPouchDb,
        remotePouchDb,
      },
    };
    log(pouchLogMessage);
  };

  const stoppedReplicatingCommonEventHandler = () => {
    replicating = false;
    replicationIdentifier = undefined;
  };

  const isReplicating = () => replicating;

  const halt = () => {
    // will throw an error.
    // replication.cancelled = true;
    // No need to set replicating=false, an error event will be thrown.
  };

  /**
   * Retreives number of docs in db and last sequence - only slim wrap is currently required.
   * @returns
   */
  const info = async ({ local: localInfo } = { local: false }) => {
    try {
      v({ msg: 'calling pouch db info' });

      localInitCheck();
      const localInfoPromise = localPouchDb.info().catch(reason => {
        e({
          msg:
            'Failed calling local pouch db info, returning local doc count = -1',
          error: reason,
        });
        return { doc_count: -1 };
      });

      if (!localInfo) {
        remoteInitcheck();
      }

      const remoteInfoPromise = !localInfo
        ? remotePouchDb.info().catch(reason => {
            e({
              msg:
                'Failed calling remote pouch db info, returning remote doc count = -1',
              error: reason,
            });
            return { doc_count: -1 };
          })
        : Promise.resolve().then(() => ({ doc_count: -1 }));

      const infoPromiseAllResult = await Promise.all([
        localInfoPromise,
        remoteInfoPromise,
      ]);

      const result = {
        local: infoPromiseAllResult[0],
        remote: infoPromiseAllResult[1],
      };

      v({ msg: 'pouch db info returned', data: { localInfo, result } });

      return result;
    } catch (error) {
      e({ msg: 'pouch db info call failed', error });
      throw error;
    }
  };

  const all = async (options = {}) => {
    try {
      v({ msg: 'calling pouch db all' });
      localInitCheck();
      const result = await localPouchDb.allDocs(options);
      v({ msg: 'pouch db all docs returned' });
      return result;
    } catch (error) {
      e({ msg: 'pouch db all docs failed', error });
      throw error;
    }
  };

  const get = ({ id, options = {} }) => {
    v({ msg: 'calling pouch db get', data: { id } });
    if (!id) {
      e({ msg: 'id not specified for get call.', throwError: true });
    }
    localInitCheck();
    return localPouchDb
      .get(id, options)
      .then(
        doc => {
          v({ msg: 'pouch db get doc found ', data: { doc } });
          return doc;
        },
        reason => {
          v({ msg: 'pouch db get doc not found ', data: { reason } });
          return undefined;
        },
      )
      .catch(reason => {
        throw reason;
      });
  };

  const find = options => {
    v({ msg: 'calling pouch db find', data: { options } });
    localInitCheck();
    return localPouchDb.find(options);
  };

  const put = async ({ id, rev, doc }) => {
    v({ msg: 'calling pouch db put', data: { doc } });
    localInitCheck();
    let docPut;
    try {
      if (!id) {
        e({
          msg:
            'id must be specified for put, call post if auto id generation is required.',
          throwError: true,
        });
      }
      docPut = { _id: id, _rev: rev, ...doc };

      const result = await localPouchDb.put(docPut);
      v({ msg: 'pouch db put returned', data: { result } });
      localEditFlag = true;
      localEditsCount += 1;
      return result;
    } catch (error) {
      e({ msg: 'pouch db put failed', error, data: { id, rev, doc, docPut } });
      throw error;
    }
  };

  const post = async ({ doc }) => {
    try {
      v({ msg: 'calling pouch db post', data: { doc } });
      localInitCheck();
      const result = await localPouchDb.post(doc);
      v({ msg: 'pouch db post returned', data: { result } });
      localEditFlag = true;
      localEditsCount += 1;
      return result;
    } catch (error) {
      e({ msg: 'pouch db post failed', error, data: { doc } });
      throw error;
    }
  };

  const remove = async ({ id, rev }) => {
    try {
      v({ msg: 'calling pouch db remove', data: { docId: id } });
      localInitCheck();
      const result = await localPouchDb.remove(id, rev);
      v({ msg: 'pouch db remove returned', data: { result } });
      localEditFlag = true;
      localEditsCount += 1;
      return result;
    } catch (error) {
      e({ msg: 'pouch db remove failed', error, data: { docId: id, rev } });
      throw error;
    }
  };

  const bulkDocs = async params => {
    // COMMENT: desturcturing docs in function signature did not work for some reason.

    const { docs } = params;
    v({
      msg: 'calling pouch db bulkDocs',
      data: { docs: docs && docs.length },
    });
    if (!docs || !docs.length) {
      throw new Error('No docs provided for bulkDocs');
    }
    try {
      localInitCheck();
      const result = await localPouchDb.bulkDocs(docs);
      v({ msg: 'pouch db bulk docs returned', data: { result } });
      localEditFlag = true;
      localEditsCount += docs.length;
      return result;
    } catch (error) {
      e({
        msg: 'pouch db docs failed',
        error,
        data: { docs: docs && docs.length },
      });
      throw error;
    }
  };

  // Returns a promise.
  const createIndex = options => localPouchDb.createIndex(options);

  return {
    replicate,
    cancelReplication,
    isReplicating,
    halt,
    info,
    post,
    put,
    get,
    remove,
    bulkDocs,
    all,
    find,
    createIndex,
  };
};

export default pouchDbApiInstance;

/**
 * A class that :
 * 1. Exposes couch authentication API via PouchDb lib, logIn / logOut /getSession.
 * 2. Enables offline authentication/authorization by storing user data (and required login data SALT etc TBD) in local pouchDB indexed db
 *
 * A login in-front of couch will store relevant authentication & authorization data in local pouchdb for sequensitive login/logout in offline mode.
 * @param {string} remoteServer url
 * @returns
 */
export const createPouchAuth = ({ remoteServer, adapter = 'idb' }) => {
  const { d, e, v } = logger({ source: 'pouch-auth' });

  const USERS_DB = 'wsuser'; // TODO: read from configuration.
  const remoteUrl = `${remoteServer}/${USERS_DB}`;
  const AUTH_CHANNEL = 'auth';
  const AUTH_EVENT_TYPE = 'auth';
  const POUCHDB_EVENT_SOURCE = 'pouchDb';

  let loggedInUsername;

  // Skip setup means we do not automatically create local indexeddb in-case we do not perform replication - which we will not for auth use-cases.
  const remoteAuthDb = new PouchDB(remoteUrl, {
    skip_setup: true,
    fetch: (url, opts) => {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken && opts?.headers) {
        opts.headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return PouchDB.fetch(url, opts);
    },
  });

  const localUserDb = new PouchDB('offlineusers', { adapter });

  const logIn = async ({ username, password }) => {
    try {
      d({ msg: 'login called' });
      const { ok, name, roles } = await remoteAuthDb.logIn(username, password);
      // TODO : need to try and login locally if not online.
      d({ msg: 'login result', data: { ok, name, roles } });
      if (ok) {
        await updateOfflineUserData({ username: name, roles });
        loggedInUsername = name;
        const userId = buildUserId({ username: loggedInUsername });
        // TODO : set place for channel names consts and data consts.
        // The auth event is for couchDb only, it is up for the app to determine it's applicative interpertation.
        dispatch({
          channel: AUTH_CHANNEL,
          source: POUCHDB_EVENT_SOURCE,
          type: AUTH_EVENT_TYPE,
          data: { loginState: 'loggedIn', name, roles, userId },
        });
      }
      return { ok, name, roles };
    } catch (error) {
      e({ msg: 'login error', error });
      throw error;
    }
  };

  const updateOfflineUserData = async ({ username, roles }) => {
    try {
      const _id = `org.couchdb.user:${username}`;
      const offlineUserDoc = {
        _id,
        username,
        ...roles,
      };

      const { _rev } = (await localUserData({ username })) || {
        _rev: undefined,
      };
      const existingUser = _rev;
      if (existingUser) {
        await localUserDb.put({ ...offlineUserDoc, _rev });
      } else {
        await localUserDb.post(offlineUserDoc);
      }
    } catch (error) {
      e({ msg: 'Failed updateOfflineUserData', error });
      throw error;
    }
  };

  /**
   * Retreives local user data, either for specified username or for loggedIn username if none is spceified.
   *
   * TODO : critical - to prevent tamperinc, offline login flag must use a cookie or otherway every person with some JS knowledge can update data in indexed db and login
   * @param {*} param0
   * @returns
   */
  const localUserData = ({ username } = { username: loggedInUsername }) => {
    if (!username) {
      const error = new Error(
        'localUserData - no username has been supplied and no user is logged in',
      );
      e({ error });
      throw error;
    }
    const id = buildUserId({ username });
    v({ msg: 'calling pouch db get', data: { id } });
    return localUserDb
      .get(id)
      .then(
        doc => {
          v({ msg: 'pouch db get doc found ', data: { doc } });
          return doc;
        },
        reason => {
          v({ msg: 'pouch db get doc not found ', data: { reason } });
          return undefined;
        },
      )
      .catch(reason => {
        throw reason;
      });
  };

  const buildUserId = ({ username }) => {
    const _id = `org.couchdb.user:${username}`;
    return _id;
  };

  const logOut = async () => {
    try {
      d({ msg: 'loutgout called' });
      const result = await remoteAuthDb.logOut();
      d({ msg: 'logout result', data: { result } });
      if (result) {
        const userId = buildUserId({ username: loggedInUsername });
        dispatch({
          channel: AUTH_CHANNEL,
          source: POUCHDB_EVENT_SOURCE,
          type: AUTH_EVENT_TYPE,
          data: { loginState: 'loggedOut', name: loggedInUsername, userId },
        });
        // TODO :must clear user from local db! Currently do not for backward compitability (need to recheck).
        // await localUserDb.remove({ _id: userId });
      }
      return result;
    } catch (error) {
      // In case the user is offline pouch logout would fail as it goes to the server, there will be a miss-sync between
      // the server and the local storage.
      // TODO: once we return online we must complete the log out or the cookie will remain.
      e({ msg: 'logout error', error });
      return false;
    }
  };

  const getSession = async () => {
    try {
      d({ msg: 'getSession called' });
      const result = await remoteAuthDb.getSession();
      d({ msg: 'getSession result', data: { result } });
      return result;
    } catch (error) {
      e({ msg: 'getSession error', error });
      throw error;
    }
  };

  d({
    msg: 'pouch auth inited',
    data: { logIn, logOut, getSession, localUserData },
  });
  return {
    logIn,
    logOut,
    getSession,
    localUserData,
  };
};

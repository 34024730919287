/* eslint-disable */
import { SET_ALL_DB_SYNCED, SET_DB_SYNCED } from './configuration/actions';

export default (
  previousState = { allDbsSynced: false, coreSynced: false, symtSynced: false },
  { type, payload } = {},
) => {
  if (type === SET_ALL_DB_SYNCED) {
    return { ...previousState, allDbsSynced };
  }
  if (type === SET_DB_SYNCED) {
    return { ...previousState, [`${db}Synced`]: synced };
  }
  return previousState;
};

import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  pouch: {
    syncPrimary: 'Sync In Progress',
    syncSecondary:
      'We are downloading data necessary to use WinPCS. Please wait as this may take awhile...',
  },
  checksheet: 'Checksheet',
  backTo: 'Back to',
  online: 'Online',
  offline: 'Offline',
  pos: {
    font: 'Font',
    fontNote: 'Drag the slider to make your text readable',
    aText: 'A',
    search: 'Search',
    configuration: 'Configuration',
    changelog: 'Change Log',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to WinPCS',
        subtitle: 'Please use the menu to explore the system!',
        aor_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
      hierarchy: 'Hierarchies',
      checksheet: 'Checksheets',
      checksheetAdmin: 'Checksheet Admin',
    },
    hierarchy: {
      list_style_name: 'Display',
      loadingPrimary: 'Configuring',
      loadingSecondary: 'Please wait while we setup hierarchies...',
      no_action_allowed: 'No Actions',
      displayChoices: {
        list: 'List',
        tile: 'Tile',
        tree: 'Tree',
      },
    },
    preservation: {
      list_style_name: 'Display',
      displayChoices: {
        due: 'Due',
        future: 'Future',
        all: 'All',
      },
      statusButton: 'Status',
      confirmStatus: 'Confirm %{status}?',
      confirming: 'Confirming %{status}',
      cancelStatus: 'Cancel',
      wrongStatus: 'Invalid status',
    },
    checksheet: {
      editableMode: 'Editable mode enabled',
      list_style_name: 'Display',
      displayChoices: {
        library: 'Library',
        custom: 'Custom',
        all: 'All',
      },
    },
    conChecksheetEquipment: {
      displayChoices: {
        checksheet: 'Checksheet',
        tagNo: 'Tag No',
      },
    },
    offline: {
      primary: "You're offline",
      secondary: 'Unable to display %{component} while offline.',
    },
    loading: 'Loading',
    pleaseWait: 'Please wait...',
  },
  resources: {
    form: {
      name: 'Form',
    },
    displayChecksheet: {
      name: 'Display Checksheet',
    },
    checksheet_question: {
      name: 'Questions',
    },
    checksheet_header: {
      name: 'Headers',
    },
    checksheet_footer: {
      name: 'Footers',
    },
    checksheet_form: {
      name: 'Forms',
    },
    checksheet: {
      name: 'Checksheets',
      fields: {
        element_type: 'Element Type',
        status: 'Status',
      },
      status: {
        complete: 'Completed',
        incomplete: 'Incomplete',
      },
      clearValue: '--Clear--',
    },
    checksheetConnection: {
      title: { createTitle: 'Connect Checksheet', listTitle: 'Checksheets' },
      tabs: {
        checksheetConnection: 'Checksheet Connection',
      },
      actions: {
        save: 'Save',
      },
      fields: {
        tag: 'Tag',
        checksheet: 'Checksheet',
      },
    },
    autopl: {
      name: 'Punch |||| Punches',
      fields: {
        discipline: 'Discipline',
        pl_type: 'Type',
        status: 'Status',
        pl_no: 'Punch Number',
        short_no: 'Short Number',
        raised_by: 'Raised By',
        created_on: 'Created',
        last_update_on: 'Last Update On',
        last_update_by: 'Last Update By',
        clear_by_group: 'Clear By Group',
        cert_table: 'Cert Table',
        cert_id: 'Tag',
        hc1_ref: 'System',
        ac1_ref: 'Subsystem',
        drawing: 'Drawing',
        area: 'Area',
        details: 'Details',
        corr_txt: 'Corrective Action',
        _attachments: 'Pictures',
        correctivePictures: 'Corrective Pictures',
        assigned_to: 'Assigned To',
        responsible_person: 'Responsible Person',
        action_group: 'Action Group',
        material_required: 'Material Required',
        show_more: 'show more',
        additional_fields: 'Additional Fields',
        latitude: 'Latitude',
        longitude: 'Longitude',
      },
      actions: {
        save_and_show: 'Save and Show',
      },
      tabs: {
        punch: 'Punch',
        pictures: 'Pictures',
        attachment: 'Others',
      },
      status: {
        complete: 'Completed',
        incomplete: 'Incomplete',
      },
      materialRequired: {
        yes: 'Y',
        no: 'N',
      },
      title: '%{pl_no} - Punch on %{cert_id} %{date}',
      pictures: {
        problem: 'Problem Pictures',
        completed: 'Completed Pictures',
        attachment: 'Attachment Links',
      },
      fabPunchTitle: 'Raise Punch Item',
      speedDialTitle: 'SpeedDial',
      geoLocationNotAvailable: 'GPS features not available',
      locationAccessNotAllowed:
        'Enable browser location services to use GPS features',
      close: 'close',
      offlineMapMessage: 'Cannot view on map while offline',
    },
    conpmr: {
      name: 'Preservation',
      fields: {
        tag_no: 'Tag',
        description: 'Description',
        area_week: 'Area Week',
        discipline_week: 'Discipline Week',
        discipline: 'Discipline',
        area: 'Area',
        status_week: 'Status Week',
        next_week: 'Next Week',
        period: 'Period',
        pmr: 'Activity',
        wstate: 'Status',
      },
      status: {
        stop: 'Stop',
        start: 'Start',
        'start-bookoff': 'Book Off',
        done: 'Done',
        doneAndStopped: 'Done and Stopped',
        fieldComplete: 'Field Complete',
      },
    },
    refpmr: {
      name: 'Preservation Activity |||| Preservation Activities',
      fields: {
        notes: 'Notes',
        discipline: 'Discipline',
        description: 'Description',
        activity: 'Activity',
      },
    },
    certsite: {
      name: 'Site |||| Sites',
    },
    certunit: {
      name: 'Unit |||| Units',
    },
    certsys: {
      name: 'System |||| Systems',
    },
    certac1: {
      name: 'Sub-System |||| Sub-Systems',
    },
    certarea: {
      name: 'Area |||| Areas',
    },
    certmc1: {
      name: 'Zone |||| Zones',
    },
    certmile: {
      name: 'Milestone |||| Milestones',
    },
    customers: {
      name: 'Customer |||| Customers',
      fields: {
        commands: 'Orders',
        groups: 'Segments',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent',
      },
      tabs: {
        identity: 'Identity',
        address: 'Address',
        orders: 'Orders',
        reviews: 'Reviews',
        stats: 'Stats',
      },
      page: {
        delete: 'Delete Customer',
      },
    },
    commands: {
      name: 'Order |||| Orders',
      title: 'Order %{reference}',
      fields: {
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          total: 'Total',
          unit_price: 'Unit Price',
        },
        customer_id: 'Customer',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        date: 'Invoice date',
        customer_id: 'Customer',
        command_id: 'Order',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        address: 'Address',
      },
    },
    products: {
      name: 'Poster |||| Posters',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    core: {
      name: 'Core |||| Core',
      tagSearch: 'Tag Search',
    },
    reviews: {
      name: 'Review |||| Reviews',
      detail: 'Review detail',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },
    segments: {
      name: 'Segments',
      fields: {
        customers: 'Customers',
        name: 'Name',
      },
      data: {
        compulsive: 'Compulsive',
        collector: 'Collector',
        ordered_once: 'Ordered once',
        regular: 'Regular',
        returns: 'Returns',
        reviewer: 'Reviewer',
      },
    },
    actionMessage: {
      cancelAgreement: 'Are you sure you want to Cancel?',
      confirmAgreement: 'I accept above terms & conditions.',
      apply: 'Apply',
      empty: 'File name cannot be empty.',
      validateFormName: 'Please enter the form name.',
      validateFormField:
        'You can not select more than one form field in question.',
      validateChecksheetName: 'Please enter the checksheet name.',
      validateNoData: 'Please select data before save.',
      validateDuplicateData:
        'The checksheet and tags you selected have already been there in the list.',
      success: 'Successfully saved',
      logoutError: 'Logging out timed out.',
      deleteSuccessMessage: 'Deleted successfully.',
      errorMessage: 'Error: %{message}',
      confirm: 'Confirm',
      cancel: 'Cancel',
      updateText: 'Update',
      delete: 'Delete',
      defaultTitle:
        'Are you sure you want to delete %{count} punch item %{item}? |||| Are you sure you want to delete %{count} punch items?',
      modelDefaultTitle: 'Are you sure?',
      showOnMap: 'Show on Map',
      captureLocation: 'Capture Location',
      captureAgain: 'Re-capture',
      savedDialog:
        'Changes you made will not be saved. Do you want to leave this page?',
      completeDialog: 'Are you sure you want to complete the checksheet?',
      fileUploadValidation:
        'Please fill all required fields before upload/cancel',
      checksheetFormValidation:
        'The form is not valid. Please check for errors.',
    },
    signup: {
      back: 'Back',
      next: 'Next',
      submit: 'Submit',
      signupText: 'Signup',
      userId: 'User ID',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      fname: 'First Name',
      lname: 'Last Name',
      companyName: 'Company Name',
      mobile: 'Contact Number',
      acceptTermCondLabel: 'I accept terms and conditions',
      acceptTermCond: 'Please accept the terms and conditions',
      basicDetail: 'Basic Registration Details',
      personalDetail: 'Personal Details',
      preview: 'Preview',
      returnLoginPage: 'Return to login page',
      alert: 'Alert',
      termTitle: 'Terms & Conditions',
      emailVerified: 'Email verified successfully',
      complanLogo: 'Complan Logo',
      registrationSuccessful: 'Registered successfully',
      passwordMatch: 'matched',
      usernameTerms: 'Space & Uppercase will not be considered',
      passwordLength: 'Password length can not exceed 18 characters.',

      error: {
        userAlreadyExist: 'Username taken',
        emailAlreadyExist: 'Email already exist',
        termsConditions: 'Please accept terms and conditions',
        required: 'Required',
        invalidNumber: 'Invalid phone number',
        emailInvalid: 'Email is invalid',
        alert: 'Alert',
        registrationUnsuccessful: 'Registration Unsuccessful',
        somethingWentWrong:
          'Something went wrong, please return the login page or contact an administrator for help.',
        somethingWentWrongText:
          'Something went wront, Please contact an administrator for help.',
        linkExpired: 'Your link is expired',
        offlineRegistration: 'You cannot register while offline.',
        mobileError:
          'Mobile number must have minimum 4 digit and maximum 12 digit',
        passwordTermCondition:
          'Your password is weak. It should have a minimum of 8 digits and a combination of the letters and numbers.',
        passwordConfirmNotMatch: 'Password and confirmation must match.',
        tokenIsInvalid: 'Your reset password link is expired.',
      },
      resetPassword: 'Reset Password',
      forgotPassword: 'Forgot password?',
      search: 'Email/Username',
      confirmPasssword: 'Confirm Password',
      changePassword: 'Change Password',
      newPasswordText: 'Please enter your new password.',
      passwordChangedSuccessfully: 'Password changed successfully.',
      resetEmailLink:
        'A password reset link is sent to your email successfully. Please check your email.',
      userIdEmailBlank: "Email/Username can't be blank.",
    },
    forgotPassword: {
      resetPassword: 'Reset Password',
      changePassword: 'Change Password',
      resetYourPassword: 'Reset your password.',
      passwordChanged: 'Password Changed.',
      errorEmailNotRegistered:
        'Email/Username entered below is not registered with us.Please contact the administrator.',
      errorEmailUserRequired: 'Please provide your email or userId.',
      errorUserRequired: 'User not found.',
      errorTokenRequired: 'Please provide your token.',
      errorPasswordRequired: 'Please provide your password.',
      invalidUrl: 'Invalid Url.',
      invalidToken: 'Token not found.',
      emailEmpty: 'Email address can not be empty.',
      emailInvalid: 'Invalid email address.',
      passcodeInvalid: 'Invalid passcode entered.',
      submitPasscode: 'Submit Passcode',
      sendEmail: 'Send Verification Email',
      returnToLoginPage: 'Return To Login Page',
      enterPasscode: 'Validate Passcode',
      enterPasscodeHelp: 'Enter the passcode sent to your email.',
      returnLoginPage: 'Return to login page',
      resetPasswordHelp:
        'Enter your registered email to reset your password.\n If found, a reset passcode will be sent.',
    },
    passwordChanged: {
      passwordChangedSuccess:
        'You have successfully changed the password for your account.',
      contactAdmin:
        'If you did not initiate this change, please contact to administrator immediately.',
      cheer: 'Cheers,',
      organization: 'Complan Team',
      missingUsername: 'Missing Username.',
      missingEmail: 'Missing Email.',
    },
    locationChangedMessage:
      'Your location has changed. Do you want to capture the location?',
    noofperpage: 'Rows per page',
    prev: 'Prev',
    next: 'Next',
    noResult: 'No results found',
  },
};

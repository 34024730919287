/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from 'react';
import { Loading } from 'react-admin';
import { Provider } from 'react-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import { PersistGate } from 'redux-persist/integration/react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import CouchDbReplicaProvider from './CouchDbReplicaProvider';
import ComplanReactAdmin from './ComplanReactAdmin';
import './App.css';
import authProvider from './authProvider';
import { useShortid } from './hooks';
import { isIOS, isCordova } from './detectDevice';
import createAdminStore from './createAdminStore';
const history = createBrowserHistory();

if (process.env.CONSOLE_DEBUG_ENABLE) {
  if (window.setConsoleDebugOn) {
    window.setConsoleDebugOn();
  }
}

const App = () => {
  useShortid();

  const { store, persistor } = createAdminStore({
    authProvider,
    history,
  });

  const syncAllDatabases = isCordova || !isIOS;
  // const syncAllDatabases = false;

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <CouchDbReplicaProvider syncAllDatabases={syncAllDatabases}>
          <ComplanReactAdmin history={history} />
        </CouchDbReplicaProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

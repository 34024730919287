import React, { useEffect } from 'react';
import {
  Layout,
  Sidebar,
  Loading,
  useRedirect,
  useAuthState,
} from 'react-admin';
import lodashGet from 'lodash/get';
import { useSelector } from 'react-redux';

import { appMakeStyles } from './AppThemeManager';
import AppBar from './AppBar';
import Menu from './Menu';

const DRAWER_WIDTH = 240;
const CLOSED_DRAWER_WIDTH = 55;

const useStyles = appMakeStyles(
  theme => ({
    drawerPaper: {
      position: 'sticky',
      [theme.breakpoints.up('sm')]: {
        top: '4em',
      },
      zIndex: 100,
      height: 'auto',
      overflowX: 'hidden',
      width: props =>
        props.open
          ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
          : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'transparent',
      marginTop: '0.5em',
      borderRight: 'none',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
        height: '100vh',
        position: 'inherit',
        backgroundColor: theme.palette.background.main,
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
        marginTop: '1.5em',
      },
      '& a:hover': {
        color: theme.palette.secondary.main,
      },
    },
  }),
  { name: 'RaSidebar' },
);

const CustomSidebar = props => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  useSelector(state => state.locale); // force redraw on locale change
  const classes = useStyles({ ...props, open });

  return (
    <Sidebar
      {...props}
      size={200}
      PaperProps={{
        className: classes.drawerPaper,
      }}
    />
  );
};

export default props => {
  const { loading: checkingAuth, authenticated: isAuthed } = useAuthState();
  // const { isAuthed, checkingAuth } = useAuth();
  const redirectTo = useRedirect();

  /**
   * TODO: would move check auth to a seperate Auth HOC and make sutre it is integrated with all hooks.
   */
  useEffect(
    () => {
      if (checkingAuth) {
        return;
      }

      if (!isAuthed) {
        redirectTo('/login');
      }
    },
    [redirectTo, isAuthed, checkingAuth],
  );

  if (checkingAuth || !isAuthed) {
    return (
      <Loading loadingPrimary="pos.loading" loadingSecondary="pos.pleaseWait" />
    );
  }

  return (
    <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} />
  );
};

import { ThemeProvider } from '@material-ui/core';
import React, { useContext } from 'react';

import AppSettingsContext from './AppSettingsContext';

export const WithAppSettingsThemeProvider = ({
  Component,
  theme = undefined,
  defaultTheme = false,
}) => props => {
  const { theme: contextTheme, defaultTheme: contextDefaultTheme } = useContext(
    AppSettingsContext,
  );
  const propsTheme = defaultTheme ? contextDefaultTheme : theme || contextTheme;
  const updatedProps = { ...props, theme: propsTheme };
  return (
    <ThemeProvider theme={propsTheme}>
      <Component {...updatedProps} />
    </ThemeProvider>
  );
};

/* eslint-disable no-shadow */
import get from 'lodash/get';

import {
  isHierarchyRootId,
  createHierPublishDoc,
  decomposeWinpcsHierarchyId,
  logPublishFutures,
  processRconnRecord,
} from '../hierarchy/hierarchyFunctions';
import { logger } from '../../../utils/logger';
import { isLowerCase } from '../../../../utils/isLowerCase';

const { v, e, w } = logger({ source: 'couch-dataprovider' });

/**
 * Extracts and deduplicates field names from a CouchDB selector object, including those nested within
 * Mango query operators ($and, $or, $not, $nor, $all, $elemMatch, $allMatch). This function recursively
 * navigates through the selector object to find all field names used in the query, supporting complex
 * queries involving logical operators. The resulting field names are intended for use in creating indexes
 * to optimize query performance.
 *
 * @param {Object} selector - The CouchDB selector object from which field names are to be extracted. The
 *                            selector defines query conditions and can include nested structures with
 *                            logical operators.
 * @returns {string[]} An array of deduplicated field names extracted from the selector. These field names
 *                     can be directly used for creating a CouchDB index.
 *
 * @example
 * // Example with $and operator
 * const andSelector = {
 *   "$and": [
 *     { "age": { "$gt": 30 } },
 *     { "city": "New York" }
 *   ]
 * };
 * // Returns: ["age", "city"]
 *
 * @example
 * // Example with $or operator
 * const orSelector = {
 *   "$or": [
 *     { "status": "Single" },
 *     { "status": "Married" }
 *   ]
 * };
 * // Returns: ["status"]
 *
 * @example
 * // Example with $not operator
 * const notSelector = {
 *   "age": { "$not": { "$lt": 18 } }
 * };
 * // Returns: ["age"]
 *
 * @example
 * // Example with $nor operator
 * const norSelector = {
 *   "$nor": [
 *     { "state": "Completed" },
 *     { "priority": "Low" }
 *   ]
 * };
 * // Returns: ["state", "priority"]
 *
 * @example
 * // Example with $all operator (Note: $all is typically used for arrays)
 * const allSelector = {
 *   "tags": { "$all": ["urgent", "office"] }
 * };
 * // Returns: ["tags"]
 *
 * @example
 * // Example with $elemMatch operator
 * const elemMatchSelector = {
 *   "projects": {
 *     "$elemMatch": { "status": "Active", "budget": { "$gt": 1000 } }
 *   }
 * };
 * // Returns: ["projects.status", "projects.budget"]
 *
 * @example
 * // Example with $allMatch operator (Similar to $elemMatch, but for all elements)
 * const allMatchSelector = {
 *   "tasks": {
 *     "$allMatch": { "completed": true }
 *   }
 * };
 * // Returns: ["tasks.completed"]
 */
function extractFieldsFromSelector(selector) {
  const fields = new Set();

  function extractFields(obj) {
    Object.keys(obj).forEach(key => {
      if (
        [
          '$and',
          '$or',
          '$not',
          '$nor',
          '$all',
          '$elemMatch',
          '$allMatch',
        ].includes(key)
      ) {
        // For operators, recursively extract field names from their value(s)
        if (Array.isArray(obj[key])) {
          obj[key].forEach(subSelector => extractFields(subSelector));
        } else {
          extractFields(obj[key]);
        }
      } else {
        // Add the field name if it's not an operator
        fields.add(key);
      }
    });
  }

  extractFields(selector);

  return [...fields];
}

// TODO: Backward compatibility purposes only, maintaining existing const, should be refactored in the future.
const DATABASES = {};

const getDatabase = dbName => DATABASES[dbName];

/* eslint-disable no-console */
function buildFilterSelector(filter) {
  // Object must be returned as a pure key value pair
  return JSON.parse(
    JSON.stringify(
      Object.keys(filter).reduce((acc, key) => {
        if (key === 'ids') {
          return { ...acc, _id: { $in: filter[key] } };
        }

        if (typeof filter[key] === 'object') {
          const innerFilter = Object.keys(filter[key]).reduce(
            (filterAcc, k) => ({
              ...filterAcc,
              [`${key}.${k}`]: { $in: filter[key][k] },
            }),
            {},
          );
          return { ...acc, ...innerFilter };
        }

        const parts = key.split('.');

        if (parts.length > 1) {
          if (parts[1] === 'id') {
            return { ...acc, [parts[0]]: { _id: filter[key] } };
          }

          return { ...acc, [key]: filter[key] };
        }

        return { ...acc, [key]: filter[key] };
      }, {}),
    ),
  );
}

async function transformFindResult(db, { warning, docs }, findParams = {}) {
  if (warning) {
    // eslint-disable-next-line no-console
    console.warn(warning);
  }
  // const allDocs = await db.allDocs();
  // const limit = findParams.limit || docs.length;
  // const skip = findParams.skip || 0;
  // let data = docs;
  // if (skip < docs.length) {
  //   const sl = skip + limit;
  //   const endIndex = sl < docs.length ? sl : docs.length - 1;
  //   data = docs.slice(skip, endIndex);
  // }
  const info = await db.info();
  let total = get(info, 'local.doc_count', 0);

  // let total = 0;//.then(({ doc_count }) => doc_count);

  // There's a limit on size and we're on the first page
  if (findParams.limit && !findParams.skip && docs.length < findParams.limit) {
    total = docs.length;
  }

  return {
    data: docs.map(({ _id: id, ...restDoc }) => ({ ...restDoc, id })),
    // TODO: How can we get a count of all docs length????? Not available from find right now.
    // total: allDocs.rows.filter(({ id }) => !id.startsWith('_design')).length,
    total,
    // total: await db.info().then(({ doc_count }) => doc_count),
  };
}

function transformIndividualResult(doc) {
  const { _id } = doc;
  const transformedDoc = { id: _id, ...doc };
  delete transformedDoc._id;
  const retval = { data: { doc: transformedDoc } };
  return retval;
}

function runFindQuery(allDbs, resource, findParams) {
  console.debug(
    `runFindQuery: resource: ${resource} findParams: `,
    findParams,
    ' allDbs: ',
    allDbs,
  );
  return allDbs[resource]?.db // .find(removeLimitAndSkipFromFindOpts(findParams))
    .find(findParams) // .then(async result => { //   const keys = result.docs.map(({ _id }) => _id); //   const records = await allDbs[resource].db //     .allDocs({ //       keys, //       attachments: true, //       include_docs: true, //     }) //     .then(({ rows }) => { //       const docs = rows.reduce((acc, { doc }, idx) => { //         acc.push({ ...result.docs[idx], ...doc }); //         return acc; //       }, []); //       return { //         docs, //         warning: result.warning, //       }; //     }); //   return transformFindResult(allDbs[resource].db, records, findParams); // })
    .then(async result => {
      const retval = await transformFindResult(
        allDbs[resource].db,
        result,
        findParams,
      );
      v({
        msg: 'transform find results',
        identifier: 'resource',
        data: { retval },
      });
      return retval;
    })
    .catch(err => {
      if (
        err.message.match(
          /Cannot sort on field\(s\) .+ when using the default index/,
        ) ||
        err.message.match(/There is no index available for this selector\./)
      ) {
        let fields = [];

        // if (field) {
        //   fields.push(field);
        // }

        Object.keys(findParams.selector).forEach(key => {
          fields.push(key);
        });

        // Remove duplicate fields
        fields = [...new Set(fields)];

        const name = `${fields.join('-')}index`;

        // console.debug(
        //   `Creating index ${name} on ${resource} with fields: `,
        //   fields,
        // );

        // Create the missing index
        return allDbs[resource].db
          .createIndex({
            index: { fields, name, type: 'json' },
          })
          .then(({ id }) =>
            allDbs[resource].db
              .find({
                ...findParams,
                use_index: id,
              })
              .then(result =>
                transformFindResult(allDbs[resource].db, result, findParams),
              ),
          )
          .catch(e => {
            if (
              e.message.match(/There is no index available for this selector\./)
            ) {
              return allDbs[resource].db
                .find({
                  ...findParams,
                })
                .then(result =>
                  transformFindResult(allDbs[resource].db, result, findParams),
                )
                .catch(suberr => {
                  console.error(suberr);
                  return {
                    data: [],
                    total: 0,
                  };
                });
            }
            console.error(e);
            return {
              data: [],
              total: 0,
            };
          });
      }

      throw err;
    });
}

export const getListHierarchy = async params => {
  console.debug('getListHierarchy: ', params);

  // In case of no filter - return empty dataset.
  if (!params.filter) {
    // TODO: Return hiertop
    return { data: [], total: 0 };
  }

  // Filtering for 'children' with a parent hierarchy, the filter key would start with __hierParent__ ,
  // should have only one existingbat any case would return only the first one that meets that condition.
  const parentFilterKeyParam = Object.keys(params.filter).find(k =>
    k.startsWith('__hierParent__'),
  );

  // TODO : In case no filter is present - publishHierTop is all commented function....
  if (!parentFilterKeyParam) {
    // eslint-disable-next-line no-undef
    return publishHiertop();
  }
  console.debug(
    `getListHierarchy: parentKeyParam: ${parentFilterKeyParam} --- parentKeyValue: ${
      params.filter[parentFilterKeyParam]
    }`,
  );

  const parentFilter = params.filter[parentFilterKeyParam];

  console.debug(
    `>>>>> getListHierarchy: running publish with parent: ${parentFilter}`,
  );

  // Checks if the filter is the root, in-case so it means that the 'children' are the top level hierarchies.
  const isRoot = isHierarchyRootId(parentFilter);

  if (isRoot) {
    console.debug('|||| getListHierarchy: Running root publish');
    isLowerCase(parentFilter, false, 'getListHierarchy: parentFilter');
    const table = parentFilter.toLowerCase();
    const hiertop = parentFilter.toLowerCase();

    // TODO: support limit, sorting, etc...
    // A basic extra query params selector place holder, currently will return all records as id > null includes everything.
    const findParams = { selector: { _id: { $gt: null } } };

    return runFindQuery(DATABASES, table, findParams).then(
      async ({ data, total }) => {
        console.debug(
          'getListHierarchy: root: return from firstFindQuery: ',
          data,
          total,
        );
        // Get cxdict data and merge it into the records
        // Before returning the data to the client, we first transform the raw data to 'publishedData' format
        // and use metadata found in the table cxdict to do so.
        // which defines how the doc fields should be named and transforming raw data to cxdict data.
        const cxdictFindParams = {
          selector: {
            itembase: table,
          },
          limit: 1,
        };

        const { data: cxDictData } = await runFindQuery(
          DATABASES,
          'cxdict',
          cxdictFindParams,
        );
        const cxDictResult =
          (cxDictData && cxDictData.length && cxDictData[0]) || {};
        console.debug('getListHierarchy: root: cxdictFind: ', cxDictResult);

        const cxDictMappedData = data.map(doc => {
          const docPublishFormat = createHierPublishDoc(null, doc, hiertop, {
            itemfld: cxDictResult.itemfld,
            itemdesflds: cxDictResult.itemdesflds || '',
          });
          return docPublishFormat;
        });
        const retval = { data: cxDictMappedData, total };
        return retval;
      },
    );
  }

  const {
    currentTableName: table0,
    rserial: table0Rserial,
    filters,
    parentRecords = [],
  } = await decomposeWinpcsHierarchyId(parentFilter, false, getDatabase);
  console.debug(
    `getListHierarchy: ---- Running child publish: table0: ${table0} - table0Rserial: ${table0Rserial} - parent: ${parentFilter}`,
  );

  // const tempRconnTimerId = Random.id();
  // console.time(`rconn${tempRconnTimerId}`);
  // const baseRconnSelector = {
  //   relbase: table0.toUpperCase(),
  // };

  isLowerCase(table0, false, 'getListHierarchy: table0');

  const baseRconnSelector = table0
    ? {
        relbase: table0.toLowerCase(),
      }
    : {};
  // const baseRconnQuery = PG
  //   .knex('rconn')
  //   .select('rserial as id', '*')
  //   .where('relbase', '=', table0.toUpperCase());
  // const rconnRecords = filters.reduce((chain, f) => f(chain), baseRconnQuery)
  //   // .whereNotIn('itembase', itembaseXFilterArray)
  //   .fetch();

  let finalRconnSelector;

  if (filters) {
    finalRconnSelector = filters.reduce(
      (chain, f) => f(chain),
      baseRconnSelector,
    );
  } else {
    finalRconnSelector = baseRconnSelector;
  }

  console.debug('getListHierarchy: finalRconnSelector: ', finalRconnSelector);
  // TODO: support limit, sorting, etc...
  const { db: rconnDb } = getDatabase('rconn');
  if (!rconnDb) {
    e({ msg: 'rconndb not found', throwError: true });
  }
  const { data: rconnRecords = [] } = await runFindQuery(DATABASES, 'rconn', {
    selector: finalRconnSelector,
  });

  if (finalRconnSelector && Object.keys(finalRconnSelector).length) {
    const rconnIndexFields = extractFieldsFromSelector(finalRconnSelector);
    console.debug('trying to create rconn index on fields: ', rconnIndexFields);
    rconnDb.createIndex({ index: { fields: rconnIndexFields } });
  }
  console.debug('getListHierarchy: rconnRecords: ', rconnRecords);
  try {
    const rconnResults = await Promise.all(
      rconnRecords.map(async rconnRecord => ({
        rconnRecord,
        itembaseRecords: await processRconnRecord(
          parentFilter,
          table0,
          table0Rserial,
          rconnRecord,
          parentRecords,
          {
            boundRunFindQuery: runFindQuery.bind(this, DATABASES),
            boundRunGetMany: runGetMany.bind(this),
          },
          getDatabase,
        ),
      })),
    ).catch(reason => {
      console.error(
        'getListHierarchy will have UNDEFINED rconnResults due to caught error: ',
        reason,
      );
    });
    console.debug('getListHierarchy: rconnResults: ', rconnResults);
    const seenConbaseItembaseCombo = new Set();
    const { skipped: rconnSkipped, toPublish } = (rconnResults || []).reduce(
      (acc, r) => {
        const { conbase } = r.rconnRecord;
        if (
          seenConbaseItembaseCombo.has(`${r.rconnRecord.itembase}+${conbase}`)
        ) {
          console.error(
            'DUPLICATE RCONN CONBASE DETECTED FOR selector ',
            finalRconnSelector,
            ' with conbase+itembase: ',
            `${conbase}+${r.rconnRecord.itembase}`,
          );
          return acc;
        }

        if (
          logPublishFutures(
            r.itembaseRecords || [],
            `getListHierarchy: itembase records for conbase+itembase: ${conbase}+${
              r.rconnRecord.itembase
            }`,
            true,
          )
        ) {
          return {
            ...acc,
            skipped: [...acc.skipped, `${r.rconnRecord.itembase}+${conbase}`],
          };
        }
        console.debug(
          `Seen conbase+itembase: ${conbase}+${r.rconnRecord.itembase} ${
            r.rconnRecord.id
          }: `,
          r.itembaseRecords,
        );
        seenConbaseItembaseCombo.add(
          `${r.rconnRecord.itembase}+${conbase}`,
          true,
        );
        return { ...acc, toPublish: [...acc.toPublish, ...r.itembaseRecords] };
      },
      { skipped: [], toPublish: [] },
    );
    console.debug(
      `getListHierarchy: ${
        rconnSkipped.length
      } rconn records had no children on ${table0}: ${rconnSkipped.join(
        ' --- ',
      )}`,
    );
    console.debug('getListHierarchy: recordsToPublish: ', toPublish);
    return { data: toPublish, total: toPublish.length };
  } catch (err) {
    console.error(
      'getListHierarchy: Ignoring caught errors on getListHierarchy: ',
      err,
    );
  } finally {
    console.debug('getListHierarchy: Finished CHILD publishing');
    // publish.removed(Publications.publicationNames.HIERARCHY, 'loading');
  }

  // TODO: implement child querying
  return { data: [], total: 0 };
};

const getLimitAndSkipFromParams = ({ perPage, page }) => {
  const ret = {};

  if (perPage) {
    ret.limit = parseInt(perPage, 10);

    if (page > 1) {
      ret.skip = (parseInt(page, 10) - 1) * parseInt(perPage, 10);
    }
  }

  return ret;
};

const getSortOrIdSelectorFromParams = ({ fieldToUse, order, target }) => {
  const ret = { selector: {} };

  if (
    fieldToUse &&
    fieldToUse !== '_id' &&
    // Handle special fields like __display_type__ & __search_field__
    !fieldToUse.startsWith('__') &&
    !fieldToUse.endsWith('__')
  ) {
    // console.debug(`Adding fieldToUse to selector: ${fieldToUse}`);s
    ret.sort = [{ [fieldToUse]: order.toLowerCase() }];

    ret.selector[fieldToUse] = { $gte: null };

    // if (!findParams.selector[fieldToUse]) {
    //   ret.selector[fieldToUse] = { $gt: null };
    // }
  } else if (!target) {
    ret.selector._id = { $gt: null };
  }

  return ret;
};

/** *
 * TODO : REFACTORED TO EASY DEBUG STATE - MUST RETURN TO SIMPLE ONE LINER PROMISE.THEN STATE.
 */
const runGetOne = async (resource, params) => {
  // console.debug(`getOne: resource: ${resource} --- params: `, params);
  const repository = DATABASES && DATABASES[resource] && DATABASES[resource].db;
  if (!repository) {
    return undefined; // TODO : validate what empty value can be returned to react-admin without causing an exception.
  }
  const id = params?.id || params?.filter?.id; // HOTFIX - there are places where filter is passed not within filter object - will update IMMEDIATELY.
  const doc = await repository.get({ id, options: { attachments: true } });
  const retval = doc ? transformIndividualResult(doc) : {};
  return retval;
};

const runGetMany = (resource, params) => {
  v({ msg: 'runGetMany called', data: { resource, params } });
  return DATABASES[resource].db
    .allDocs({
      include_docs: true,
      keys: params.ids,
      // TODO: Can we pass params to not always fetch attachments?
      attachments: true,
    })
    .then(({ rows }) => {
      console.debug(
        'getMany: allDocs response: rows: ',
        rows,
        ' ids: ',
        params.ids,
      );
      return {
        data: rows
          .filter(({ id }) => {
            if (!id) {
              console.error(
                `id is missing for table ${resource}, this likely indicates a permission problem on the table`,
              );

              return false;
            }

            return !id.includes('_design');
          })
          .map(({ doc }) => transformIndividualResult(doc).data),
      };
    });
};

const createDataProvider = () => {
  const setDataSource = contextRepositories => {
    if (contextRepositories) {
      contextRepositories.forEach((repository, localDbName) => {
        const db = { db: repository };
        DATABASES[localDbName] = db;
      });
    }
  };

  const getDataSource = () => DATABASES;

  const getListParseParams = params => {
    const { page, perPage } = params?.pagination || {};
    const { field, order } = params?.sort || {};
    const fieldToUse = field === 'id' ? '_id' : field;
    let searchValue;
    let searchField;
    let searchType;
    let searchKeyParam;
    let rangeFilterField = null;
    if (params.filter) {
      if (params.filter.__range_search__) {
        rangeFilterField = params.filter.__range_search__;
      }
      searchKeyParam = Object.keys(params.filter).find(k =>
        k.startsWith('__search__'),
      );
      searchType = params.filter.__search_type__ || 'search';
      // console.debug(
      //   `searchKeyParam: ${searchKeyParam} --- searchType: ${searchType} --- searchValue: ${
      //     params.filter[searchKeyParam]
      //   }`,
      // );

      if (searchKeyParam && params.filter[searchKeyParam]) {
        searchValue = params.filter[searchKeyParam];
        searchField = searchKeyParam.replace('__search__', '');
      }
    }

    return {
      page,
      perPage,
      field,
      order,
      fieldToUse,
      searchField,
      searchValue,
      searchType,
      searchKeyParam,
      rangeFilterField,
    };
  };

  // TODO: in next versinos to create a wrapper HierarchyDataProvider(couchDbProvider)
  // TODO: currently specific for HierRoot, thus works only by relbase, relation also by itembase can be added (relbase OR itembase)
  const getHierarchyRelatedDbs = async ({ relbase }) => {
    if (!relbase) {
      return [];
    }
    const { db: rconnDb } = getDatabase('rconn');
    if (!rconnDb) {
      e({ msg: 'rconndb not found', throwError: true });
    }
    const relbaseLowercase = relbase.toLowerCase();
    const relbaseUppercase = relbase.toUpperCase();
    const { data: rconnRecords = [] } = await runFindQuery(DATABASES, 'rconn', {
      selector: {
        $or: [{ relbase: relbaseLowercase }, { relbase: relbaseUppercase }],
      },
    });
    const conbases = [];
    const itembases = [];
    const relatedDbs = [];
    // conbases and relbases are used for debugging purposes only.
    if (rconnRecords && rconnRecords.length > 0) {
      rconnRecords.forEach(obj => {
        if (obj.conbase) {
          conbases.push(obj.conbase);
          relatedDbs.push(obj.conbase);
        }
        if (obj.itembase) {
          itembases.push(obj.itembase);
          relatedDbs.push(obj.itembase);
        }
      });
    }
    const retval = [...new Set(relatedDbs)];
    console.log(`getHierarchyRelatedDbs rconn records : ${retval}`);
    return { data: retval };
  };

  const getListCouchDb = async (resource, params = {}) => {
    v({
      msg: 'getListCouchDb called',
      identifier: resource,
      data: { resource, params },
    });
    // console.debug('getListCouchDb: resource: ', resource, ' params: ', params);
    if (resource === 'hier') {
      // console.debug('getListCouchDb: getListHierarchy');
      return getListHierarchy(params);
    }
    const {
      page,
      perPage,
      // field,
      order,
      fieldToUse,
      searchField,
      searchValue,
      searchType,
      searchKeyParam,
      rangeFilterField,
    } = getListParseParams(params);

    let searchPromise;
    const findParams = {
      ...getSortOrIdSelectorFromParams({ fieldToUse, order }),
      ...getLimitAndSkipFromParams({ perPage, page }),
    };

    if (params.filter) {
      if (searchField && searchValue) {
        if (searchType === 'search' || searchType === 'prefix') {
          if (searchType === 'prefix') {
            findParams.selector[searchField] = {
              $gte: searchValue,
              $lte: `${searchValue}\uffff0`,
              // $gt: searchValue,
              // $lt: `${searchValue}\uffff`,
            };
          } else if (searchType === 'search') {
            findParams.selector[searchField] = {
              // $regex: `(?:.*)${searchValue}(?:.*)`,
              $regex: RegExp(`(?:.*)${searchValue}(?:.*)`, 'i'),
              $gt: null,
            };
          }
          if (rangeFilterField) {
            findParams.selector = {
              ...findParams.selector,
              ...rangeFilterField,
            };
          }

          // Don't yet explicitly support indexes with sort
          if (!findParams.sort) {
            // console.debug('Indexes: ', foundIndexes);
            let foundIndexes = await DATABASES[resource].db.getIndexes();
            let index_to_use = foundIndexes.indexes.find(
              ({ name }) => name === searchField,
            );
            if (index_to_use) {
              console.debug(
                `Found index to use for field ${searchField} : ${index_to_use}`,
              );
              findParams.use_index = index_to_use.ddoc;
            } else {
              // console.debug(
              //   `Did not find existing index to use, creating one for ${searchField}`,
              // );
              await DATABASES[resource].db.createIndex({
                index: {
                  fields: [searchField],
                  name: searchField,
                },
              });
              foundIndexes = await DATABASES[resource].db.getIndexes();
              index_to_use = foundIndexes.indexes.find(
                ({ name }) => name === searchField,
              );
              if (index_to_use) {
                findParams.use_index = index_to_use.ddoc;
              } else {
                console.warn(
                  `Could not find index even after trying to create for field: ${searchField}`,
                );
              }
            }
          }
          // console.debug(findParams);
          delete findParams.selector._id;
        } else if (searchType === 'prefix') {
          // console.debug('Use a prefix query');
          const queryId = `${resource}_${searchField}`;
          try {
            await DATABASES[resource].db.get(`_design/${queryId}`);
          } catch (e) {
            // console.debug('Thrown error from getting queryIndex');
            // console.debug(e);
            if (e.message === 'missing') {
              const ddoc = {
                _id: `_design/${queryId}`,
                views: {
                  [`by_${searchField}`]: {
                    map: `
                        function searchFieldMapFunction(doc) {
                          emit(
                            doc.${searchField}
                              ? doc.${searchField}.toLowerCase()
                              : doc.${searchField},
                          );
                        }
                      `,
                  },
                },
              };
              try {
                await DATABASES[resource].db.put(ddoc, { force: true });
              } catch (e2) {
                // console.debug('Error trying to put new design doc');
                // console.debug(e2);
                if (e2.status !== 409) {
                  throw e2;
                }
                // console.debug('Error creating index');
                // console.debug(e2);
                // throw e2;
              }
            } else {
              throw e;
            }
          }

          const searchValueLower = searchValue.toLowerCase();
          searchPromise = DATABASES[resource].db
            .query(`${queryId}/by_${searchField}`, {
              startKey: searchValueLower,
              endKey: `${searchValueLower}\uffff`,
            })
            .then(res => {
              console.log(res);
              return res;
            });
        }
        // const searchParams = findParams;
        // delete searchParams.selector;
        // searchParams.include_docs = true;
        // searchParams.query = searchValue;
        // searchParams.fields = [searchField];
        // searchParams.tokenizer = tokenizer;
        // searchPromise = DATABASES[resource].db
        //   .search(searchParams)
        //   .then(mapSearchResult)
        //   .then(result => transformSearchData(params, searchKey, result));
      } else {
        // console.debug(
        //   `Was missing searchField ${searchField} or searchValue ${searchValue}, so need to remove it: `,
        //   filter,
        // );
        const { filter } = params;

        if (searchKeyParam) {
          // Search key value was empty, so remove it
          delete filter[searchKeyParam];
        }

        if (filter.__range_search__) {
          // Expects a filter object with individual query keys
          // {
          //   __range_search__: {
          //     fieldOne: {
          //       $gt: 23
          //     },
          //     fieldTwo: {
          //       $lt: 54
          //     },
          //   }
          // }
          findParams.selector = {
            ...findParams.selector,
            ...filter.__range_search__,
          };

          // console.debug('Final params: ', findParams);

          delete findParams.selector._id;
        }

        if (filter) {
          // Remove special keys from the filter
          Object.keys(filter).forEach(
            key =>
              key.startsWith('__') && key.endsWith('__') && delete filter[key],
          );
        }

        findParams.selector = {
          ...findParams.selector,
          ...buildFilterSelector(filter),
        };
      }
    }
    // console.debug(
    //   `Returning from getList on resource ${resource} using a ${
    //     searchPromise ? 'search' : 'find'
    //   }`,
    // );
    const retval =
      searchPromise ||
      runFindQuery(DATABASES, resource, findParams, fieldToUse);

    return retval;
  };

  const getAll = (resource, params) => {
    v({
      msg: 'get all called',
      identifier: resource,
      data: { resource, params },
    });
    const { field, order = 'asc' } = params.sort || {};

    const dbNotInitialized =
      !DATABASES || !DATABASES[resource] || !DATABASES[resource].db;

    if (dbNotInitialized) {
      e({
        msg: `db ${resource} not initialized, be certain it is in SYMT or custom dbs list and to suspense screen till it loads`,
        data: { resource },
      });
      return Promise.resolve().then(() => ({
        data: [],
        total: 0,
      }));
    }

    return DATABASES[resource].db
      .allDocs({ include_docs: true })
      .then(({ rows, total_rows }) => {
        if (total_rows === 0) {
          return {
            data: [],
            total: 0,
          };
        }

        const finalRows = field
          ? rows.sort((a, b) => {
              if (!a.doc[field] || !b.doc[field]) {
                return 0;
              }
              const fieldA = a.doc[field].toLowerCase();
              const fieldB = b.doc[field].toLowerCase();
              if (fieldA < fieldB) {
                return order === 'asc' ? -1 : 1;
              }

              if (fieldA > fieldB) {
                return order === 'asc' ? 1 : -1;
              }

              return 0;
            })
          : rows;

        const data = finalRows.reduce((acc, { doc: { _id: id, ...rest } }) => {
          if (id.startsWith('_design')) {
            return acc;
          }
          acc.push({
            id,
            ...rest,
          });
          return acc;
        }, []);

        return {
          data,
          total: data.length,
        };
      });
  };

  const getList = async (resource, params) => {
    v({
      msg: 'get list called',
      identifier: resource,
      data: { resource, params },
    });
    // console.debug(`Received getList on resource ${resource}: `, params);
    if (!params || (resource !== 'hier' && !DATABASES[resource])) {
      // console.debug('getList: DATABASES or params missing: ', DATABASES);
      return { data: [], total: 0 };
    }

    v({
      msg: 'Running query',
      identifier: resource,
      data: { resource, params },
    });
    const retval = await getListCouchDb(resource, params);
    v({
      msg: 'Query returned',
      identifier: resource,
      data: { resource, params },
    });
    return retval;
  };

  const getOne = (resource, params) => {
    v({
      msg: 'get one called',
      identifier: resource,
      data: { resource, params },
    });
    return runGetOne(resource, params);
  };

  const getMany = (resource, params) => {
    v({
      msg: 'get many called',
      identifier: resource,
      data: { resource, params },
    });
    return runGetMany(resource, params);
  };

  const getManyReference = (resource, params) => {
    v({
      msg: 'many reference called',
      identifier: resource,
      data: { resource, params },
    });
    // console.debug(`Received getManyReference on ${resource}: `, params);
    const {
      page,
      perPage,
      // field,
      order,
      fieldToUse,
      // searchField,
      // searchValue,
      // searchType,
      // searchKeyParam,
    } = getListParseParams(params);

    const { field } = params.sort || {};
    const { selector, sort } = getSortOrIdSelectorFromParams({
      fieldToUse,
      order,
      target: params.target,
    });
    const findParams = {
      selector: {
        ...selector,
        ...buildFilterSelector(params.filter),
        [params.target]: params.id,
      },
      ...getLimitAndSkipFromParams({ perPage, page }),
    };

    if (sort) {
      findParams.sort = sort;
    }

    // if (field) {
    //   findParams.sort = [{ [field]: order.toLowerCase() }];

    //   if (!findParams.selector[field]) {
    //     findParams.selector[field] = { $exists: true };
    //   }
    // }
    return runFindQuery(DATABASES, resource, findParams, field);
  };

  // COMMENT: a patch find slim wrapper over db.find as getMany and getList did
  // not properl respect selector param.
  // TODO: validate and fix selector param in getMany, getList.
  const find = async (resource, params) => {
    v({
      msg: 'deleteIf called',
      identifier: resource,
      data: { resource, params },
    });

    const repository = DATABASES[resource].db;

    const result = await repository.find(params);

    const { docs, warning } = result || { docs: [] };

    w({ msg: 'Find warning', data: warning });

    return { data: { docs } };
  };

  const update = (resource, params) => {
    v({
      msg: 'update called',
      identifier: resource,
      data: { resource, params },
    });
    // console.debug(`Received update on ${resource}: `, params);
    // First make sure the document already exists, otherwise put does an upsert
    const { id } = params;
    // TODO : verify resource exists and throw meaningful error - for all data provider methods.
    return DATABASES[resource].db.get({ id }).then(existingDoc => {
      const { _attachments, ...rest } = params.data;
      // Object must be a PURE key-value pair object, no fancy things like DATES inside
      const payload = { ...existingDoc, ...JSON.parse(JSON.stringify(rest)) };
      if (_attachments) {
        payload._attachments = _attachments;
      }
      const { _id: putId, _rev: putRev } = existingDoc;
      return DATABASES[resource].db
        .put({ id: putId, rev: putRev, doc: payload })
        .then(({ ok, id, rev: _rev }) => {
          if (!ok) {
            throw new Error(
              `Error updating ${resource} with id ${
                params.id
              } and data ${JSON.stringify(payload)}`,
            );
          }

          const { _id, ...restDoc } = payload;

          return {
            data: {
              id,
              ...restDoc,
              _rev,
            },
          };
        });
    });
  };

  const updateMany = (resource, params) => {
    v({
      msg: 'update many called',
      identifier: resource,
      data: { resource, params },
    });
    return DATABASES[resource].db
      .allDocs({
        include_docs: true,
        attachments: true,
        keys: params.ids,
      })
      .then(({ rows }) => {
        const bulkData = rows.map(({ doc }) => {
          const { _attachments, ...rest } = params.data;
          const final = {
            ...doc,
            ...JSON.parse(...JSON.stringify(rest)),
          };

          if (_attachments) {
            final._attachments = _attachments;
          }
          return final;
        });
        return DATABASES[resource].db.bulkDocs(bulkData).then(result => ({
          data: result.reduce((acc, doc) => {
            if (!doc.error) {
              acc.push(doc);
            }
            return acc;
          }, []),
        }));
      });
  };

  const create = (resource, params) => {
    v({
      msg: 'create called',
      identifier: resource,
      data: { resource, params },
    });
    // console.debug(`Received create on ${resource}: `, params);
    const { _attachments, ...restData } = params.data;
    const repository =
      DATABASES && DATABASES[resource] && DATABASES[resource].db;
    // doc must be a “pure JSON object”, i.e. a collection of name/value pairs.
    // If you try to store non-JSON data (for instance Date objects) you may see inconsistent results.
    // https://pouchdb.com/errors.html#could_not_be_cloned
    const doc = { _attachments, ...JSON.parse(JSON.stringify(restData)) };
    const postPromise = repository.post({ doc });
    const result =
      postPromise &&
      postPromise.then(result => {
        if (!result.ok) {
          throw new Error(
            `Failed to create document: ${JSON.stringify(params.data)}`,
          );
        }
        return {
          data: { ...params.data, id: result.id, _rev: result.rev },
        };
      });
    return result;
  };

  /**
   * Creates or updates a document in the specified database based on the provided selector.
   * If the selector matches multiple documents, throws an error due to ambiguity.
   *
   * Use with care and caution when using this method due to a performance penalty as it initially performs a database query before any write operation,
   *
   * @param {string} resource - The name of the database resource to target.
   * @param {Object} params - Parameters for the operation, including the selector to find existing documents.
   * @param {Object} params.selector - The query selector used to find documents in the database.
   * @returns {Promise<void>} A promise that resolves when the operation is complete. The promise does not return any data but may reject with an error if the operation fails or if multiple documents are found for the given selector.
   */
  const createOrUpdate = async (resource, params) => {
    v({
      msg: 'createOrUpdate called',
      identifier: resource,
      data: { resource, params },
    });

    const repository = DATABASES[resource].db;
    const { selector } = params;
    const results = await repository.find({ selector });
    const { docs, warning } = results;

    if (warning) {
      w({ msg: warning });
    }

    const length = docs?.length;

    if (length > 1) {
      e({
        msg: 'Multiple docs returned for selector',
        data: { params, docsCount: length, resource },
      });
      throw new Error('Multiple docs returned for selector');
    }

    let createOrUpdateRef = create;
    let createOrUpdateRefParams = { ...params };

    if (length) {
      const doc = docs[0];
      const id = doc._id;
      createOrUpdateRef = update;
      createOrUpdateRefParams = { ...createOrUpdateRefParams, id };
    }

    delete createOrUpdateRefParams.selector;

    return createOrUpdateRef(resource, createOrUpdateRefParams);
  };

  const $delete = (resource, params) => {
    // TODO: must verify user has sufficient permissions to delete from couch - currently optimistic code.
    v({
      msg: 'delete called',
      identifier: resource,
      data: { resource, params },
    });
    return DATABASES[resource].db
      .get({ id: params.id })
      .then(existingDoc => {
        v({
          msg: 'Removing following doc version',
          identifier: resource,
          data: { id: existingDoc._id, rev: existingDoc._rev },
        });
        return DATABASES[resource].db.remove({
          id: existingDoc._id,
          rev: existingDoc._rev,
        });
      })
      .then(data => ({ data }));
  };

  const deleteMany = (resource, params) => {
    v({ msg: 'delete many called', data: { resource, params } });
    return DATABASES[resource].db
      .allDocs({
        keys: params.ids,
      })
      .then(({ rows }) =>
        DATABASES[resource].db
          .bulkDocs({
            docs: JSON.parse(
              JSON.stringify(
                rows.map(({ id: _id, value: { rev: _rev } }) => ({
                  _id,
                  _rev,
                  _deleted: true,
                })),
              ),
            ),
          })
          .then(result => ({
            data: result.reduce((acc, doc) => {
              if (!doc.error) {
                acc.push(doc);
              }
              return acc;
            }, []),
          })),
      );
  };

  const bulk = async (resource, params) => {
    const { items = undefined } = params || {};

    v({
      msg: 'bulk called',
      identifier: resource,
      data: { resource, items: items && items?.length },
    });

    if (!resource || !items) {
      e({
        msg: 'bulk called with missing resource or items',
        data: { resource, items },
      });
      throw new Error('bulk called with missing resource or items');
    }
    const repository = DATABASES[resource].db;
    const bulkResult = await repository.bulkDocs({ docs: items });
    const retval = { data: bulkResult };
    return retval;
  };

  return {
    setDataSource,
    getDataSource,
    getAll,
    getOne,
    getHierarchyRelatedDbs,
    getList,
    find,
    getMany,
    getManyReference,
    update,
    updateMany,
    create,
    createOrUpdate,
    bulk,
    delete: $delete,
    deleteMany,
  };
};

export const defaultDataProvider = createDataProvider();
/* eslint-enable no-console */

import http from 'http';
import https from 'https';

import axios from 'axios';
import _ from 'lodash';

import { coreAppServiceUrl } from '../isomorphic/config';

export const checkSyncAuth = async ({ username, databaseName }) => {
  try {
    if (!username || !databaseName) {
      console.error(
        `Missing username or databaseName ${{ username, databaseName }}`,
      );
      throw Error(
        `Missing username or databaseName ${{ username, databaseName }}`,
      );
    }
    const url = coreAppServiceUrl(`auth/syncAuthCheck`);

    const instance = axiosInstance();

    const callConfig = getCallConfig();
    const response = await instance.post(
      url,
      {
        username,
        databaseName,
      },
      callConfig,
    );
    // d({
    //   msg: `checkSyncAuth post to ${url} response`,
    //   data: response,
    // });
    const statusCode = _.get(response, 'data.statusCode');

    // eslint-disable-next-line eqeqeq
    const result = { ok: statusCode == 200 };
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const axiosInstance = () => {
  const httpAgent = new http.Agent({ keepAlive: false });
  const httpsAgent = new https.Agent({ keepAlive: false });

  const instance = axios.create({
    httpAgent,
    httpsAgent,
  });
  return instance;
};

const getCallConfig = () => {
  const accessToken = localStorage.getItem('access_token');
  const retval = accessToken
    ? {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    : {};
  return retval;
};

import PreservationIcon from '@material-ui/icons/Build';
import loadable from '@loadable/component';

const PreservationList = loadable(() => import('./PreservationList'));
const PreservationShow = loadable(() => import('./PreservationShow'));

export default {
  list: PreservationList,
  icon: PreservationIcon,
  show: PreservationShow,
};

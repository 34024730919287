/**
 * Checks if a string is lowercase and logs an error if it is not. Returns the string as lowercase.
 * @param {string | undefined} str The string to check.
 * @param {boolean} canBeUndefinedOrNull If true, the function will not log an error if the string is undefined.
 * @param {string} debugStr The string to use in the error message.
 * @returns {string} The string as lowercase.
 */
export function isLowerCase(
  str,
  canBeUndefinedOrNull = false,
  debugStr = 'string',
) {
  if (canBeUndefinedOrNull && (str === undefined || str === null)) {
    return str;
  }
  const lowerCaseResult = str.toLowerCase();
  if (lowerCaseResult !== str) {
    console.error(
      `!! hierarchyFunctions - ${debugStr} ${str} is NOT lowercase. This can indicate a problem with the data in the database.`,
    );
  }

  return lowerCaseResult;
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { logger } from '../../utils/logger';

import { CouchDbReplicaProviderAppContext } from './CouchDbReplicaProviderAppContext';
import useDb, { ProgressBar } from './hooks/useDb';

const { v } = logger({ source: 'Bootstrap Progress' });

export const BootstrapProgress = () => {
  const { allTables, syncContextInited } = useContext(
    CouchDbReplicaProviderAppContext,
  );
  const allTablesInited =
    allTables && Array.isArray(allTables) && allTables.length > 0;
  const { db: symtDb, syncState } = useDb(['symt']);
  const previousSyncStateRef = useRef();
  const redirect = useRedirect();
  const location = useLocation();

  const [
    {
      progressBarLocalDocsCount,
      progressBarRemoteDocsCount,
      progressBarRepositoiresSynced,
      progressBarRepositoiresCount,
    },
    setProgressBarLocalDocsCount,
  ] = useState({
    progressBarLocalDocsCount: 0,
    progressBarRemoteDocsCount: 0,
    progressBarRepositoiresSynced: 0,
    progressBarRepositoiresCount: 0,
  });

  useEffect(
    () => {
      const syncStateUpdate = !isEqual(syncState, previousSyncStateRef.current);
      if (syncStateUpdate) {
        previousSyncStateRef.current = syncState;
        const {
          localDocsCount = 0,
          remoteDocsCount = 0,
          repositoriesSynced = 0,
          repositoriesCount = 0,
        } = syncState;
        v({ msg: 'sync state update', data: { syncState } });
        setProgressBarLocalDocsCount({
          progressBarLocalDocsCount: localDocsCount,
          progressBarRemoteDocsCount: remoteDocsCount,
          progressBarRepositoiresSynced: repositoriesSynced,
          progressBarRepositoiresCount: repositoriesCount,
        });
      }
    },
    [syncState],
  );

  useEffect(
    () => {
      redirectCheck();
    },
    [allTables],
  );

  const redirectCheck = () => {
    if (!location || !location.pathname) {
      return;
    }
    const { pathname } = location;
    const bootstrapScreen = pathname.includes('bootstrap');
    // If user browses/being reidrected to bootstrap screen while context allTables variable has already been set
    // or symt db already loaded we redirect him to the dashboard, if allTables result is still inconclusive (undfined) we will wait and not redirect to avoid back and forth redirects.

    const dashboardRedirect = (allTablesInited || symtDb) && bootstrapScreen;
    if (dashboardRedirect) {
      redirect('/');
    }
  };

  // useEffect(
  //   () => {
  //     redirectCheck();
  //   },
  //   [db, location],
  // );

  // useEffect(() => {
  //   redirectCheck();
  // }, []);

  // Currently we simply pass onAnimationCompleted as is, we can wrap in next steps.

  const displayProgressbar = syncContextInited && !allTablesInited;
  const { text } = syncState;
  return displayProgressbar ? (
    ProgressBar && (
      <ProgressBar
        localDocsCount={progressBarLocalDocsCount}
        remoteDocsCount={progressBarRemoteDocsCount}
        repositoriesSynced={progressBarRepositoiresSynced}
        repositoriesCount={progressBarRepositoiresCount}
        onFullLoadProgressCompleted={redirectCheck}
        text={text}
      />
    )
  ) : (
    <></>
  );
};

export default BootstrapProgress;

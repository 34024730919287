import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { CouchDbReplicaProviderAppContext } from '../CouchDbReplicaProviderAppContext';
import { seedHiertop } from '../hierarchy/hiertop';

/**
 * @typedef {Object} HiertopStatus
 * @property {boolean} hiertopChecked - Indicates if `hiertop` has been checked yet.
 * @property {boolean} hiertopSeeded - Indicates if `hiertop` is finished seeding its data.
 */

/**
 * A hook that checks to seed `hiertop` with data if it doesn't already exist.
 *
 * @see CouchProvider
 * @see CoreSymtGate
 * @see useHierarchy
 *
 * @returns {boolean} Returns the checked & seeded status for hiertop
 */
const useHiertopCheck = () => {
  const [hiertopChecked, setHiertopChecked] = useState(false);
  const [hiertopSeeded, setHiertopSeeded] = useState(false);
  const { allDatabases, allDbsSyncDone } = useContext(
    CouchDbReplicaProviderAppContext,
  );
  const allDbsSynced = useSelector(state => state.db.allDbsSynced);

  const syncDone = allDbsSyncDone || allDbsSynced;

  useEffect(
    () => {
      if (
        (allDatabases &&
          allDatabases.hiertop &&
          (allDatabases.hiertop.hiertopSyncDone ||
            allDatabases.hiertop.hiertopLocalDocCount >=
              allDatabases.hiertop.hiertopRemoteDocCount) &&
          !hiertopChecked) ||
        (syncDone && !hiertopChecked)
      ) {
        setHiertopChecked(true);
        seedHiertop().then(() => {
          setHiertopSeeded(true);
        });
      }
    },
    [allDatabases, syncDone, hiertopChecked],
  );

  return { hiertopChecked, hiertopSeeded };
};

export default useHiertopCheck;

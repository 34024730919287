/* eslint-disable no-unreachable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import union from 'lodash/union';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { convertSymtToSimplSchema, readSymt } from '../../couch/winpcs';
import { getChecksheetConfigTablesNames } from '../../isomorphic/config';
import { couchConfig } from '../../couch/config';
import { logger } from '../../utils/logger';
import { listener } from '../../utils/eventshub';
import syncManager, {
  REPOSITORY_STATE,
  SYNC_MANAGER_EVENT_TYPES,
  SYNC_DATA_TYPE,
} from '../../couch/sync-manager';

import { isIOS } from './detectDevice';
import { CouchDbReplicaProviderAppContext } from './CouchDbReplicaProviderAppContext';

const { listen } = listener;

const { v, e, d } = logger({ source: 'CouchDbReplicaPorvider' });

// const AVAILABLE_STORAGE_TYPES = { INDEXDB: 'indexeddb', MEMORY: 'memory' };

const { getSelectedStorageType, setSelectedStorageType } = (() => {
  let selectedStorageType = 'idb';

  // eslint-disable-next-line no-shadow
  const getSelectedStorageType = () => selectedStorageType;

  // eslint-disable-next-line no-shadow
  const setSelectedStorageType = stroageType => {
    selectedStorageType = stroageType;
  };

  return { getSelectedStorageType, setSelectedStorageType };
})();

// TODO: make configurable with the db priorities.
const iosSupportedDbs = (() => {
  const checksheetTables = getChecksheetConfigTablesNames();
  const retval = new Set(checksheetTables);
  retval.add('core');
  retval.add('symt');
  retval.add('autopl');
  retval.add('refdisp');
  retval.add('conpmr');
  retval.add('phistory');
  retval.add('refpmr');
  retval.add('checksheet');
  retval.add('engequip');
  retval.add('con_checksheet_equipment');

  return retval;
})();

// TODO: add as part of config file.
const customPrioritiesMap = (() => {
  const retval = new Map();
  retval.set('core', 500);
  retval.set('symt', 500);
  retval.set('autopl', 750);
  retval.set('conpl', 755);
  retval.set('refdisp', 760);
  retval.set('conpmr', 770);
  retval.set('phistory', 780);
  retval.set('refpmr', 790);
  retval.set('con_checksheet_equipment', 794);
  retval.set('checksheet', 795);
  retval.set('engequip', 796);
  retval.set('checksheet_questions', 809);
  retval.set('checksheet_headers', 810);
  retval.set('checksheet_footers', 811);
  retval.set('checksheet_form', 812);
  retval.set('con_checksheet_form', 813);
  retval.set('con_checksheet', 814);
  retval.set('checksheet_values', 815);
  retval.set('rconn', 870);
  return retval;
})();

const systemDbs = ['core', 'symt'];

const applicativeDbs = [...customPrioritiesMap.keys()].filter(
  dbName => !systemDbs.includes(dbName),
);
/**
 *
 * @returns A custom list of required repositories - dervied from debugging errors.
 * TODO: either add to SYMT or create a config file for.
 */
const getCustomRepositoriesList = () => {
  const checksheetTables = getChecksheetConfigTablesNames();
  const retval = [
    ...checksheetTables,
    'autopl',
    'phistory',
    'hiertop',
    'con_checksheet_equipment',
  ];
  return retval;
  // return ["checksheet","con_checksheet_equipment","checksheet_questions","checksheet_headers","checksheet_footers","checksheet_form","con_checksheet_form","con_checksheet"];
};

// TODO: convert to object parameter
const getRepositoryInitParameters = ({
  repositoryName,
  priority = 0,
  partitionCount = 1,
  partitionPriorityIncrease = 1000,
  syncDataType,
  repeat,
  repeatInterval,
}) => {
  const storageType = getSelectedStorageType();
  const { url } = couchConfig;
  const calcaultedPriority = customPrioritiesMap.has(repositoryName)
    ? customPrioritiesMap.get(repositoryName)
    : priority;
  return {
    localDbName: repositoryName,
    remoteDBServerAddress: `${url}/${repositoryName}`,
    defaultReplicationConfiguration: {
      priority: calcaultedPriority,
      partitionCount,
      partitionPriorityIncrease,
      syncDataType,
      repeat,
      repeatInterval,
    },
    storageType,
  };
};

/**
 * TODO: the long async operation causes a react 'error' (more of a warnin)
 * @param {*} param0
 */
const syncSystemRepositoriesAndData = async ({
  syncManagerInstance,
  setSymtRepository,
  setCoreRepository,
  setSymtSimplSchemaByTable,
  setAllTables,
  setSymtDocs,
  setAllDatabases,
  setContextRepositories,
  setRepositoriesSyncStats,
}) => {
  v({ msg: 'syncSystemRepositoriesAndData called' });
  if (!syncManagerInstance) {
    // eslint-disable-next-line no-useless-return
    return;
  }
  const {
    initRepository,
    hasRepository,
    getRepository,
    syncContext,
    repositoriesStats,
  } = syncManagerInstance;

  // We start with core replication first as symt replication needs to block until we can produce the entire db tables scheme to replicate
  // but we do not want to postpone core replication till then but let the sync manager to schedule.
  const coreRepositoryInitParameters = getRepositoryInitParameters({
    repositoryName: 'core',
  });
  const coreRepository = hasRepository('core')
    ? getRepository('core')
    : initRepository(coreRepositoryInitParameters);

  setCoreRepository(coreRepository);

  coreRepository.startSync({
    config: { repeat: false, syncDataType: SYNC_DATA_TYPE.SYSTEM },
  });

  const symtRepositoryInitParameters = getRepositoryInitParameters({
    repositoryName: 'symt',
  });
  const symtRepository = hasRepository('symt')
    ? getRepository('symt')
    : initRepository(symtRepositoryInitParameters);
  setSymtRepository(symtRepository);

  symtRepository.startSync({
    config: { repeat: false, syncDataType: SYNC_DATA_TYPE.SYSTEM },
  });
  await symtRepository.waitState({
    state: REPOSITORY_STATE.REPOSITORY_FULLY_SYNCED,
  });
  v({ msg: 'Symt repository synced' });
  const allSymtDocs = await symtRepository.all();
  setSymtDocs(allSymtDocs);
  const symtSchema = await readSymt(symtRepository);
  // Todo : readSymt reads allDocs from repository internally, must update method to receive docs as arg.
  d({ msg: 'Symt schema read' });
  const simplifiedSymtSchema =
    convertSymtToSimplSchema && convertSymtToSimplSchema(symtSchema);
  setSymtSimplSchemaByTable(simplifiedSymtSchema);
  // allTablesSyncedList per CouchProvider.js allTables variable backward compatibility with context state.
  const symtTables = Object.keys(simplifiedSymtSchema) || [];
  const customRepositoriesList = getCustomRepositoriesList() || [];
  const allTablesSyncedList = union(
    ['core', 'symt'],
    symtTables,
    customRepositoriesList,
  );

  setAllTables(allTablesSyncedList);

  const allTablesSyncedListJSON = JSON.stringify(allTablesSyncedList);
  try {
    localStorage.setItem('allTablesList', allTablesSyncedListJSON);
  } catch (error) {
    e({
      msg: 'Failed to cache symt tables list.',
      error,
      data: { allTablesSyncedListJSON },
    });
  }

  // allRepositories is used for backward compatability while contextRepositories works by the new sync manager.
  // Important : we set in the context all the reposotries according to the symt table and start their sync process
  // we do not update the context further per repository data sync event (partition or full) as this will cause re-rending of possiblity large parts of the app
  // each time the context updates, intead we simply propogate the repositories through the context and it is up for each component to
  // get sync events and update accordingly.

  const supportedTablesSyncedList = allTablesSyncedList.filter(tableName => {
    const lowerCaseTableName = tableName.toLowerCase();
    const repositoryExists = hasRepository(lowerCaseTableName);

    const noSupport = isIOS && !iosSupportedDbs.has(lowerCaseTableName);

    const isCore = lowerCaseTableName === 'core';
    const isSymt = lowerCaseTableName === 'symt';

    const keep = !isCore && !isSymt && !repositoryExists && !noSupport;
    v({
      msg: 'Sync parameters will not be yield for following table',
      data: { isCore, isSymt, repositoryExists, isIOS, noSupport },
    });
    return keep;
  });

  v({
    msg: 'CouchDbReplicaProvider context final tables list',
    data: supportedTablesSyncedList,
  });

  // TODO: Upon login a resync would occur, as we currently do not dispose/free the sync manager and call a resync on same sync context in practice it would occur with previous (same) init parameters - this is semantically not correct - should be refactroed
  const repositoriesInitParameters = supportedTablesSyncedList.map(
    tableName => {
      // TODO : create a seperate applicative db names with default priority - would contain same dbs as custom.
      const applicative = customPrioritiesMap.has(tableName);
      const priority = customPrioritiesMap.has(tableName)
        ? customPrioritiesMap.get(tableName)
        : 3000;

      const { repeat, repeatInterval, syncDataType } = applicative
        ? {
            repeat: true,
            repeatInterval: 1000 * 60 * 5,
            syncDataType: SYNC_DATA_TYPE.APPLICATIVE,
          }
        : {
            repeat: true,
            repeatInterval: 1000 * 60 * 10,
            syncDataType: SYNC_DATA_TYPE.VIEW,
          };

      const repositoryInitParameters = getRepositoryInitParameters({
        repositoryName: tableName,
        priority,
        partitionCount: 1,
        partitionPriorityIncrease: 1,
        repeat,
        repeatInterval,
        syncDataType,
      });
      // todo : convert to object arg, set priorties properly.
      return repositoryInitParameters;
    },
  );

  // Backward compitability - TODO : remove references across the system.
  const allRepositories = [];

  // const initRepositoryBulkGenerator = initRepositoryBulk({
  //   repositoriesInitParameters,
  //   bulkSize: 10,
  // });

  const contextRepositories = new Map();
  contextRepositories.set('symt', symtRepository);
  contextRepositories.set('core', coreRepository);

  // TODO : update of the context repositoires can be moved into the syncManager.onStatsChangedEvent -- currently works well like so - will handle in next iterations.
  const onBulkRepositoriesInitedEvent = ({ repositoriesBulkInited }) => {
    if (repositoriesBulkInited) {
      allRepositories.push(...repositoriesBulkInited);
      repositoriesBulkInited.forEach(repository => {
        contextRepositories.set(repository.localDbName, repository);
      });
      const updatedContextRepositories = new Map(contextRepositories);
      // If we do not clone using new Map(originalMap) React will not propogate the set event to useAppContext components as it performs
      // simple ref equality not size compare.

      setContextRepositories(updatedContextRepositories);
    }
  };
  const onSyncProgressEvent = repositoriesSyncStats => {
    if (repositoriesSyncStats) {
      // If we do not clone using new Map(originalMap) React will not propogate the set event to useAppContext components as it performs
      // simple ref equality
      // const updatedRepositoriesSyncStats = { ...repositoriesSyncStats };
      // setRepositoriesSyncStats &&
      //   setRepositoriesSyncStats(updatedRepositoriesSyncStats);
    }
  };

  const allRepositoriesSyncPromise = syncContext({
    repositoriesInitParameters,
    bulkSize: 10,
    requiredSyncState: REPOSITORY_STATE.REPOSITORY_FULLY_SYNCED,
    onBulkRepositoriesInitedEvent,
    onSyncProgressEvent,
  });

  // TODO : Important : verify core is properly counted as part of the loading stats of all the repositories docs (as it might account to more than all the others)

  // Only at the end of the init context repositories bootstrap we block till core replication is done, as the app can not bootstrap without it.
  await coreRepository.waitState({
    state: REPOSITORY_STATE.REPOSITORY_FULLY_SYNCED,
  });
  v({ msg: 'Core repository sync completed' });

  /**
   * TODO : Important, split it into another function and useEffect.
   */
  v({
    msg: 'Wating for repositories to fully sync',
    data: {
      repositoriesCount: allRepositoriesSyncPromise.length,
    },
  });
  await allRepositoriesSyncPromise;

  const loadedRepositoriesNames = allRepositories.map(
    repository => repository.localDbName,
  );

  v({
    msg: 'Setting backward compatability AllDatabases',
    data: {
      loadedRepositoriesNamesLength: allRepositories.length,
      loadedRepositoriesNames,
    },
  });

  // This is a safeguard as it should have been synced through syncManagerUpdateStatsEventHandler
  // it helps in unit testing as it is easy to verify 'setAllDatabase' everything is synced
  // otherwise as  syncManagerUpdateStatsEventHandler works with timer you have to perhaps
  // wait till timer occurs.
  const syncCompleteRepositoriesStats = repositoriesStats();
  setRepositoriesSyncStats(syncCompleteRepositoriesStats);

  setAllDatabases([...allRepositories]);
};
/**
  * A fucntion as part of the CouchDbReplicaProvider file module, receives sync manager stats update and initiates a react component state update accoridngly, the function splits the state updates into two :
  * 1. lastEventRepositoriesSyncStatsUpdatesRef will update on each syncManager stats update but without triggering a react component state update
  * 2. setRepositoriesSyncStats will initiate a react component update but limit the incurring updates to more than one every UPDATE_DELTA = 3000, when the update will inccur it will perform it according to lastEventRepositoriesSyncStatsUpdatesRef, hence one sync manager event might trigger  the state update but by the time the sync will inccur another sync manager event would already update the ref to more recent stats.
 *
 *
  @param {Object} eventHandlerRequiredParameters
 * @param {Object} eventHandlerRequiredParameters.eventArgs - syncmanager stats update event args
 * @param {Object} eventHandlerRequiredParameters.lastEventRepositoriesSyncStatsUpdatesRef - a ref object to store last stats without triggering react component tree / ux  stats update.
 * @param {Object} eventHandlerRequiredParameters.setRepositoriesSyncStats - method to update the react sync stats.
 * @param {Object} eventHandlerRequiredParameters.updateInitiatedFlagRef - a  ref object for the method to maintain if it initiated an udpate or not.
 * @param {Object} eventHandlerRequiredParameters.isMountedRef - if the context is mounted or not.
 */

const syncManagerUpdateStatsEventHandler = ({
  eventArgs,
  lastEventRepositoriesSyncStatsUpdatesRef,
  updateInitiatedFlagRef,
  lastUpdateTimeRef,
  setRepositoriesSyncStats,
  isMountedRef,
}) => {
  try {
    const eventRepositoriesSyncStatsUpdates = get(
      eventArgs,
      'data.repositoriesStats',
    );

    if (!eventRepositoriesSyncStatsUpdates) {
      return;
    }

    lastEventRepositoriesSyncStatsUpdatesRef.current = eventRepositoriesSyncStatsUpdates;

    if (updateInitiatedFlagRef.current) {
      v({
        msg: 'Sync stats update already inititaed, returning.',
        data: { updateInitiated: updateInitiatedFlagRef.current },
      });
      return;
    }

    const now = Date.now();

    const UPDATE_DELTA = 3000;
    const fromLastUpdate = now - lastUpdateTimeRef.current;

    const currentUpdateDelta = UPDATE_DELTA - fromLastUpdate;
    const waitTimeout = Math.max(currentUpdateDelta, 0);
    updateInitiatedFlagRef.current = true;

    v({
      msg: 'Initiating sync stats context update',
      data: { now, fromLastUpdate, waitTimeout, updateInitiatedFlagRef },
    });

    // In case now - lastUpdate is greater than UPDATE_DELTA , e.g 3000 , curretUpdateDelta would be negative number, in which case Math.max will result in 0 - immediate upate.
    setTimeout(() => {
      try {
        if (isMountedRef && isMountedRef.current) {
          const updateStats =
            lastEventRepositoriesSyncStatsUpdatesRef !== undefined &&
            lastEventRepositoriesSyncStatsUpdatesRef.current !== undefined;

          if (updateStats) {
            const newStatsMap = new Map(
              lastEventRepositoriesSyncStatsUpdatesRef.current,
            );
            setRepositoriesSyncStats(newStatsMap);
          }
          v({
            msg: 'Updating stats',
          });

          lastUpdateTimeRef.current = now;
          updateInitiatedFlagRef.current = false;
        }
      } catch (error) {
        e({ msg: 'on sync event stats handler update error', error });
      }
    }, waitTimeout);
  } catch (error) {
    e({ msg: 'on sync event stats handler error', error });
  }
};

const onAuthEventHandler = (eventArgs, setLoggedIn) => {
  d({ msg: 'Auth event handler called', data: eventArgs });
  const {
    data: { loginState },
  } = eventArgs;

  // The reason we set state updates in arrow function
  // is that if retal equals to same current value
  // no rerender and thus useEffect would be called.

  switch (loginState) {
    case 'loggedIn': {
      setLoggedIn(() => true);
      break;
    }
    case 'loggedOut': {
      setLoggedIn(() => false);
      break;
    }
    default:
      break;
  }
};

export const __testAPI__ = {
  syncSystemRepositoriesAndData,
  getSelectedStorageType,
  setSelectedStorageType,
  syncManagerUpdateStatsEventHandler,
};

const CouchDbReplicaProvider = props => {
  // TODO: fetch symt/core dbs names and urls from config.
  const syncManagerInstanceRef = useRef();
  const [symtRepository, setSymtRepository] = useState();
  const [coreRepository, setCoreRepository] = useState();
  const [symtSimplSchemaByTable, setSymtSimplSchemaByTable] = useState();
  const [symtDocs, setSymtDocs] = useState();
  const lastUpdateTimeRef = useRef(0);
  const updateInitiatedFlagRef = useRef(false);
  const lastEventRepositoriesSyncStatsUpdatesRef = useRef();
  const [loggedIn, setLoggedIn] = useState(null);
  const syncStarted = useRef(false);
  const authEventRegistered = useRef(false);
  const isMountedRef = useRef(false);

  // allTables is a string array of all tables compiled from SYMT while  contextRepositories is the new state/class to manage pouch sync,
  // allDatabases is for backward compatability, both hold references to repositories which in turn support all required apis.
  const [allTables, setAllTables] = useState();
  const [allDatabases, setAllDatabases] = useState();
  const [contextRepositories, setContextRepositories] = useState();

  const [syncContextInited, setSyncContextInited] = useState(false);

  const realtimeSyncs = useRef(new Map());

  // [repositoriesSyncCompleted,totalRepositories]
  const [repositoriesSyncStats, setRepositoriesSyncStats] = useState(new Map());

  const realTimeSync = useCallback(async nameArray => {
    if (!nameArray) {
      return false;
    }

    const alldbs = isEqual(nameArray, ['*']);

    if (alldbs) {
      return false;
    }

    const coreSymtGatewayUseDb = isEqual(nameArray, ['symt']);

    if (coreSymtGatewayUseDb) {
      return false;
    }

    d({ identifier: nameArray, msg: 'Real time sync check' });
    // In case contextRepositories undefined means we are still in init phase - no realtime possible.
    if (!contextRepositories) {
      d({
        identifier: nameArray,
        msg: 'Real time sync, no context repositories',
      });
      // eslint-disable-next-line no-useless-return
      return false;
    }

    const realtimeSyncsDbNames = realtimeSyncs.current
      ? [...realtimeSyncs.current.keys()]
      : [];

    const nameChanged = !isEqual(realtimeSyncsDbNames, nameArray);

    if (!nameChanged) {
      d({
        identifier: realtimeSyncsDbNames,
        msg: 'Real time sync, target db no change',
        data: { nameArray, realtimeSyncsDbNames },
      });
      return false;
    }

    v({
      identifier: nameArray,
      msg: 'Real time sync, target db change',
      data: { nameArray, realtimeSyncsDbNames },
    });

    // if (realtimeSyncsDbNames.length > 0) {
    //   const realtimeSyncsMap = realtimeSyncs.current;
    //   realtimeSyncsDbNames.forEach(dbName => {
    //     const repository = contextRepositories.has(dbName)
    //       ? contextRepositories.get(dbName)
    //       : undefined;
    //     if (repository) {
    //       const syncTaskId =
    //         realtimeSyncsMap.has(dbName) && realtimeSyncsMap.get(dbName);
    //       repository.cancelSync({ syncTaskId });
    //     }
    //   });
    // }
    const repeatCallback = request => {
      try {
        const { repositoryId } = request || {};
        const repositryIdStringParts = repositoryId && repositoryId.split('/');
        const localDbName =
          repositryIdStringParts &&
          repositryIdStringParts.length > 0 &&
          repositryIdStringParts[1];
        const repeat = realtimeSyncs.current.has(localDbName);
        v({
          identifier: repositoryId,
          msg: `repository ${repositoryId} sync real-time repeat : ${repeat} `,
          data: {
            request,
            repositoryId,
            localDbName,
            repeat,
          },
        });
        return repeat;
      } catch (repeatError) {
        e({ error: repeatError });
        return false;
      }
    };

    const realTimeStartSyncCalls = nameArray.map(async localDbName => {
      if (!localDbName) {
        return Promise.resolve();
      }
      const localDbNameLowerCase = localDbName.toLowerCase();
      // const isApplicativeDb = applicativeDbs.includes(localDbNameLowerCase);
      const isSystemDb = systemDbs.includes(localDbNameLowerCase);
      // const repeat = isApplicativeDb ? repeatCallback : false;

      // const repeat = true;
      const repository =
        !isSystemDb &&
        contextRepositories &&
        contextRepositories.has(localDbNameLowerCase)
          ? contextRepositories.get(localDbNameLowerCase)
          : undefined;

      const startRealTimeSync = repository !== undefined && !isSystemDb;

      if (!startRealTimeSync) {
        v({
          identifier: localDbName,
          msg: 'Reopository not found, realtime sync would not start',
          data: { localDbName, startRealTimeSync },
        });
        return Promise.resolve();
      }

      v({
        identifier: localDbName,
        msg: 'Reopository start realtime sync',
        data: { localDbName, startRealTimeSync },
      });

      const requestPromise = repository.startSync({
        config: {
          syncDataType: SYNC_DATA_TYPE.REAL_TIME,
          repeat: repeatCallback,
          repeatInterval: 1000 * 20,
        },
      });

      // TODO: request needs to return localDbName, but exposed only recently (2024-07-27) and
      // at times returns undefined, thus, currently set the localDbName from here (was passed from here to syncmanager at anycase)
      // should be returned from the request back when it is inited back from the sync manager.
      const promiseRetval = requestPromise.then(request => {
        const { syncTaskId } = request || {};
        if (!request || !syncTaskId) {
          e({
            msg: 'Real time sync start request returned undefined',
            data: { localDbName, request, syncTaskId },
          });
        }
        return { localDbName, syncTaskId };
      });
      return promiseRetval;
    });

    const realTimeRequests = await Promise.all(realTimeStartSyncCalls);
    realtimeSyncs.current = new Map(
      realTimeRequests
        .filter(request => request)
        .map(({ localDbName, syncTaskId }) => [localDbName, syncTaskId]),
    );
    v({
      msg: 'Real time tasks inited, current real time tasks',
      data: { realtimeSyncs: realtimeSyncs.current },
    });
    return true;
  });

  const syncManagerUpdateStatsEventHandlerCallback = useCallback(eventArgs => {
    syncManagerUpdateStatsEventHandler({
      eventArgs,
      setRepositoriesSyncStats,
      updateInitiatedFlagRef,
      lastEventRepositoriesSyncStatsUpdatesRef,
      lastUpdateTimeRef,
      isMountedRef,
    });
  });

  const onAuthEventCallback = useCallback(eventArgs =>
    onAuthEventHandler(eventArgs, setLoggedIn),
  );

  const updateStats = useCallback(() => {
    const { repositoriesStats } =
      syncManagerInstanceRef && syncManagerInstanceRef?.current
        ? syncManagerInstanceRef.current
        : {};
    const currentRepositoriesStats = repositoriesStats
      ? repositoriesStats()
      : undefined;
    const performUpdateStats =
      currentRepositoriesStats && currentRepositoriesStats.size > 0;
    if (performUpdateStats) {
      setRepositoriesSyncStats(currentRepositoriesStats);
    }
  });

  useEffect(() => {
    v({ msg: 'CouchDbReplicaProvider AppContext mounted' });
    if (!authEventRegistered.current) {
      listen({
        channel: 'auth',
        listenerName: 'CouchDbReplicaProvider',
        callback: onAuthEventCallback,
      });
      authEventRegistered.current = true;
    }
    isMountedRef.current = true;
    const userName = localStorage.getItem('username');
    if (userName) {
      setLoggedIn(true);
    }
    updateStats();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(
    () => {
      (async () => {
        const initSyncManager = !syncManagerInstanceRef.current && loggedIn;
        if (initSyncManager) {
          v({ msg: 'Initializing sync manager' });
          const syncManagerInstance = await syncManager();

          syncManagerInstanceRef.current = syncManagerInstance;

          syncManagerInstanceRef.current.on({
            subscriber: 'CouchDbReplicaProvider',
            eventType: SYNC_MANAGER_EVENT_TYPES.REPOSITORIES_STATS_UPDATE,
            callback: syncManagerUpdateStatsEventHandlerCallback,
          });
        }

        try {
          // Using 'var' here so it will be accessible in the catch block below (cleaner & more consistent code)
          // eslint-disable-next-line vars-on-top, no-var
          var cachedAllTablesSyncedListJSON = localStorage.getItem(
            'allTablesList',
          );

          const cachedAllTablesSyncedList = cachedAllTablesSyncedListJSON
            ? JSON.parse(cachedAllTablesSyncedListJSON)
            : [];

          if (cachedAllTablesSyncedList) {
            setAllTables(cachedAllTablesSyncedList);
          }
        } catch (loadCachedAllTablesSyncedListError) {
          e({
            error: loadCachedAllTablesSyncedListError,
            // eslint-disable-next-line block-scoped-var
            data: { cachedAllTablesSyncedListJSON },
          });
          setAllTables([]);
        }

        updateStats();

        setSyncContextInited(true);

        // loggedIn can be true,false or null
        if (loggedIn === false) {
          d({ msg: 'User logged out' });
          // When user logs out we reset the resync flag.
          // TODO : examine if we need to halt the sync process.
          syncStarted.current = false;
          // syncManagerInstanceRef?.current?.logout();
          if (syncManagerInstanceRef && syncManagerInstanceRef.current) {
            syncManagerInstanceRef.current.logout();
          }
        }

        // On each re-login we will try to resync, check for syncManagerInstanceRef.current again in-case of init failure.
        const startResync =
          syncManagerInstanceRef.current && !syncStarted.current && loggedIn;
        if (startResync) {
          v({ msg: 'Calling sync' });
          syncStarted.current = true;
          await syncSystemRepositoriesAndData({
            syncManagerInstance: syncManagerInstanceRef.current,
            setSymtRepository,
            setCoreRepository,
            setSymtSimplSchemaByTable,
            setSymtDocs,
            setAllTables,
            setAllDatabases,
            setContextRepositories,
            setRepositoriesSyncStats,
          });
          syncStarted.current = false;
        }
      })();
    },
    [loggedIn],
  );

  // TODO: verify which context variables are acctually required as besides contextRepositotires
  // the rest were placed as backward compatability per previous app context.
  return (
    <CouchDbReplicaProviderAppContext.Provider
      value={{
        allTables,
        allDatabases,
        contextRepositories,
        symtDocs: { ...symtDocs },
        symtSimplSchemaByTable: { ...symtSimplSchemaByTable },
        symtRepository,
        coreRepository,
        repositoriesSyncStats,
        syncContextInited,
        applicativeDbs,
        realTimeSync,
      }}
    >
      {props.children}
    </CouchDbReplicaProviderAppContext.Provider>
  );
};

CouchDbReplicaProvider.propTypes = {
  children: PropTypes.node,
};

export default CouchDbReplicaProvider;

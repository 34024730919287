import SimpleSchema from 'simpl-schema';
import UniformsAntd from 'uniforms-antd';

// symtSchema is keyed by tablename and subkeyed by fieldname
// {
//   conpmr: {
//     rserial: {
//       wtype: 'R',
//       // ... more fields
//     }
//   }
// }
// Output keyed by table and subkeyed by fieldname, but with contents of subkeys in the shape of simplschema
export const convertSymtToSimplSchema = symtSchema =>
  Object.keys(symtSchema)
    .map(tableKey => ({
      [tableKey]: Object.keys(symtSchema[tableKey])
        .map(fieldKey => {
          let type = String;
          switch (symtSchema[tableKey][fieldKey].wtype) {
            case 'A':
            case 'Z':
            case 'E':
              type = String;
              break;

            case 'D':
            case 'T':
            case 'S':
              type = Date;
              break;

            case 'I':
            case 'U':
            case 'R':
            case 'N':
              type = SimpleSchema.Integer;
              break;

            case 'F':
              type = Number;
              break;

            case 'B':
              type = Object;
              break;

            default: {
              // eslint-disable-next-line no-console
              console.error('NO WTYPE FOUND:');
              // eslint-disable-next-line no-console
              console.error(symtSchema[tableKey][fieldKey]);
              return {};
            }
          }

          const uniforms = symtSchema[tableKey][fieldKey].uniforms || null;

          const ret = {
            [fieldKey]: {
              type,
              optional: true,
            },
          };

          if (uniforms) {
            if (uniforms.component) {
              uniforms.component = UniformsAntd[uniforms.component];

              if (!uniforms.component) {
                delete uniforms.component;
              }
            }
            ret.uniforms = uniforms;
          }
          return ret;
        })
        .reduce((final, current) => {
          Object.assign(final, current);
          return final;
        }, {}),
    }))
    .reduce((final, current) => {
      Object.assign(final, current);
      return final;
    }, {});

/*
 _id and _rev are the standard couchdb entries that exist everywhere
 basename identifies the name of the table -- this appears in all the records in SYMT that describes this table; thus
 the schema for the certac1 table is made from all the records in symt that has this value for basename.
 fieldname is the name of the field in the table,  One record in symt per field, this one refers to the one named rserial
 priority is an ordering number, it indicates the order we will want to see these fields in Inspect and other places. I
 have noticed that the order of them being returned from couch seems random and it gets really confusing... so this
 field has become of higher importance. It is used internally in WinPCS for ordering the fields.
 woffset used to mean the offset into the record data block on btrieve systems, it has carried over but doesn't have the
 same important meaning here. It also used to be just offset, but that is an SQL reserved keyword, so it was changed to
 avoid trouble there.
 wlength used to similarly mean the length allocated for the field in the btrieve data block.  With SQL and couch, this
 is less interesting since the data there can be larger. This is used for sizing fields in Inspect views now.
 wtype indicates the type of data stored in the field. This is a letter, which started out as the btrieve mnemonics and
 was extended slightly later. The known set is defined in include/datalyt.h as follows:

 #define WinPCS_ASTRING  'A'
 #define WinPCS_DATE     'D'
 #define WinPCS_TIME     'T'
 #define WinPCS_ZSTRING  'Z'
 #define WinPCS_INTEGER  'I'
 #define WinPCS_UNSIGNED 'U'
 #define WinPCS_FLOAT    'F'
 #define WinPCS_AUTOINC  'R'
 #define WinPCS_NUMERIC  'N'
 #define WinPCS_BLOB     'B'
 #define WinPCS_TEXT     'E'

 of these, the most common ones are A D Z I and R, sometimes we see T and U -- these are mapped to equivalent types in
 sql systems, but mostly treated as text inside WinPCS. The D field type allows fields to be identified for
 date-formatting-conversions, so the usual ISO8601/rfc3339 style YYYY-MM-DD can be changed to the projects' MM/DD-YYYY
 or DD/MM/YYYY variants.

 keyno identified key fields in the old Pervasive systems, This was the key number as defined in that system or -1 for
 fields not being keys. Since we can do queries on any fields in sqlite (and couch, from what it looks like) these
 numbers are less critical by themselves, but along with the keyflag and segment_no, the TXS files and the internal
 winpcs representation of this is used to define constraints in sql tables. I foresee something similar for couch, where
 I have to do constraints myself.

 keyflag is still used to indicate a unique field -- M is unique and B is non-unique. An addition S (would be MS now)
 indicates this is part of a multi-field constraint.

 segment_no indicates the order of the fields in the constraint. Used to be vitally important for btrieve where these
 things were called segmented keys.

 fieldclass is another old distinction, when the record was carefully mapped out with the offset and length values,
 fields could be defined as fully or partially overlapping other fields in the record. Thus this flag indicated which
 field was the real "owner" of that part of the record, and which other fields were overlaying.  Additionally this might
 indicate if the field was to be zeroed before insertions, needed for autoincrement fields, so the possibilities are R
 for Real field, A for Autozero (implies real field) and O for Overlaying.  Since the internal storage with couch is
 more similar to the sql systems, the Overlaying variant is not likely to appear.  The recent practice has been to mark
 Autoincrement (type R) fields as fieldclass A (autozero) and all the others to fieldclass R (real field), but no
 auto-zeroing of these fieldclass A fields actually takes place. In fact, the whole auto-increment business in the couch
 system isn't defined yet.
 */

export const SCHEMAS = {};

export const readSymt = Symt =>
  Symt.allDocs({
    include_docs: true,
    descending: true,
  })
    .then(
      ({ total_rows: totalRows, rows }) =>
        (totalRows > 0 &&
          rows.map(({ doc }) => ({
            ...doc,
          }))) ||
        [],
    )
    .then(docs =>
      // Key by tablename and subkey by fieldname
      // {
      //   conpmr: {
      //     rserial: {
      //       wtype: 'R',
      //       // ... more fields
      //     }
      //   }
      // }
      docs.reduce((final, current) => {
        const { basename, fieldname, ...rest } = current;
        if (rest._id.startsWith('_')) {
          return final;
        }
        if (!final[basename]) {
          // eslint-disable-next-line no-param-reassign
          final[basename] = {};
        }
        Object.assign(final[basename], {
          [fieldname]: {
            ...rest,
          },
        });
        return final;
      }, {}),
    );

/* eslint-disable no-shadow */
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import { logger, auditor } from '../../utils/logger';
import pouchDbApiInstance, { addPlugin } from '../pouchDbAPI';
import {
  getDbsDocCount as couchApiGetDbsDocCount,
  online as couchApiServerOnline,
} from '../couchDbAPI';
import { createEventsHub } from '../../utils/eventshub';
import { checkSyncAuth as syncApicheckSyncAuth } from '../syncAPI';

import PriorityQeueue from './data-structures/priority-queue/PriorityQueue';
import LinkedQueue from './data-structures/linked-list/LinkedQueue';
import eventsEmitter from './eventsEmitter';

const uuid = require('node-uuid').v4;

/**
 * Adds a pouch plugin that can be used by sync manager and the various repositories based on per repository config.
 * @param {object} plugin a plugin of pouch
 * @returns
 */
export const addPouchPlugin = plugin => addPlugin(plugin);

const {
  STATE_CHANGE,
  EVENT_FIRE,
  EVENT_RECEIVED,
  DECLINE,
  OPERATION_BEGIN,
  OPERATION_END,
  OPERATION_CANCEL,
} = auditor.AUDIT_TYPE;

const { v, e, d } = logger({ source: 'sync-manager' });

// TODO : turn into es6 bit b0

// TODO : add state - REPOSITORY_FULLY_SYNCED_UPDATES_PENDING after repository modification.
/**
 * Enum Repositry Sync State
 * @readonly
 * @enum {number}
 */
export const REPOSITORY_STATE = {
  REPOSITORY_EMPTY: 1,
  REPOSITORY_UNSYNCED: 2,
  REPOSITORY_STALE: 4,
  REPOSITORY_SYNC_PENDING: 8,
  REPOSITORY_PARTIALLY_SYNCED: 16,
  REPOSITORY_FULLY_SYNCED: 32,
  REPOSITORY_FAULT: 64,
};

/**
 * Enum Repositry Acitvity State
 * @readonly
 * @enum {number}
 */
export const REPOSITORY_ACTIVITY = {
  REPOSITORY_NO_ACTIVITY: 1,
  REPOSITORY_SYNC_PENDING: 2,
  REPOSITORY_SYNCING: 4,
};

export const REQUEST_STAGE = {
  REQUEST_INIT: 1,
  REQUEST_SYNC_PENDING: 2,
  REQUEST_SYNC_ACTIVE: 4,
  REQUEST_SYNC_COMPLETE: 8,
  REQUEST_SYNC_INTERRUPT: 16,
  REQUEST_SYNC_CANCEL: 32,
  REQUEST_SYNC_DECLINE: 64,
  REQUEST_SYNC_DROP: 128,
  REQUEST_SYNC_LOGOUT: 256,
  REQUEST_SYNC_ERROR: 512,
};

const REQUEST_STAGE_SET = new Set(Object.values(REQUEST_STAGE));

/**
 * Block is used as highest priority scheduler sync type to block a sync slot - currently for testing purposes but a good practice nontheless.
 */
export const SYNC_DATA_TYPE = {
  BLOCK: 1,
  SYSTEM: 2,
  REAL_TIME_INIT: 4,
  REAL_TIME: 8,
  MODIFICATIONS: 16,
  APPLICATIVE_INIT: 32,
  APPLICATIVE: 64,
  VIEW: 128,
};

export const ACTIVE_SYNC_SET_SIZE = 7;

/**
 * TBD : modifications threshold
 */
export const SYNC_DATA_TYPE_ACTIVE_SYNCS_THRESHOLD = {
  1: ACTIVE_SYNC_SET_SIZE,
  2: ACTIVE_SYNC_SET_SIZE,
  4: ACTIVE_SYNC_SET_SIZE,
  8: 2,
  16: ACTIVE_SYNC_SET_SIZE,
  32: ACTIVE_SYNC_SET_SIZE,
  64: 2,
  128: 2,
};

export const SYNC_MANAGER_EVENT_TYPES = {
  DATA_SYNC: 'DATA_SYNC',
  REPOSITORIES_STATS_UPDATE: 'REPOSITORIES_STATS_UPDATE',
};

const repositoryStatesFromBitMask = state => {
  const repositoryStates = [];
  Object.keys(REPOSITORY_STATE).forEach(repositoryState => {
    const repositoryStateValue = REPOSITORY_STATE[repositoryState];
    // eslint-disable-next-line no-bitwise
    const isStateBitMask = repositoryStateValue & state;
    if (isStateBitMask) {
      repositoryStates.push(repositoryState);
    }
  });
  return repositoryStates;
};

/**
 * Provides a single entry point and unified API for both on-line and offline data fetch & sync in-front of CouchDB and it's offline
 * sync library PouchDb.
 *
 * 1.Encapsulates and abstract CouchDb & PouchDB apis.
 * 2.Enables hybrid on-line & offline connectivities and seamless transition between then two per requirement for both network issues handling
 *   and optimized user browsing experience (view repository documentation below)
 * 3.Provides shared and prioritized/synchoronized resources between both multiple CouchDB calls and PouchDB sync processes.
 * 4.Monitors connectivity and implements 'retry' behavior.
 * 5.Enables access via both client side as well as pre-generated server side pages calls (Next.JS)
 *
 * Base CouchDb/PouchDb 'db' access api is a repository, based on the repository design pattern, opposed to classic design
 * currently the repositoy does not need to hold entities in-memory as they are held internally in IndexDB but might do so in the future
 * for various use-cases.
 *
 * JS OOP is implemented in an object referncing internal function state powerful yet straightforward pattern,
 * prototype OOP might be used in the future if needed.
 */
// eslint-disable-next-line consistent-return
export const createSyncManager = async ({
  activeSyncSetSize = ACTIVE_SYNC_SET_SIZE,
  syncsDataTypeActiveSyncsThreshold = SYNC_DATA_TYPE_ACTIVE_SYNCS_THRESHOLD,
}) => {
  const determinedSyncsDataTypeActiveSyncsThreshold = {
    ...SYNC_DATA_TYPE_ACTIVE_SYNCS_THRESHOLD,
    ...syncsDataTypeActiveSyncsThreshold,
  };
  try {
    const eventsHub = createEventsHub();
    const SYNC_EVENT_TYPES = {
      START_PARTITION_REPLICATION_REQUEST_EVENT:
        'START_PARTITION_REPLICATION_REQUEST_EVENT',
      START_PARTITION_REPLICATION_REQUEST_ACK_EVENT:
        'START_PARTITION_REPLICATION_REQUEST_ACK_EVENT',
      START_PARTITION_REPLICATION_PENDING_EVENT:
        'START_PARTITION_REPLICATION_PENDING_EVENT',
      HALT_REPLICATION_REQUEST_EVENT: 'HALT_REPLICATION_REQUEST_EVENT',
      HALT_REPLICATION_ACK_EVENT: 'HALT_REPLICATION_ACK_EVENT',
      COMPLETED_PARTITION_REPLICATION_EVENT:
        'COMPLETED_PARTITION_REPLICATION_EVENT',
      COMPLETED_DB_REPLICATION_EVENT: 'COMPLETED_DB_REPLICATION_EVENT',
      SYNC_DATA_TYPE_REQUEST_ALREADY_EXISTS_IN_QUEUE:
        'SYNC_DATA_TYPE_REQUEST_ALREADY_EXISTS_IN_QUEUE',
      CANCEL_REQUEST_EVENT: 'CANCEL_REQUEST_EVENT',
      CANCEL_REQUEST_ACK_EVENT_: 'CANCEL_REQUEST_ACK_EVENT',
      CANCEL_REQUEST_DECLINE_EVENT_: 'CANCEL_REQUEST_DECLINE_EVENT',
      REQUEST_DROP_EVENT: 'REQUEST_DROP_EVENT',
      REQUEST_DISCONNECT_EVENT: 'REQUEST_DISCONNECT_EVENT',
    };

    // TODO : move to inisde the repository.
    const REPOSITORY_EVENT_TYPES = {
      REPOSITORY_STATE_CHANGED: 'REPOSITORY_STATE_CHANGED',
      REPOSITORY_ACTIVITY_CHANGED: 'REPOSITORY_ACTIVITY_CHANGED',
      REPOSITORY_DATA_SYNC: 'REPOSITORY_DATA_SYNC',
    };

    const syncManagerEventsEmitter = eventsEmitter({
      eventsEmitterName: 'sync-manager',
      eventTypes: SYNC_MANAGER_EVENT_TYPES,
    });

    const on = ({ subscriber, eventType, callback }) => {
      syncManagerEventsEmitter.on({ subscriber, eventType, callback });
    };

    const SYNC_MANAGER_LOCAL_STORAGE_KEY = 'sync-manager';

    const repositories = new Map();
    const getServerDbsDocsCount = async () => {
      let retval = new Map();

      try {
        const syncManagerCacheJSON = localStorage.getItem(
          SYNC_MANAGER_LOCAL_STORAGE_KEY,
        );

        const syncManagerCache =
          syncManagerCacheJSON && JSON.parse(syncManagerCacheJSON);

        const {
          remoteServerDbsCount: remoteServerDbsCountCache,
        } = syncManagerCache || { remoteServerDbsCount: undefined };

        const properCache = Array.isArray(remoteServerDbsCountCache);
        if (properCache) {
          const deserailizedRemoteServerDbsCountArray = remoteServerDbsCountCache;
          const deserailizedRemoteServerDbsCountMap = new Map(
            deserailizedRemoteServerDbsCountArray,
          );
          retval = deserailizedRemoteServerDbsCountMap;
        }

        const onlineState = await couchApiServerOnline();
        v({ msg: 'getServerDbsDocsCount online state', data: { onlineState } });
        if (onlineState) {
          const couchApiGetDbsDocCountResponse = await couchApiGetDbsDocCount();

          const {
            dbsDocsCount: remoteServerDbsCount,
          } = couchApiGetDbsDocCountResponse || { dbsDocsCount: undefined };

          if (remoteServerDbsCount) {
            retval = remoteServerDbsCount;
            let updatedSyncManagerCache = syncManagerCache || {};
            const serializedRemoteServerDbsCountEntries = remoteServerDbsCount.entries();
            const serializedRemoteServerDbsCountArray = Array.from(
              serializedRemoteServerDbsCountEntries,
            );
            updatedSyncManagerCache = {
              ...updatedSyncManagerCache,
              remoteServerDbsCount: serializedRemoteServerDbsCountArray,
            };
            const updatedSyncManagerCacheJSON = JSON.stringify(
              updatedSyncManagerCache,
            );
            localStorage.setItem(
              SYNC_MANAGER_LOCAL_STORAGE_KEY,
              updatedSyncManagerCacheJSON,
            );
            v({
              msg: 'updated remote dbs counts',
              data: { serializedRemoteServerDbsCountArray },
            });
          }
        }
      } catch (error) {
        e({ error });
      }
      return retval;
    };

    const remoteServerDatabasesDocsCountMap = await getServerDbsDocsCount();

    const repositoriesStats = (() => {
      const retval = new Map();
      // TODO : change to map() from forEach()
      remoteServerDatabasesDocsCountMap.forEach((docsCount, localDbName) => {
        const repositoryStats = {
          localDocsCount: 0,
          remoteDocsCount: docsCount,
          repositoryState: REPOSITORY_STATE.REPOSITORY_EMPTY,
        };
        retval.set(localDbName, repositoryStats);
      });
      return retval;
    })();

    let loggedIn = true;

    const logout = () => {
      loggedIn = false;
      syncScheduler.clear();
      repositories.forEach(repository => {
        repository.logout();
      });
      d({ msg: 'Logged out' });
    };

    const login = () => {
      loggedIn = true;
    };
    /**
     * The complete Triforce, or one or more components of the Triforce.
     * @typedef {Object} syncQueueEventsHandlers
     * @property {eventsEmitterEventHandler} onStartSyncAckEvent - Indicates whether the Courage component is present.
     * @property {boolean} hasPower - Indicates whether the Power component is present.
     * @property {boolean} hasWisdom - Indicates whether the Wisdom component is present.
     */
    // eslint-disable-next-line no-unused-vars
    const syncScheduler = await (async () => {
      const syncDataTypes = Object.values(SYNC_DATA_TYPE);
      const syncDataTypePrioritiyQueues = {};
      const syncDataTypeActiveSyncRequestCountMap = new Map();
      const syncDataTypeRepositoryQueuedRequestsSet = new Set();
      const requestsMap = new Map();
      const handledRequestsQueue = new LinkedQueue();
      const timeoutQueue = new LinkedQueue();
      const activeSyncs = new Set();
      const activeSyncsRepositories = new Set();
      const canceleldRequestsSet = new Set();

      const TIME_OUT_CHECK_INTERVAL = 5 * 1000; // TODO: Configurable
      const SYNC_EXPIRATINON = 1000 * 60 * 90; // TODO: Configurable
      const MAX_HANDLED_REQUESTS_QUEUE = 1500; // Used for monitoring and reviewing.

      const { d, a } = logger({
        source: 'sync-scheduler',
      });

      // Populates prioritiyQueues - > prioritiyQueues[SYNC_DATA_TYPE.SYSTEM] = new PriorityQueue(), prioritiyQueues[SYNC_DATA_TYPE.REAL_TIME] = new PriorityQueue() etc...
      syncDataTypes.forEach(key => {
        syncDataTypePrioritiyQueues[key] = new LinkedQueue();
        syncDataTypeActiveSyncRequestCountMap.set(key, 0);
      });

      // TODO : Propogate data properly, move from here - temp (!).

      const schedulerMon = () => {
        const activeSyncsRequests =
          activeSyncs &&
          [...activeSyncs].reduce((reduced, requestId) => {
            const request = requestsMap.has(requestId)
              ? requestsMap.get(requestId)
              : { requestId };
            reduced.push(request);
            return reduced;
          }, []);
        const handledRequestsArray = handledRequestsQueue.toArray();

        const monData = cloneDeep({
          timeoutQueue,
          activeSyncsRepositories,
          requestsMap,
          activeSyncs,
          activeSyncsRequests,
          syncDataTypePrioritiyQueues,
          syncDataTypeRepositoryQueuedRequestsSet,
          syncDataTypeActiveSyncRequestCountMap,
          handledRequestsArray,
        });

        function filterMapByDbName(dbNames) {
          const dbNamesArray = Array.isArray(dbNames) ? dbNames : [dbNames];
          const repositoryIdsArray = dbNamesArray.map(
            dbName => `repository/${dbName}`,
          );

          const result = new Map();

          this.requestsMap.forEach((value, key) => {
            if (repositoryIdsArray.includes(value.repositoryId)) {
              result.set(key, value);
            }
          });
          return result;
        }
        monData.filterMapByRepositoryId = filterMapByDbName.bind(monData);

        return monData;
      };

      window.schedulerMon = schedulerMon;
      window.syncStatus = dbNames =>
        schedulerMon().filterMapByRepositoryId(dbNames);
      window.lastSync = dbNames => {
        const requests = schedulerMon().filterMapByRepositoryId(dbNames) || [];
        const requestsArray = Array.from(requests.entries());
        const lastThreeRequests = requestsArray.slice(-3);
        return new Map(lastThreeRequests);
      };

      const eventsHubEventsHandler = event => {
        const { type } = event;
        v({ msg: 'Event received', data: { event } });

        switch (type) {
          case SYNC_EVENT_TYPES.START_PARTITION_REPLICATION_REQUEST_EVENT: {
            startSyncRequestEventHandler(event);
            break;
          }
          case SYNC_EVENT_TYPES.COMPLETED_PARTITION_REPLICATION_EVENT: {
            completedSyncEventHandler(event);
            break;
          }
          case SYNC_EVENT_TYPES.HALT_REPLICATION_ACK_EVENT: {
            syncHaltedAckEventHandler(event);
            break;
          }
          case SYNC_EVENT_TYPES.CANCEL_REQUEST_EVENT:
            cancelRequest(event);
            break;
          case SYNC_EVENT_TYPES.REQUEST_DROP_EVENT:
            completedSyncEventHandler(event);
            break;
          default:
            break;
        }
      };

      const clear = () => {
        Object.values(syncDataTypePrioritiyQueues).forEach(queue => {
          queue.clear();
        });

        syncDataTypeActiveSyncRequestCountMap.clear();
        syncDataTypeRepositoryQueuedRequestsSet.clear();
        requestsMap.clear();
        handledRequestsQueue.clear();
        activeSyncs.clear();
        activeSyncsRepositories.clear();
        d({ msg: 'Scheduler state cleared' });
      };

      const cancelRequest = event => {
        const { requestId, syncDataType, repositoryId } = event;
        const activeSync = activeSyncs.has(requestId);
        if (activeSync) {
          eventsHub.dispatch({
            type: SYNC_EVENT_TYPES.CANCEL_REQUEST_DECLINE_EVENT_,
            source: 'scheduler',
            target: repositoryId,
            data: { repositoryId, requestId, syncDataType },
          });
          a({
            type: DECLINE,
            action: 'REQUEST_CANCEL',
            data: { requestId },
            msg: 'Request cancel declined',
          });
          return;
        }
        canceleldRequestsSet.add(requestId);
        syncDataTypeRepositoryQueuedRequestsSet.delete({
          requestId,
          syncDataType,
        });
        a({ type: STATE_CHANGE, data: { requestId }, msg: 'Request canceled' });
        eventsHub.dispatch({
          type: SYNC_EVENT_TYPES.CANCEL_REQUEST_ACK_EVENT_,
          source: 'scheduler',
          target: repositoryId,
          data: { repositoryId, requestId, syncDataType },
        });
      };

      const startSyncRequestEventHandler = event => {
        const {
          data: { repositoryId, priority, requestId, syncDataType },
        } = event;

        if (!requestId || !syncDataType || !repositoryId || priority < 0) {
          e({
            msg: 'Bad request',
            data: {
              repositoryId,
              priority,
              requestId,
              syncDataType,
            },
            throwError: true,
          });
        }

        if (!syncDataTypePrioritiyQueues[syncDataType]) {
          e({
            msg: 'Bad request, sync data type not found',
            data: { repositoryId, priority, requestId, syncDataType },
            throwError: true,
          });
        }
        a({
          type: EVENT_RECEIVED,
          action: 'SYNC_REQUEST_RECEIVED',
          data: { event },
        });

        const syncDataTypeRepositoryRequest = { repositoryId, syncDataType };

        const repositorySyncDataTypeRequestQueued = syncDataTypeRepositoryQueuedRequestsSet.has(
          syncDataTypeRepositoryRequest,
        );

        if (repositorySyncDataTypeRequestQueued) {
          a({
            type: DECLINE,
            data: { event, repositorySyncDataTypeRequestQueued },
            action: 'SYNC_REQUEST_RECEIVED',
            msg: 'Sync request with that data type already exists in queue',
          });
          eventsHub.dispatch({
            type:
              SYNC_EVENT_TYPES.SYNC_DATA_TYPE_REQUEST_ALREADY_EXISTS_IN_QUEUE,
            source: 'scheduler',
            target: repositoryId,
            data: { priority, repositoryId, requestId, syncDataType },
          });
          return;
        }

        requestsMap.set(requestId, {
          repositoryId,
          priority,
          requestId,
          syncDataType,
        });

        const syncDataTypePriorityQueue =
          syncDataTypePrioritiyQueues[syncDataType];

        syncDataTypePriorityQueue.enqueue(requestId);

        const { nextSyncRequestId } = syncNext() || {
          nextSyncRequestId: undefined,
        };

        // eslint-disable-next-line eqeqeq
        if (nextSyncRequestId == requestId) {
          v({
            identifier: repositoryId,
            msg: 'Request sync immediate',
            data: { repositoryId, requestId, priority, syncDataType },
          });
          // event was dispatched by syncNext()
        } else {
          v({
            identifier: repositoryId,
            msg: 'Request sync pending',
            data: { repositoryId, requestId, priority, syncDataType },
          });
          const syncDataTypePrioritiyQueuesAuditClone = cloneDeep(
            syncDataTypePrioritiyQueues,
          );
          const activeSyncsAuditClone = cloneDeep(activeSyncs);
          const { data } = event || {};
          a({
            type: STATE_CHANGE,
            action: 'SYNC_REQUEST_PENDING_QUEUED',
            data: {
              request: data,
              syncDataTypePrioritiyQueuesAuditClone,
              syncDataType,
              activeSyncsAuditClone,
            },
          });
          eventsHub.dispatch({
            type: SYNC_EVENT_TYPES.START_PARTITION_REPLICATION_PENDING_EVENT,
            source: 'scheduler',
            target: repositoryId,
            data: { priority, repositoryId, requestId, syncDataType },
          });
        }
      };

      /**
       * Handles acknolegement from the repository a sync process has completed, removes it from the active syncs set
       * and approves next sync on queue to begin syncing.
       * @param {*} event
       */
      const completedSyncEventHandler = event => {
        /**
         * TODO: Need to handle edge-case where expirtation timer for repository in scheduelr occurs just before completed event is fired by repository
         * in that case it would no longer be in activeSyncs -- need to determine what should be the new priority of expired repo.
         */
        const {
          data: { requestId },
        } = event;
        if (!requestId) {
          e({
            msg: 'Received event with undefined request id',
            data: { event },
          });
        }
        if (activeSyncs.has(requestId)) {
          activeSyncs.delete(requestId);
        } else {
          e({
            msg: 'Request completed but was not on active syncs set',
            data: { event },
          });
        }

        const { syncDataType, repositoryId } = requestsMap.has(requestId)
          ? requestsMap.get(requestId)
          : {};
        const count = syncDataTypeActiveSyncRequestCountMap.get(syncDataType);
        const updatedCount = Math.max(count - 1, 0);
        syncDataTypeActiveSyncRequestCountMap.set(syncDataType, updatedCount);
        activeSyncsRepositories.delete(repositoryId);

        handledRequestsQueue.enqueue(requestId);

        if (handledRequestsQueue.size >= MAX_HANDLED_REQUESTS_QUEUE) {
          const {
            requestId: deprecatedHanldedRequestId,
          } = handledRequestsQueue.dequeue();
          requestsMap.delete(deprecatedHanldedRequestId);
        }

        syncNext();
      };

      /**
       * Handles acknolegement from the repository a sync process has been halted, removes it from the active syncs set
       * and approves next sync on queue to begin syncing.
       * @param {*} event
       */
      const syncHaltedAckEventHandler = event => {
        const {
          data: { requestId },
        } = event;
        if (activeSyncs.has(requestId)) {
          activeSyncs.delete(requestId);
        }
        syncNext();
      };

      eventsHub.listen({
        listenerName: 'scheduler',
        callback: eventsHubEventsHandler,
      });

      /**
       * Main interval timeloop to perform required periodic scheduler tasks, currently only checks for sync expirations.
       */
      setInterval(() => {
        const now = new Date().getTime();

        // The expirtation queue was is FIFO, hence we can iterate as long as 'now' is bigger than the next expirtation time on the queue.

        // If there is an object at the head of the queue it will be returned, if it is null/undefined then
        // a default flag object will be returned.
        // eslint-disable-next-line prefer-const
        let { expirtaionMillis, requestId } = timeoutQueue.peek() || {
          expirtaionMillis: now,
          requestId: undefined,
        };

        while (requestId && expirtaionMillis < now) {
          const { repositoryId, syncDataType } =
            requestsMap.get(requestId) || {};

          timeoutQueue.dequeue();
          // We do not clear the expiration queue in the onCompleted method as
          // we can not determine it's position, hence it might be completed
          // and no need to re-call halt.
          if (repositoryId && syncDataType >= 0 && activeSyncs.has(requestId)) {
            // TOO: fire all details of the request.
            const event = {
              source: 'scheduler',
              type: SYNC_EVENT_TYPES.HALT_REPLICATION_REQUEST_EVENT,
              data: { repositoryId, requestId, syncDataType },
              target: repositoryId,
            };
            eventsHub.dispatch(event);
            // This only requests a repository to stop syncing, it does not yet remove it from the active syncs set
            // as halting might take some additional time -- TODO - how to perform terminiation if required.
          }
          // If there is an object at the head of the queue it will be returned, if it is null/undefined then
          // a default flag object will be returned.
          // Note: Becuase vars were already decalred, in order to destructure you need paranthesis.
          requestId = timeoutQueue.peek() || {
            expirtaionMillis: now,
            repositoryId: undefined,
            requestId: undefined,
          };
        }
      }, TIME_OUT_CHECK_INTERVAL);

      /**
       * Polls for next repository sync request from priority queue and approves it by firing the startSyncAckEvent
       * TODO: currently adds repository immedietly to activeSyncs set, perhaps should wait till ack from repository
       * that it acctually managed to begin sync (TBD next step when integrating with Pouch - part of error/retry behavior)
       */
      const syncNext = () => {
        v({
          msg: 'Sync next called',
        });

        if (activeSyncs.size >= activeSyncSetSize) {
          v({
            msg: 'Will not begin next sync, max syncs reached.',
            data: {
              activeSyncSetSize,
              activeSyncsCount: activeSyncs.size,
            },
          });
          return undefined;
        }

        const dequeueCondition = request => {
          const { repositoryId } = requestsMap.has(request)
            ? requestsMap.get(request)
            : {};
          const repositorySingleActiveSync =
            repositoryId && !activeSyncsRepositories.has(repositoryId);

          const retval = repositorySingleActiveSync;
          return retval;
        };

        // eslint-disable-next-line no-undef-init, prefer-const
        let nextSyncRequestQueue = undefined;
        let nextSyncRequestId;
        // The queues are writtein the syncDataTypes by their prioritiy, iterating through syncDataTypes will iterate by prioriity.
        for (
          let syncDataTypesIndex = 0;
          syncDataTypesIndex < syncDataTypes.length && !nextSyncRequestId;
          syncDataTypesIndex++
        ) {
          const syncDataType = syncDataTypes[syncDataTypesIndex];

          const syncDataTypeQueue = syncDataTypePrioritiyQueues[syncDataType];

          const syncDataTypeActiveSyncRequestCount = syncDataTypeActiveSyncRequestCountMap.has(
            syncDataType,
          )
            ? syncDataTypeActiveSyncRequestCountMap.get(syncDataType)
            : 0;

          const syncDataTypeActiveSyncsThreshold =
            determinedSyncsDataTypeActiveSyncsThreshold[syncDataType];

          const syncDataTypeRequestCountBelowThreshold =
            syncDataTypeActiveSyncRequestCount <
            syncDataTypeActiveSyncsThreshold;

          nextSyncRequestQueue =
            syncDataTypeQueue.size > 0 && syncDataTypeRequestCountBelowThreshold
              ? syncDataTypeQueue
              : undefined;

          const nextQueueSyncRequstId = nextSyncRequestQueue
            ? nextSyncRequestQueue.dequeue({ condition: dequeueCondition })
            : undefined;
          // debugger;
          // In case request has been already cancled we do nothing.
          nextSyncRequestId =
            nextQueueSyncRequstId &&
            !canceleldRequestsSet.has(nextQueueSyncRequstId)
              ? nextQueueSyncRequstId
              : undefined;

          v({
            msg: 'Examined sync request data type queue',
            data: {
              syncDataTypeQueueLength: syncDataTypeQueue.length,
              syncDataTypeActiveSyncRequestCount,
              syncDataTypeActiveSyncsThreshold,
              isNextSyncRequestQueue: nextSyncRequestQueue !== undefined,
            },
          });
        }

        if (nextSyncRequestId) {
          const monData = schedulerMon();
          v({ msg: 'Scheduler Mon data', data: { monData } });
          const nextSyncRequest = requestsMap.has(nextSyncRequestId)
            ? requestsMap.get(nextSyncRequestId)
            : {};
          // TODO : internal bug, see how to handle.
          const {
            repositoryId,
            priority,
            requestId,
            syncDataType,
          } = nextSyncRequest;
          if (!requestId || syncDataType === undefined || !repositoryId) {
            e({
              msg: 'intenral error, request was not stored properly',
              data: {
                repositoryId,
                priority,
                requestId,
                syncDataType,
              },
            });
            return undefined;
          }

          // const sync

          d({
            identifier: repositoryId,
            msg: 'Next sync request',
            data: { syncDataType, repositoryId, priority, requestId },
          });

          activeSyncs.add(requestId);
          activeSyncsRepositories.add(repositoryId);
          const now = new Date().getTime();
          const expirtaionMillis = now + SYNC_EXPIRATINON;

          timeoutQueue.enqueue({
            expirtaionMillis,
            requestId,
          });
          const count = syncDataTypeActiveSyncRequestCountMap.get(syncDataType);
          const updatedCount = count + 1;
          syncDataTypeActiveSyncRequestCountMap.set(syncDataType, updatedCount);

          const syncDataTypePrioritiyQueuesAuditClone = clone(
            syncDataTypePrioritiyQueues,
          );
          const activeSyncsAuditClone = clone(activeSyncs);
          const timeoutQueueCloneAudit = clone(timeoutQueue);
          a({
            type: STATE_CHANGE,
            action: 'NEXT_SYNC_REQUEST',
            data: {
              request: nextSyncRequestId,
              syncDataType,
              timeoutQueueCloneAudit,
              syncDataTypePrioritiyQueuesAuditClone,
              activeSyncsAuditClone,
            },
          });
          a({
            type: EVENT_FIRE,
            target: repositoryId,
            action: 'NEXT_SYNC_REQUEST',
            data: {
              request: nextSyncRequestId,
            },
          });
          nextSyncRequest.syncBeginTime = new Date().toUTCString();
          eventsHub.dispatch({
            source: 'scheduler',
            type:
              SYNC_EVENT_TYPES.START_PARTITION_REPLICATION_REQUEST_ACK_EVENT,
            target: repositoryId,
            data: { priority, repositoryId, requestId, syncDataType },
          });
        }

        return nextSyncRequestId;
      };

      const mon = () => {
        const syncDataTypePrioritiyQueuesSizeCounts = Object.keys(
          syncDataTypePrioritiyQueues,
        ).reduce((reducedValue, syncDataType) => {
          const queue = syncDataTypePrioritiyQueues[syncDataType];
          const syncDataTypeSize = queue.size;

          const updatedReducedValue = {
            ...reducedValue,
            [syncDataType]: syncDataTypeSize,
          };
          return updatedReducedValue;
        }, {});
        const schedulerData = {
          syncDataTypes,
          syncDataTypePrioritiyQueuesSizeCounts,
          syncDataTypeActiveSyncRequestCountMap,
          timeoutQueue,
          activeSyncs,
        };
        const clone = cloneDeep(schedulerData);

        return clone;
      };

      return { mon, clear };
    })();

    /**
     * Returns repository by dbName
     * @param {string} dbName
     * @returns
     */
    const getRepository = localDbName => {
      const retval = repositories.get(localDbName);
      return retval;
    };

    /**
     * @typedef DefaultReplicationConfiguration
     * @type {object}
     * @property {number} priority
     * @property {number} partitionCount
     * @property {number} partitionPriorityIncrease
     */

    /**
     * @typedef RepositoryInitParameters
     * @type {object}
     * @property {string} localDbName
     * @property {string} remoteDBServerAddress
     * @property {DefaultReplicationConfiguration} defaultReplicationConfiguration - The default replication configuration for the repository as long as it has not be overrided by parameters that can be passed to the startSync method.
     * @property {string} storageType - indexeddb || memory
     */

    const onRepositoryDataSyncEventHandler = eventArgs => {
      const { data } = eventArgs;
      const {
        localDocsCount,
        remoteDocsCount,
        localDbName,
        repositorySyncState,
        dataChangesCount,
        syncPullCount,
        syncPushCount,
      } = data;

      // TODO: review if required (?)
      const calcaultedRemoteDocsCount =
        remoteDocsCount || remoteServerDatabasesDocsCountMap.has(localDbName)
          ? remoteServerDatabasesDocsCountMap.get(localDbName)
          : 0;

      repositoriesStats.set(localDbName, {
        localDocsCount,
        remoteDocsCount: calcaultedRemoteDocsCount,
        repositoryState: repositorySyncState,
        dataChangesCount,
        syncPullCount,
        syncPushCount,
      });
      const clonedRepositoriesStats = cloneDeep(repositoriesStats); // We must perform deep clone to prevent  modifications of the dictionary by event subscribers.
      syncManagerEventsEmitter.dispatch({
        dispatcher: 'syncManager',
        eventType: SYNC_MANAGER_EVENT_TYPES.REPOSITORIES_STATS_UPDATE,
        data: {
          repositoriesStats: clonedRepositoriesStats,
          lastRepositoryUpdate: localDbName,
        },
      });
    };

    const onRepositoryStateChangeEventHandler = eventArgs => {
      const { data } = eventArgs;
      const { localDbName, state } = data;

      const repositorySyncStats = repositoriesStats.get(localDbName);
      const updatedRepositorySyncStats = {
        ...repositorySyncStats,
        repositoryState: state,
      };
      repositoriesStats.set(localDbName, updatedRepositorySyncStats);
      const clonedRepositoriesStats = cloneDeep(repositoriesStats); // We must perform deep clone to prevent intenral state change by event subscribers.
      syncManagerEventsEmitter.dispatch({
        dispatcher: 'syncManager',
        eventType: SYNC_MANAGER_EVENT_TYPES.REPOSITORIES_STATS_UPDATE,
        data: {
          repositoriesStats: clonedRepositoriesStats,
        },
      });
    };

    /**
     * Inits a repository if does not exists,
     * returns existing one if does.
     * @param {RepositoryInitParameters} repositoryInitParameters
     */
    const initRepository = ({
      localDbName,
      remoteDBServerAddress,
      testLocalDbName1,
      defaultReplicationConfiguration,
      storageType,
    }) => {
      const exists = hasRepository(localDbName);
      let newRepository;
      if (!exists) {
        newRepository = repository({
          localDbName,
          testLocalDbName1,
          remoteDBServerAddress,
          defaultReplicationConfiguration,
          storageType,
        });
        repositories.set(localDbName, newRepository);

        const {
          localDbName: newRepositoryLocalDbName,
          localDocsCount: newRepositoryLocalDocsCount,
          remoteDocsCount: newRepositoryRemoteDocsCount,
          state: newRepositoryState,
        } = newRepository;
        const couchRESTRemoteDocsCount = remoteServerDatabasesDocsCountMap.has(
          newRepositoryLocalDbName,
        )
          ? remoteServerDatabasesDocsCountMap.get(newRepositoryLocalDbName)
          : -1;
        const calcaultedRemoteDocsCount = newRepositoryRemoteDocsCount()
          ? newRepositoryRemoteDocsCount()
          : couchRESTRemoteDocsCount;

        const newRepositorySyncStats = {
          localDocsCount: newRepositoryLocalDocsCount(),
          remoteDocsCount: calcaultedRemoteDocsCount,
          repositoryState: newRepositoryState(),
        };
        repositoriesStats.set(newRepositoryLocalDbName, newRepositorySyncStats);

        newRepository.on({
          eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_DATA_SYNC,
          callback: onRepositoryDataSyncEventHandler,
          subscriber: 'syncManager',
        });

        newRepository.on({
          eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_STATE_CHANGED,
          callback: onRepositoryStateChangeEventHandler,
          subscriber: 'syncManager',
        });

        const clonedRepositoriesStats = cloneDeep(repositoriesStats); // We must perform deep clone to prevent intenral state change by event subscribers.
        syncManagerEventsEmitter.dispatch({
          dispatcher: 'syncManager',
          eventType: SYNC_MANAGER_EVENT_TYPES.REPOSITORIES_STATS_UPDATE,
          data: {
            lastRepositoryUpdate: newRepositoryLocalDbName,
            repositoriesStats: clonedRepositoriesStats,
          },
        });
      } else {
        throw new Error(`Db ${localDbName} repository already exists`);
      }
      return newRepository;
    };

    /**
     * As initRepository is relatively heavy function with long processing duration in-case of a large
     * amount of repositories to be inited the initRepositoryBulk divides the bulk into smaller chunks and
     * inits those chunks sequentily to prevent system hang / main process hang.
     * Receives an array of repositoriesInitParameters and inits the repositories in small bulks, returns inited repositories
     * in case a repository already previously inited returns it.
     * @param {*} param0
     * @returns
     */
    // eslint-disable-next-line no-inner-declarations
    async function* initRepositoryBulk({
      repositoriesInitParameters,
      bulkSize,
    }) {
      // const dbsList = getDbsDocCount();
      const delayedBulkInit = repositoriesBulk => {
        const repositoriesBulkClone = [...repositoriesBulk];
        return new Promise(resolve => {
          try {
            // TODO: define proper error handling - should the entire process be stopped or not ?
            setTimeout(() => {
              try {
                const initedRepositoriesYield = [];
                repositoriesBulkClone.forEach(repositoryInitParameters => {
                  const {
                    localDbName: repositoryName,
                  } = repositoriesInitParameters;
                  const repositoryExists = hasRepository(repositoryName);
                  const repository = !repositoryExists
                    ? initRepository(repositoryInitParameters)
                    : getRepository(repositoryName);
                  initedRepositoriesYield.push(repository);
                });
                resolve(initedRepositoriesYield);
              } catch (error) {
                e({ msg: 'Failed to bulk init', error });
                // reject(error);
              }
            }, 500);
          } catch (error) {
            e({ msg: 'Failed to set timeout', error });
          }
        });
      };
      const initRepositoryQueue = repositoriesInitParameters.reduce(
        (priorityQueue, repository) => {
          if (repository) {
            const priority = get(
              repository,
              'defaultReplicationConfiguration.priority',
              2000,
            );
            priorityQueue.add(repository, priority);
          }
          return priorityQueue;
        },
        new PriorityQeueue(),
      );

      // TODO : remove size property from priority queue - debug only.
      const initRepositorySize = initRepositoryQueue.size();
      v({ msg: 'initRepositoryQueue', data: { size: initRepositorySize } });

      let currentBulkSize = 0;
      let currentRepositoriesInitParametersBulk = [];
      let repository;
      let totalRepositoriesInited = 0;
      do {
        repository = initRepositoryQueue.poll();
        if (repository) {
          currentRepositoriesInitParametersBulk.push(repository);
          currentBulkSize++;
        }
        const remainder = currentBulkSize > 0 && !repository;
        // Either we reached bulksize or the last poll would be repository == undefined and it is the last remainder > 0;
        if (currentBulkSize >= bulkSize || remainder) {
          // We intentionally perfrom the promise in a loop to delay
          // eslint-disable-next-line no-await-in-loop
          const initedRepositoriesBulk = await delayedBulkInit(
            currentRepositoriesInitParametersBulk,
          );
          currentRepositoriesInitParametersBulk = [];
          currentBulkSize = 0;
          totalRepositoriesInited += initedRepositoriesBulk.length;
          yield initedRepositoriesBulk;
        }
      } while (repository != null);
      v({ msg: 'Bulk init completed', data: { totalRepositoriesInited } });
    }

    /**
     * @typedef SyncProgressEventArgs
     * @type {object}
     * @param {number} totalRepositories
     * @param {number} initedRepositories
     * @param {number} repositoriesSyncCompleted
     * @param {number} totalLocalDocsCount
     * @param {number} estimatedRemoteDocsCount
     * @param {object[]} syncContextRepositories - repositories that were inited so far by the repositories init parameters provided.
     * @param {Map<string,any>} syncContextRepositoriesStats - map for repositories doc stats local / remote and sync status for repositories synced so far.
     */

    /**
   * 1.The method receives a list of db names, initializes their corrsponding repositories, starts and waits their syncing process to complete,
   * tracks their syncing progress on the doc level, shares data via callback and reports overall status.
   *
   * 2.Due to the fact that initialization of a large amount of repositories and underneath PouchDB API is a time intesneive/consuming operation
   * which may block main app thread of long duration (seconds / tens of seconds) this method
   * receives a list of repositories init parameters and bulk size and gradually with fixed delays in between inits all repositories
   * and starts their syncing process.
   * The method then tracks their sync progress till they reach either PARTIAL_REPLICATION or FULL_REPLICATION state while notifying via
   * the onSyncProgressCallback of - totalRepositoriesInitalized,totalRepositoriesRequiredSyncState,totalLocalDocsCount,totalRemoteDocsCount
   *
   * 3.The method uses direct CouchDB access to gather overall doc count stats (and in the future perhaps more) hence is optimized versus
   * working directy with each repository API.
   *
   * @param {{repositoriesInitParameters:RepositoryInitParameters[],bulkSize:number,requiredSyncState:string,onBulkRepositoriesInitedEvent
  ,onSyncProgressEvent
   * :(SyncProgressEventArgs)=>void}}

   */
    const syncContext = async ({
      repositoriesInitParameters,
      bulkSize,
      requiredSyncState,
      onBulkRepositoriesInitedEvent,
      onSyncProgressEvent,
    }) => {
      const dbsNamesList = repositoriesInitParameters.reduce(
        (reducedDbsList, repositoryInitParameters) => {
          const { localDbName } = repositoryInitParameters || {};
          if (localDbName) {
            reducedDbsList.push(localDbName);
          }
          return reducedDbsList;
        },
        [],
      );

      const {
        reducedRemoteServersDocsCountMap: remoteDbsDocsCount,
        reducedTotalDocsCount: couchRESTRemoteDocsCount,
      } = dbsNamesList.reduce(
        (
          { reducedTotalDocsCount, reducedRemoteServersDocsCountMap },
          dbName,
        ) => {
          const dbRemoteCountExists = remoteServerDatabasesDocsCountMap.has(
            dbName,
          );
          if (dbRemoteCountExists) {
            const remoteDocsCount = remoteServerDatabasesDocsCountMap.get(
              dbName,
            );
            reducedRemoteServersDocsCountMap.set(dbName, remoteDocsCount);
            reducedTotalDocsCount += remoteDocsCount;
          } else {
            e({
              msg: `Remote docs count for ${dbName} does not exists - data discrepancy might occur.`,
              data: { dbName },
            });
          }
          return { reducedRemoteServersDocsCountMap, reducedTotalDocsCount };
        },
        {
          reducedTotalDocsCount: 0,
          reducedRemoteServersDocsCountMap: new Map(),
        },
      );

      // const remoteDbsDocsCount = remoteServerDatabasesDocsCountMap;

      let totalRemoteDocsCount = couchRESTRemoteDocsCount;
      let totalLocalDocsCount = 0;
      let repositoriesSyncCompleted = 0;
      let initedRepositories = 0;

      const totalRepositories = repositoriesInitParameters.length;

      const syncContextRepositories = new Map();

      /**
       * Eventhough this data is stored also in the local state of the repositry, because we need to start and present 'remote_docs_count'
       * both total and both for each repository based on what we fetched from couch REST even before all or some of the repositories have been inited,
       * we create a seperate syncContextRepositoriesStats map to hold the stats of the repositories right from the start.
       * In practice after all the repositories have been inited that data can be access and aggregated directly from the repositories themselves.
       */
      const syncContextRepositoriesStats = repositoriesInitParameters.reduce(
        (reducedMap, repositoryInitParameters) => {
          const { localDbName: repositoryName } = repositoryInitParameters || {
            localDbName: undefined,
          };
          const repositoryRemoteStatsExists = repositoryName
            ? remoteDbsDocsCount.has(repositoryName)
            : false;
          if (repositoryRemoteStatsExists) {
            // TODO: handle case where it is isnt.
            const remoteDocsCount = remoteDbsDocsCount.get(repositoryName);
            const repositorySyncStats = {
              localDocsCount: 0,
              remoteDocsCount,
              repositoryState: REPOSITORY_STATE.REPOSITORY_EMPTY,
            };
            reducedMap.set(repositoryName, repositorySyncStats);
          } else {
            e({
              msg: 'Repository does not have remote docs count.',
              data: { repositoryName, repositoryInitParameters },
            });
          }
          return reducedMap;
        },
        new Map(),
      );

      const fireSyncProgressEvent = () => {
        if (onSyncProgressEvent) {
          onSyncProgressEvent({
            totalRepositories,
            initedRepositories,
            repositoriesSyncCompleted,
            totalLocalDocsCount,
            totalRemoteDocsCount,
            repositories: syncContextRepositories,
            syncContextRepositoriesStats,
          });
        }
      };

      // TODO: no need to run O(n) on all repositories all the time, the function need to run on all initialy and then
      // recieve the repository name that has been changed and update total counts according to it.
      const updateContextRepositoriesSyncStats = () => {
        let updatedTotalLocalDocsCount = 0;
        let upatedTotalRemoteDocsCount = 0;
        let updatedRepositoriesSyncCompleted = 0;

        repositories.forEach((repository, localDbName) => {
          const repositoryStatsExists = syncContextRepositoriesStats.has(
            localDbName,
          );
          if (repositoryStatsExists) {
            const { localDocsCount, state, remoteDocsCount } = repository;

            const repositoryStats = syncContextRepositoriesStats.get(
              localDbName,
            );

            const {
              remoteDocsCount: couchRESTRemoteDocsCountStatsValue,
            } = repositoryStats;

            const repositoryLocalDocsCountValue = localDocsCount();

            const repositoryRemoteDocsCountValue = remoteDocsCount();

            const repositoryState = state();

            /* Our original remote docs count for the repository is based on direct REST Couch fetch query for all dbs together, before the repository has been initialized.
            Now that we update the stats we check with the repository if it already has it's own value of the remote docs count
            (value it gets from pouch api query), in practice it is not anticipated large differnities between the two
            but it is more accurate and it makes the syncContext flow concise (maintainability).
           */
            const selectedRemoteDocsValue =
              repositoryRemoteDocsCountValue > 0
                ? repositoryRemoteDocsCountValue
                : couchRESTRemoteDocsCountStatsValue;

            const updatedRepositoryStats = {
              remoteDocsCount: selectedRemoteDocsValue,
              repositoryState,
              localDocsCount: repositoryLocalDocsCountValue,
            };

            syncContextRepositoriesStats.set(
              localDbName,
              updatedRepositoryStats,
            );
            updatedTotalLocalDocsCount += repositoryLocalDocsCountValue;
            upatedTotalRemoteDocsCount += upatedTotalRemoteDocsCount;
            updatedRepositoriesSyncCompleted =
              repositoryState === requiredSyncState
                ? updatedRepositoriesSyncCompleted + 1
                : updatedRepositoriesSyncCompleted;
          }
        });
        totalLocalDocsCount = updatedTotalLocalDocsCount;
        totalRemoteDocsCount = upatedTotalRemoteDocsCount;
        repositoriesSyncCompleted = updatedRepositoriesSyncCompleted;
      };

      // TODO: In case in future there would be multiple syncContexts check if there are repositories that has already been inited in the past and exclude them from the initRepositoriesBulk() method to save time
      // as the initRepositoryBulk method will delay return anyhow - for future versions only as currently there is only one syncContext hence redundant.
      const initRepositoryBulkGenerator = initRepositoryBulk({
        repositoriesInitParameters,
        bulkSize,
      });

      let repositoriesBulkYield = await initRepositoryBulkGenerator.next();

      const allDatabasePartialReplicationWaitStatePromises = [];

      do {
        const repositoriesBulkInited =
          repositoriesBulkYield && repositoriesBulkYield.value;

        if (repositoriesBulkInited) {
          v({
            msg: 'Inited repositories bulk',
            data: { repositoriesBulkSize: repositoriesBulkInited.length },
          });

          initedRepositories += repositoriesBulkInited.length;
          fireSyncProgressEvent();

          // eslint-disable-next-line no-loop-func
          repositoriesBulkInited.forEach(repository => {
            repository.on({
              eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_DATA_SYNC,
              callback: () => {
                updateContextRepositoriesSyncStats();
                fireSyncProgressEvent();
              },
              subscriber: 'groupSyncProgress',
            });

            repository.startSync();

            const repositoryWaitStatePromise = repository
              .waitState({
                state: requiredSyncState,
              })
              .then(() => {
                repositoriesSyncCompleted++;
                updateContextRepositoriesSyncStats();
                fireSyncProgressEvent();
              });

            allDatabasePartialReplicationWaitStatePromises.push(
              repositoryWaitStatePromise,
            );

            const { localDbName } = repository;
            // TODO : bulkInitRepositories returns an array - perhaps should return a map ...
            syncContextRepositories.set(localDbName, repository);
          });

          if (onBulkRepositoriesInitedEvent) {
            onBulkRepositoriesInitedEvent({ repositoriesBulkInited });
          }
          // eslint-disable-next-line no-await-in-loop
          repositoriesBulkYield = await initRepositoryBulkGenerator.next();
        }
      } while (repositoriesBulkYield && !repositoriesBulkYield.done);

      await Promise.all(allDatabasePartialReplicationWaitStatePromises);
    };

    /**
     * Check if repository exists
     * @param {string} dbName
     */
    const hasRepository = dbName => {
      const retval = repositories.has(dbName);
      return retval;
    };

    const removeRepository = dbName => {
      // TODO: this is currently being used for testing only, removal must be performed only after stop sync has
      // been called, there is also currently no use-case requiring it besdies caching testing.
      const retval = repositories.delete(dbName);
      return retval;
    };

    const allRepositories = () => {
      const iterator = repositories.values();
      const retval = Array.from(iterator); // Adds O(n) - does not matter on small numbers but keeps usage concise accross system.
      return retval;
    };

    /**
   * Entities repository applicative pattern implementation,
   * each Couchdb 'db' or rational db table or other nosql databases providers single collection maps to a single repository.
   * This PouchDB/CouchDB repository provides a single access point to a single CouchDB/PouchDB db and manages & exposes APIs for both general connectivity/syncing management and monitoring, CRUD and indexs.
   *
   * The core data storage strategy used by the repository is local PouchDB stroage which syncs pulls/push data from the central CouchDB to a local db API on-top of IndexDb
   * but it uses hybrid mode with direct on-line connectivity to CouchDB to optimize and enhance user experience while PouchDB syncs.
   * Hence, the repository works in two modes :
   * Hybrid - PouchDB sync to local db + Couchdb on-line direct connectivity
   * Offline - PouchDB only offline api.
   * Please view mode param below for detailed states.
   *
   * As part of its connectivity management the repository implements monitoring and 'retry' behavior both for failed on-line calls due to network issues
   * and both PouchDB for offline db syncing
   *
   * @param dbName db name to fetch data from or sync in-front
   * @param pouchDbAPI a shared reference to pouchDB
   * @param couchDbAPI a shared reference to couchDb
   * @param defaultReplicationConfiguration : {
      partitionCount,
      priority,
      partitionPriorityIncreaseMode:'incremental'||'static',
      partitionPriorityIncrease,
     };
   * @param priority db sync priority compared to other db's, 0 is lowest.
   * @param sharedContext - A shared context object enabling limiting number of parallel syncs occuring in parallel by priortiy
   * @param mode - Mode/Data State (per DB) | Initial Sync - Empty Data | Initial Sync Completed | Short Term Stale Data | Long Term Stale Data
   *              Online - CouchDB | CRUD Enabled | CRUD Disabled | CRUD Disabled | CRUD Enabled - on dbs the user did not modify, CRUD Disabled on dbs the user did modify
   *              PouchDB (offline / online when available)| CRUD Disabled | CRUD Enabled + Periodic syncs when online available | CRUD Enabled + Periodic syncs when online available + Display warning of stale data | Configurable CRUD (admin) + Re-sync  + Display warning of stale data & resyncing
   * @param storageType indexdb | memory | filesystem
   * @returns
   *
   */
    // TODO: force strict local db name compared to remote server address - e.g local : 'core' , remote : http://couch_url:5984/core - relying on same name as convention in code.

    /**
     * @function
     * @param {{localDbName:string,remoteDBServerAddress:string,defaultReplicationConfiguration,storageType:string}}
     */
    const repository = ({
      localDbName,
      remoteDBServerAddress,
      defaultReplicationConfiguration,
      storageType,
    } = {}) => {
      const repositoryId = `repository/${localDbName}`;
      // eslint-disable-next-line no-shadow
      const { d, e, v, a } = logger({
        source: 'repository',
        identifier: localDbName,
      });

      const reqCxt = request => {
        const { requestId = 'empty', syncTaskId = 'empty' } = request || {};
        return { requestId, syncTaskId };
      };

      const syncTasksMap = new Map();
      const syncRequestsMap = new Map();
      const syncDataTypeQueuedRequestsSet = new Set();
      // TODO: read from configuration.
      const initedDefaultReplicationConfigurationValues = {
        partitionCount: 1,
        priority: 1000,
        partitionPriorityIncreaseMode: 'incremental',
        partitionPriorityIncrease: 5,
        syncDataType: SYNC_DATA_TYPE.APPLICATIVE_INIT,
        ...defaultReplicationConfiguration,
      };
      const {
        partitionCount: defaultPartitionCount,
        priority: defaultPriority,
        // eslint-disable-next-line no-unused-vars
        partitionPriorityIncreaseMode: defaultPartitionPriorityIncreaseMode,
        // eslint-disable-next-line no-unused-vars
        partitionPriorityIncrease: defaultPartitionPriorityIncrease,
      } = initedDefaultReplicationConfigurationValues;

      let currentReplicationRequest;

      /**
       * Either retrives state from cache or inits variables with initial values.
       * @returns
       */
      const initRepositorySyncState = () => {
        const repositoryCacheJSON = localStorage.getItem(repositoryId);
        v({
          msg: 'Loading repository state cache',
          data: { repositoryCacheStateJSON: repositoryCacheJSON },
        });
        const repositoryCache =
          repositoryCacheJSON && JSON.parse(repositoryCacheJSON);
        const { repositoryStatesMemory = [] } = repositoryCache || {};

        const { length: statesCount } = repositoryStatesMemory;

        const emptySyncState = {
          repositorySyncState: REPOSITORY_STATE.REPOSITORY_EMPTY,
          repositorySyncStateTime: 0,
        };

        // When initialized, we try to load the repository state from cache, in case it exists the repository would be in it's last cached state,
        // in case it does not, there are two options, if were online - we would be in

        const {
          repositorySyncState: cachedRepositorySyncState,
          repositorySyncStateTime: cachedRepositorySyncStateTime,
          localDocsCount: cachedLocalDocsCount,
          remoteDocsCount: cachedRemoteDocsCount,
        } =
          statesCount && statesCount >= 1
            ? repositoryStatesMemory[statesCount - 1]
            : emptySyncState;
        // TODO: Check if we need to store local and remote docs count in the cache to increase loading performance - but
        // at any case it might not be helpful as as long pouch has not been inited no data would be available.

        return {
          repositorySyncState: cachedRepositorySyncState || 1,
          repositorySyncStateTime: cachedRepositorySyncStateTime || 0,
          repositoryStatesMemory,
          localDocsCount: cachedLocalDocsCount || 0,
          remoteDocsCount: cachedRemoteDocsCount || 0,
          dataChangesCount: 0,
        };
      };

      /**
       * dataChangesCount : currently the purpose of this var is to enable classes/components using the repository to be notified whenever some change
       * has occured to the data, either locally or remotely,
       * currently we will use a simply number to count data changes and not hold the sequence number used by couchDb remote server as we
       * still need to determine if the sequence number is being updated also locally before remote sync and that it supports all use-cases
       * the local data change var is controlled by us and increased for both local POST/PUT or remote sync fetch.
       */
      let {
        repositorySyncState,
        repositorySyncStateTime,
        localDocsCount,
        remoteDocsCount,
        dataChangesCount,
      } = initRepositorySyncState();

      // eslint-disable-next-line prefer-const
      let syncPullCount = 0;
      // eslint-disable-next-line prefer-const
      let syncPushCount = 0;

      // As the sync manager fetches remote docs counts for all repositories, the repository will take
      // the last updated remote doc count from the sync manager, in case there is no value - it will
      // use it's own cache.
      const syncManagerRemoteDocsCount = remoteServerDatabasesDocsCountMap.get(
        localDbName,
      );
      if (syncManagerRemoteDocsCount !== 0) {
        remoteDocsCount = syncManagerRemoteDocsCount;
      }

      let repositoryActivity = REPOSITORY_ACTIVITY.REPOSITORY_NO_ACTIVITY;
      let repositoryActivityTime = Date.now();

      const updateRepositoryStatesMemory = () => {
        const state = {
          repositorySyncState,
          repositorySyncStateTime,
          repositoryActivity,
          repositoryActivityTime,
          currentReplicationRequest,
          localDocsCount,
          remoteDocsCount,
        };
        // Oringally stored in cache an array of N last states but was redundant, currently stores "array" with only last state.
        const repositoryStatesMemoryCache = [];

        repositoryStatesMemoryCache.push(state);
        const repositoryCache = {
          repositoryStatesMemory: repositoryStatesMemoryCache,
        };
        const repositoryCacheJSON = JSON.stringify(repositoryCache);
        localStorage.setItem(repositoryId, repositoryCacheJSON);
      };

      // TODO : combine sync state and activity to a single set method.

      const setRepositoryActivity = ({ request, activity }) => {
        d({
          context: reqCxt(request),
          msg: 'Repository activity change request',
          data: { currentActivity: repositoryActivity, newActivity: activity },
        });

        if (repositoryActivity === activity) {
          return;
        }

        repositoryActivity = activity;
        repositoryActivityTime = Date.now();

        updateRepositoryStatesMemory();

        dispatchEvent({
          eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_ACTIVITY_CHANGED,
          data: { activity },
        });
      };

      const setRepositorySyncState = ({ request, state }) => {
        d({
          context: reqCxt(request),
          msg: 'Repository state change request',
          data: { currentState: repositorySyncState, state },
        });
        if (repositorySyncState === state) {
          return;
        }

        repositorySyncState = state;
        repositorySyncStateTime = Date.now();

        updateRepositoryStatesMemory();
        dispatchEvent({
          eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_STATE_CHANGED,
          data: { state, localDbName },
        });
      };

      d({
        msg: 'initial repository state',
        data: { repositoryId, repositoryState: repositorySyncState },
      });
      // TODO: need to check for previous IndexDB data and if recent less than TBD
      // set as unsynced or stale.

      const REPLICATION_TIMEOUT = 1000 * 60 * 15; // Configurable TBD

      const repositoryEventsEmitter = eventsEmitter({
        eventsEmitterName: repositoryId,
        eventTypes: REPOSITORY_EVENT_TYPES,
      });

      // eslint-disable-next-line no-shadow
      const on = ({ subscriber, callback, eventType }) => {
        if (!REPOSITORY_EVENT_TYPES[eventType]) {
          throw new Error();
        }
        return repositoryEventsEmitter.on({ eventType, subscriber, callback });
      };

      const dispatchEvent = ({ eventType, data }) => {
        try {
          repositoryEventsEmitter.dispatch({
            dispatcher: repositoryId,
            eventType,
            data,
          });
        } catch (error) {
          const eventData = data;
          e({
            msg: 'Failed dipsatching event',
            data: { eventType, eventData },
          });
          throw error;
        }
      };

      d({
        msg: 'Creating repository',
        data: {
          localDbName,
          remoteDBServerAddress,
          priority: defaultPriority,
          storageType,
          REPLICATION_TIMEOUT,
          partitionCount: defaultPartitionCount,
        },
      });

      const nextReplicationRequest = ({ completedRequest }) => {
        d({
          context: reqCxt(completedRequest),
          msg: 'Generating next replication request',
          data: { completedRequest },
        });
        const {
          requestId,
          partitionIndex,
          partitionCount,
          priority,
          partitionPriorityIncrease,
          partitionPriorityIncreaseMode,
          syncTaskId,
        } = completedRequest;

        // Check if type is incremental or static
        const nextPriority =
          partitionPriorityIncreaseMode === 'incremental'
            ? priority + partitionPriorityIncrease
            : priority;
        const newRequestId = uuid();
        // partition count - 1 counting based, partition index - 0 counting based.
        const nextReplicationRequestInstance =
          partitionIndex + 1 < partitionCount
            ? {
                ...completedRequest,
                partitionIndex: partitionIndex + 1,
                requestId: newRequestId,
                priority: nextPriority,
                replicationActive: false, // This is a request - hence replication is yet ot be active.
                stage: REQUEST_STAGE.REQUEST_INIT,
              }
            : {
                ...completedRequest,
                direction: 'push',
                requestId: newRequestId,
                priority: nextPriority,
                partitionCount: undefined,
                partitionIndex: undefined,
                replicationActive: false, // This is a request - hence replication is yet ot be active.
                stage: REQUEST_STAGE.REQUEST_INIT,
              };

        syncRequestsMap.set(newRequestId, nextReplicationRequestInstance);
        const syncTask = syncTasksMap.get(syncTaskId);
        syncTask.requests.push(nextReplicationRequestInstance);

        a({
          type: STATE_CHANGE,
          data: {
            completedRequest,
            nextReplicationRequest: nextReplicationRequestInstance,
          },
          action: 'NEXT_REPLICATION_REQUEST',
        });

        updateRepositoryStatesMemory();

        const context = `${syncTaskId}/${requestId}/${newRequestId}`;

        d({
          context,
          msg: 'Next replication request generated for sync task.',
          data: {
            syncTaskId,
            completedRequest,
            nextReplicationRequestInstance,
          },
        });
        return nextReplicationRequestInstance;
      };

      const updateRequest = ({ requestId, request, updates }) => {
        const getRequest = syncRequestsMap.has(requestId)
          ? syncRequestsMap.get(requestId)
          : undefined;

        const computedRequest = getRequest || request || {};
        const computedUpdates = updates || {};

        const updatedRequest = { ...computedRequest, ...computedUpdates };

        const { requestId: updateRequestId } = updatedRequest;
        if (updateRequestId) {
          syncRequestsMap.set(updateRequestId, updatedRequest);
        }

        return updatedRequest;
      };

      const createRequestStageUpdates = (request, stage) => {
        const { stages = [] } = request;

        const stageExists = REQUEST_STAGE_SET.has(stage);

        if (!stageExists) {
          e({ msg: 'stage does not exists', data: { request, stage } });
        }

        const now = new Date().toUTCString();

        const stageTimestamped = { stage, stageTimestamp: now };

        const requestStagesUpdate = {
          stages: [...stages, stageTimestamped],
          stage,
          stageTimestamp: now,
        };

        return requestStagesUpdate;
      };

      // TODO : add requestId to pouch so it will store to what request id it relates.
      const onPouchDBCompleted = eventData => {
        v({ msg: 'Received pouch complete event', data: eventData });

        const { direction, replicaitonIdentifier } = eventData;
        const completedRequest = syncRequestsMap.has(replicaitonIdentifier)
          ? syncRequestsMap.get(replicaitonIdentifier)
          : undefined;

        if (completedRequest) {
          d({
            context: reqCxt(completedRequest),
            msg: `Partition sync completed`,
            data: { completedRequest },
          });
        } else {
          e({
            msg:
              'Completed pouch request unfound in requests map - critical error',
            data: eventData,
          });
          return;
        }

        if (!loggedIn) {
          a({
            type: OPERATION_CANCEL,
            data: { completedRequest, loggedIn },
            message: 'sync discontinue due to logout',
          });
          return;
        }

        handleReplicationOver(completedRequest);
        if (direction === 'pull') {
          a({
            type: STATE_CHANGE,
            data: {
              request: completedRequest,
              state: REPOSITORY_STATE.REPOSITORY_PARTIALLY_SYNCED,
            },
            action: 'REPOSITORY_STATE_CHANGE',
          });

          setRepositorySyncState({
            state: REPOSITORY_STATE.REPOSITORY_PARTIALLY_SYNCED,
          });

          const nextReplicationRequestInstance = nextReplicationRequest({
            completedRequest,
          });

          dispatchNextPartitionReplicationRequestEvent(
            nextReplicationRequestInstance,
          );
        } else if (direction === 'push') {
          a({
            type: OPERATION_END,
            data: {
              completedRequest,
            },
            action: 'SYNC_END',
          });
          // The last request for each replications bulk is 'push', if that was the last request being completed we fire the replication completed event.
          setRepositorySyncState({
            state: REPOSITORY_STATE.REPOSITORY_FULLY_SYNCED,
          });

          eventsHub.dispatch({
            source: repositoryId,
            type: SYNC_EVENT_TYPES.COMPLETED_DB_REPLICATION_EVENT,
            target: 'scheduler',
          });
          a({
            type: STATE_CHANGE,
            data: {
              request: completedRequest,
              state: REPOSITORY_STATE.REPOSITORY_FULLY_SYNCED,
            },
            action: 'REPOSITORY_STATE_CHANGE',
          });
          repeatRequestCheck(completedRequest);
          currentReplicationRequest = undefined;
        }
        // TODO : re-enable push replication.
        // We examine if there is still a 'next approved request' and start the sync for it - either pull/push
        // nextPushApprovedReplicationRequestId &&
        //   startReplication(nextPushApprovedReplicationRequestId);
      };

      const repeatRequestCheck = request => {
        const { repeat, syncTaskId } = request;

        const syncTask = syncTasksMap.has(syncTaskId)
          ? syncTasksMap.get(syncTaskId)
          : undefined;

        const { canceled } = syncTask;

        const repeatComputed =
          (isFunction(repeat) ? repeat({ ...request }) : repeat) && !canceled;

        v({
          context: reqCxt(request),
          msg: 'Repeat check',
          data: { request, repeat: repeatComputed },
        });

        if (repeatComputed) {
          resync(request);
        }
      };

      const resync = replicationRequest => {
        if (!replicationRequest) {
          return;
        }
        // TODO : simplify, add the the scheduler a functionality to resync after interval, not to add additional timer here.
        const { repeatInterval } = replicationRequest;
        const compuatedRepeatInterval = repeatInterval || 1000 * 60 * 5;
        d({
          context: reqCxt(replicationRequest),
          msg: `Will perform a resync in ${compuatedRepeatInterval} minutes`,
          data: { replicationRequest },
        });
        a({
          type: STATE_CHANGE,
          msg: `Will resync in ${compuatedRepeatInterval} minutes`,
          data: { replicationRequest },
          action: 'RESYNC',
        });
        setTimeout(() => {
          // TODO: when resyncing need to lower priority to make sure if there are other repositories which has yet to be synced better proirity
          // or resynced only a while ago (e.g did not push their changes in a while) better priority.
          d({ context: reqCxt(replicationRequest), msg: 'Resync begins' });
          // The getResyncRequestConfig enables us to define and determine parametrers of next resync replication request, based on previous one or in general. This is required to begin with APPLICATION_INIT sync type with top prioirty and transfrom it to APPLICATION sync type after single sync for standard priority.
          const { getResyncRequestConfig } = replicationRequest;
          const resyncReplicaitonRequest = getResyncRequestConfig
            ? getResyncRequestConfig({ ...replicationRequest })
            : replicationRequest;
          startSync({ config: resyncReplicaitonRequest });
        }, compuatedRepeatInterval);
      };

      const onPouchDBError = error => {
        // In case of an error, we store the failed replication request id, first clear it from the scheduler
        // same as completed request id using the handleReplicationOver method but we then perform
        // a retry by initiating a start sync request again with the same request id
        // TODO: limit amount of retries and add fault state.
        // let retryRequestId = currentActiveReplicationRequestId;
        e({ msg: 'PouchApi error', error });

        const { replicationIdentifier = undefined } = error || {};

        const errorRequest = syncRequestsMap.has(replicationIdentifier)
          ? syncRequestsMap.get(replicationIdentifier)
          : undefined;

        setRepositorySyncState({ state: REPOSITORY_STATE.REPOSITORY_FAULT });

        setRepositoryActivity({
          request: errorRequest,
          activity: REPOSITORY_ACTIVITY.REPOSITORY_NO_ACTIVITY,
        });

        if (!errorRequest) {
          return;
        }
        if (!loggedIn) {
          a({
            type: OPERATION_CANCEL,
            data: { errorRequest, loggedIn },
            message: 'sync discontinue due to logout',
          });
          e({
            context: reqCxt(errorRequest),
            msg: 'User logout, request error',
          });
          return;
        }

        e({ context: reqCxt(errorRequest), msg: 'Replicaiton request error' });

        const updatedRequestStage = createRequestStageUpdates(
          errorRequest,
          REQUEST_STAGE.REQUEST_SYNC_ERROR,
        );

        const updatedErrorRequest = updateRequest({
          request: errorRequest,
          updatedRequestStage,
        });

        resync(updatedErrorRequest);
      };

      const onPouchDBPaused = reason => {
        d({ msg: 'PouchApi paused', error: reason });
        // TODO : verify different repo options.
      };

      const onPouchDBChange = changeEventArgs => {
        v({ msg: `on pouch db change` });
        const {
          ok,
          start_time,
          docs_read,
          docs_written,
          doc_write_failures,
          errors,
          replicationIdentifier,
        } =
          changeEventArgs && changeEventArgs.logInfo
            ? changeEventArgs.logInfo
            : {
                ok,
                start_time,
                docs_read: 0,
                docs_written: 0,
                doc_write_failures: 0,
                errors,
              };

        if (docs_written > 0) {
          const replicationRequest = syncRequestsMap.has(replicationIdentifier)
            ? syncRequestsMap.get(replicationIdentifier)
            : undefined;

          d({
            context: reqCxt(replicationRequest),
            msg: 'PouchDB change event',
            data: { changeEventArgs },
          });

          dataChangeHandler({
            ok,
            start_time,
            docs_read,
            docs_written,
            doc_write_failures,
            errors,
          });
        }
      };

      const onPouchDBDenied = reason => {
        e({ msg: 'PouchApi denied', error: reason });
      };

      const pouchApi = pouchDbApiInstance({
        localDbName,
        remoteDBServerAddress,
        events: {
          onPouchDBCompleted,
          onPouchDBError,
          onPouchDBPaused,
          onPouchDBChange,
          onPouchDBDenied,
        },
        replicationTimeout: REPLICATION_TIMEOUT,
        pouchAdapter: storageType,
        filter: {},
      });

      // Afer pouch db is inited it is available to be locally queried even before any sync called,
      // we fire report our sync stats from the cache through the data sync event. In case of new repository all would be 0.
      // dispatchEvent({
      //   eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_DATA_SYNC,
      //   data: {
      //     localDbName,
      //     localDocsCount,
      //     previousLocalDocsCount: localDocsCount,
      //     remoteDocsCount,
      //     previousRemoteDocsCount: remoteDocsCount,
      //     dataChangesCount: 0,
      //     previousDataChangesCount: 0,
      //     repositorySyncState,
      //     docsWritten: 0,
      //     docsRead: 0,
      //     docsWriteErrors: 0,
      //     errors: undefined,
      //   },
      // });

      const dataChangeHandler = async ({
        docs_written = 0,
        docs_read = 0,
        docs_write_errors = 0,
        errors = false,
      } = {}) => {
        try {
          const previousDataChangesCount = dataChangesCount;
          const previousLocalDocsCount = localDocsCount;
          const previousRemoteDocsCount = remoteDocsCount;
          // console.log(args);
          // const {docs_written,docs_read,docs_write_errors,errors} = args ? args : {docs_written:0,docs_read:0,docs_write_errors:0,errors:false};
          if (docs_written > 0 && docs_read > 0) {
            dataChangesCount++;
          }

          /**
           * We check for info upon each new updateDocsStatsCount as it can occur that while we
           * sync and fetch additional docs remote -> locally more docs are added remote by another client
           * and in case of bulk writes our stats would be soon consdierable inaccurate.
           */
          v({ msg: 'Syncing docs count' });

          // TODO : important, once in a while the remote docs count which is kept being taken from the cache can become stale, needs
          // to take the new one, need to validate offline mode.
          const info = await pouchApi.info({ local: true });
          const {
            local: { doc_count: infoLocalDocsCount },
          } = info;

          v({ msg: 'Info docs count', data: { info } });

          localDocsCount =
            infoLocalDocsCount !== -1 ? infoLocalDocsCount : localDocsCount;

          if (remoteDocsCount === 0) {
            const {
              remoteDocsCount: couchRESTRemoteDocsCount,
            } = repositoriesStats.has(localDbName)
              ? repositoriesStats.get(localDbName)
              : { remoteDocsCount: -1 };
            remoteDocsCount =
              couchRESTRemoteDocsCount !== -1 ? couchRESTRemoteDocsCount : 0;
            // TODO : important, once in a while the remote docs count which is kept being taken from the cache can become stale, needs
            // to take the new one, need to validate offline mode.
          }

          updateRepositoryStatesMemory();

          dispatchEvent({
            eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_DATA_SYNC,
            data: {
              localDbName,
              localDocsCount,
              previousLocalDocsCount,
              remoteDocsCount,
              previousRemoteDocsCount,
              dataChangesCount,
              previousDataChangesCount,
              repositorySyncState,
              syncPullCount,
              syncPushCount,
              docsWritten: docs_written,
              docsRead: docs_read,
              docsWriteErrors: docs_write_errors,
              errors,
            },
          });
        } catch (error) {
          e({ msg: 'Failed to update docs count stats.', error });
        }
      };

      try {
        dataChangeHandler();
      } catch (dataChangeHandlerError) {
        e({
          msg: 'Initial dataChangeHandler error',
          error: dataChangeHandlerError,
        });
      }

      const eventsHubEventsHandler = event => {
        const {
          target,
          source,
          type,
          data: { repositoryId: eventRepositoryId, requestId },
        } = event;
        if (
          source !== 'scheduler' ||
          repositoryId !== eventRepositoryId ||
          target !== repositoryId
        ) {
          return;
        }
        d({ msg: 'Event received', data: { event } });
        switch (type) {
          case SYNC_EVENT_TYPES.START_PARTITION_REPLICATION_REQUEST_ACK_EVENT: {
            startSyncRequestApprovedEventHandler(requestId);
            break;
          }
          case SYNC_EVENT_TYPES.HALT_REPLICATION_REQUEST_EVENT: {
            haltSyncEventHandler();
            break;
          }
          case SYNC_EVENT_TYPES.START_PARTITION_REPLICATION_PENDING_EVENT: {
            requestPendingEventHandler(requestId);
            break;
          }
          case SYNC_EVENT_TYPES.SYNC_DATA_TYPE_REQUEST_ALREADY_EXISTS_IN_QUEUE: {
            setRequestDeclined(requestId);
            break;
          }
          case SYNC_EVENT_TYPES.CANCEL_REQUEST_ACK_EVENT_:
            cancelRequestAckEventHandler(requestId);
            break;
          default:
            break;
        }
      };

      const requestPendingEventHandler = requestId => {
        const request = syncRequestsMap.get(requestId);

        if (request) {
          const updateRequestStage = createRequestStageUpdates(
            request,
            REQUEST_STAGE.REQUEST_SYNC_PENDING,
          );
          const updatedRequest = updateRequest(request, updateRequestStage);
          a({
            type: STATE_CHANGE,
            data: { updatedRequest },
            action: 'SYNC_PENDING',
          });
          setRepositoryActivity({
            request: updatedRequest,
            activity: REPOSITORY_ACTIVITY.REPOSITORY_SYNC_PENDING,
          });
        }
      };

      const setRequestDeclined = requestId => {
        const request = syncRequestsMap.has(requestId)
          ? syncRequestsMap.get(requestId)
          : undefined;

        if (request) {
          const updatedRequestStage = createRequestStageUpdates(
            request,
            REQUEST_STAGE.REQUEST_SYNC_DECLINE,
          );
          updateRequest({ request, updates: updatedRequestStage });

          // If a request has been declined by the queue, it means a sync request for same repository
          // is already in the queue, this can occur for cases such as user performed edit and initated
          // a push request, or when realtime request occurs when a background request is alrady
          // in the queue.
          repeatRequestCheck(request);
        }
      };

      const logout = () => {
        syncRequestsMap.clear();
        syncDataTypeQueuedRequestsSet.clear();
        syncTasksMap.clear();
        pouchApi.cancelReplication();
      };

      eventsHub.listen({
        listenerName: repositoryId,
        callback: eventsHubEventsHandler,
      });

      /**
       * @param {*} priorityOverride overrides the default repository's priority defined in the createRepository()
       */
      const startSync = async ({ config } = {}) => {
        try {
          // if (currentReplicationRequest) {
          //   return; // TODO: check if should throw n error
          // }

          // TODO : discard two replication requests of same data sync type.

          const username = localStorage.getItem('username');
          // TODO: username must not be received directly from localStorage but received from authProvider - will be performed.
          const { ok: syncAuthCheck } = await syncApicheckSyncAuth({
            username,
            databaseName: localDbName,
          });

          if (!syncAuthCheck) {
            e({ msg: 'Sync auth checked failed, sync cancled' });
            return;
          }

          const replicationConfiguration = {
            ...initedDefaultReplicationConfigurationValues,
            ...config,
          };

          const { syncDataType } = replicationConfiguration;

          const syncDataTypeRepositoryQueuedRequestSetKey = {
            repositoryId,
            syncDataType,
          };

          // We store queued requests in a set and prevent double queued request, we do permit one active sync request and one queue request - as the queued request will sync all changes so far anyhow, no need for a duplicate (we remove that record in the replicate(request) begin method)
          const syncDataTypeRequestQueued = syncDataTypeQueuedRequestsSet.has(
            syncDataTypeRepositoryQueuedRequestSetKey,
          );

          if (syncDataTypeRequestQueued) {
            return;
          }

          // Currently, each user will have it's own filtering doc deployed in the db - this will redundant the requirement to deploy
          // filtering docs from some admin panel + script, TODO : the filtering doc name should be filteringDoc_<userId>
          // this will enable easy extention to further dbs and upgrade of versions without deprecating previous versions, as filtering is done by docs with JS,
          // no concern of too many 'stored procedures' as with traditional SQL Dbs.
          // await deployDesignDocument(localDbName);
          // nextPullReplicationRequestId and nextPushReplicationRequestId are used as flags that a replication process
          // is underway, either it is scheduled or it is currently active by PouchDB, the vars are cleared
          // upon completion or error,
          // TODO : we want to enable multiple reads before a single push for cases the user needs to push a large
          // file on the one hand but wants to continue and receive updates on the other - but we want to prevent
          // situtation data is not pushed because user constantly refreshes the data, pull & push together especially
          // of large files can block both processes in case reads wont occur until large attachment is pushed and
          // pouch/couch perform calculations - will review.
          // TODO: we currently override partition count config based on repo state.
          const repositoryStatePartitionCount =
            repositorySyncState === REPOSITORY_STATE.REPOSITORY_EMPTY ||
            repositorySyncState === REPOSITORY_STATE.REPOSITORY_STALE
              ? defaultPartitionCount
              : 1; // In case we perform initial sync we split the data which might be a large dataset into partitions, incase it is a continues sync we will fetch it as a single partition.

          /**
           * On resync a config will be passed with previous synctask,
           * additional requests will be added to same synctask
           * TODO : add additional 'sync iteration' field to seperate
           * replications on same sync list of different resyncs / sync iterations.
           */
          const { syncTaskId: existingSyncTaskId } = config || {
            syncTaskId: undefined,
          };

          const syncTaskId = existingSyncTaskId || uuid();

          const requestId = uuid();

          const replicationRequest = {
            ...replicationConfiguration,
            direction: 'pull',
            partitionIndex: 0,
            requestId,
            syncTaskId,
            partitionCount: repositoryStatePartitionCount,
            replicationActive: false,
            repositoryId,
            localDbName,
          };

          const requestStagesUpdate = createRequestStageUpdates(
            replicationRequest,
            REQUEST_STAGE.REQUEST_INIT,
          );

          const initedReplicationRequest = {
            ...replicationRequest,
            ...requestStagesUpdate,
          };

          syncRequestsMap.set(requestId, initedReplicationRequest);

          const resync = syncTasksMap.has(syncTaskId);
          const action = resync ? 'RESYNC_BEGIN' : 'SYNC_BEGIN';

          const now = new Date().toUTCString();
          const syncTask = syncTasksMap.has(syncTaskId)
            ? syncTasksMap.get(syncTaskId)
            : {
                syncTaskId,
                requests: [],
                canceled: false,
                creationTime: now,
                repositoryId,
              };

          syncTask.requests.push(initedReplicationRequest);

          syncTasksMap.set(syncTaskId, syncTask);

          a({
            type: OPERATION_BEGIN,
            action,
            msg: action,
            data: {
              replicationRequest,
            },
          });
          dispatchNextPartitionReplicationRequestEvent(
            initedReplicationRequest,
          );
          const retval = { ...initedReplicationRequest }; // TODO: remove unrequired fields.
          // eslint-disable-next-line consistent-return
          return retval;
        } catch (error) {
          e({ msg: 'Start sync failed', error });
          setRepositorySyncState({ state: REPOSITORY_STATE.REPOSITORY_FAULT });
        }
      };

      const cancelSync = ({ syncTaskId }) => {
        const syncTask = syncTasksMap.has(syncTaskId)
          ? syncTasksMap.get(syncTaskId)
          : undefined;

        if (!syncTask) {
          e({ msg: 'sync task unfound', data: { syncTaskId } });
        }
        syncTask.canceled = true;

        const { requests } = syncTask;
        requests.forEach(request => {
          // eslint-disable-next-line no-unused-vars
          const { requestId, syncDataType } = request;
          const replicationActive =
            request.stage === REQUEST_STAGE.REQUEST_SYNC_ACTIVE;
          if (request && !replicationActive) {
            const { syncDataType } = request;
            a({ type: OPERATION_CANCEL, data: { request } });
            eventsHub.dispatch({
              source: repositoryId,
              type: SYNC_EVENT_TYPES.CANCEL_REQUEST_EVENT,
              target: 'scheduler',
              data: {
                requestId,
                syncDataType,
              },
            });
          } else if (replicationActive) {
            v({
              msg: 'Request active,can not cancel an active request',
              data: { request },
            });
          }
        });
      };

      const cancelRequestAckEventHandler = ({ requestId }) => {
        const request =
          syncRequestsMap.has(requestId) && syncRequestsMap.get(requestId);
        if (request && !request.replicationActive) {
          request.canceled = true;
        }
      };

      const startSyncRequestApprovedEventHandler = requestId => {
        const request = syncRequestsMap.get(requestId);
        a({
          type: EVENT_RECEIVED,
          data: { request },
          action: 'START_SYNC_REQUEST_EVENT_RECEIVED',
        });

        d({
          context: reqCxt(request),
          msg: 'Start sync request approved',
          data: { request },
        });

        replicate(requestId);
      };

      const invalidReplicateRequestStage = stage => {
        const invalid =
          stage === REQUEST_STAGE.REQUEST_SYNC_ACTIVE ||
          stage === REQUEST_STAGE.REQUEST_SYNC_CANCEL ||
          stage === REQUEST_STAGE.REQUEST_SYNC_COMPLETE ||
          stage === REQUEST_STAGE.REQUEST_SYNC_INTERRUPT;
        return invalid;
      };

      const replicate = requestId => {
        const requestExists = syncRequestsMap.has(requestId);

        if (!requestExists) {
          e({
            msg: 'Request does not exists',
            data: { requestId },
            throwError: true,
          });
        }

        let replicationRequest = syncRequestsMap.get(requestId);

        if (!loggedIn) {
          d({
            context: reqCxt(replicationRequest),
            msg: 'User logout, dropping request',
            data: { replicationRequest },
          });

          const requestLogoutStageUpdate = createRequestStageUpdates(
            replicationRequest,
            REQUEST_STAGE.REQUEST_SYNC_LOGOUT,
          );

          replicationRequest = updateRequest({
            request: replicationRequest,
            updates: requestLogoutStageUpdate,
          });

          const requestSyncDropUpdate = createRequestStageUpdates(
            replicationRequest,
            REQUEST_STAGE.REQUEST_SYNC_DROP,
          );

          replicationRequest = updateRequest({
            request: replicationRequest,
            updates: requestSyncDropUpdate,
          });

          const { syncDataType } = replicationRequest;

          eventsHub.dispatch({
            source: repositoryId,
            type: SYNC_EVENT_TYPES.REQUEST_DROP_EVENT,
            target: 'scheduler',
            data: {
              repositoryId,
              requestId,
              syncDataType,
            },
          });

          return;
        }

        // IMPORTANT: though the scheduler makes sure no double sync requests are dispatched to a repository nonetheless for resliancy we perform another validation on the repository level as well.
        if (currentReplicationRequest) {
          d({
            context: reqCxt(replicationRequest),
            msg:
              'Another request sync already active, dropping and repeating if configured',
            data: { replicationRequest },
          });
          const requestDropUpdate = createRequestStageUpdates(
            replicationRequest,
            REQUEST_STAGE.REQUEST_SYNC_DROP,
          );

          replicationRequest = updateRequest({
            request: replicationRequest,
            updates: requestDropUpdate,
          });

          const { syncDataType } = replicationRequest;

          eventsHub.dispatch({
            source: repositoryId,
            type: SYNC_EVENT_TYPES.REQUEST_DROP_EVENT,
            target: 'scheduler',
            data: {
              repositoryId,
              requestId,
              syncDataType,
            },
          });
          // Though this request has been dropped, if it is configured as repeatable, we will try to repeat it on next time slot, in case of no duplication it will be performed.
          repeatRequestCheck(replicationRequest);
          return;
        }

        try {
          const {
            direction,
            partitionIndex,
            partitionCount,
            syncDataType,
            stage,
          } = replicationRequest;

          const syncDataTypeQueuedRequestsSetHas = syncDataTypeQueuedRequestsSet.has(
            { repositoryId, syncDataType },
          );
          if (syncDataTypeQueuedRequestsSetHas) {
            syncDataTypeQueuedRequestsSet.delete({
              repositoryId,
              syncDataType,
            });
          }

          const invalidRequestReplicationStageCheck = invalidReplicateRequestStage(
            stage,
          );

          if (invalidRequestReplicationStageCheck) {
            e({
              context: reqCxt(replicationRequest),
              msg: 'Invalid replication stage',
              data: { replicationRequest },
              throwError: true,
            });
          }
          const requestStageUpdate = createRequestStageUpdates(
            replicationRequest,
            REQUEST_STAGE.REQUEST_SYNC_ACTIVE,
          );
          const activeReplicationRequest = updateRequest({
            request: replicationRequest,
            updates: requestStageUpdate,
          });

          currentReplicationRequest = activeReplicationRequest;

          // We verify the request id to begin replicating for is the one defined as the next one,
          // it is important to keep in mind multiple start sync and replicate calls can be made
          // in use cases where the user switches between screens back and forth- this is for basic validation.
          if (direction === 'pull') {
            if (partitionIndex < partitionCount) {
              a({
                type: OPERATION_BEGIN,
                data: { request: activeReplicationRequest },
                action: 'PARTITION_PULL_BEGIN',
              });
              pouchApi.replicate({
                direction: 'pull',
                replicaitonIdentifier: requestId,
              });
              // pouchApi.replicate({
              //   direction: 'pull',
              //   filter: {
              //     filterName: 'partitionFilterDesignDocument/partitionFilter',
              //     queryParams: {
              //       partitionCount: partitionCount,
              //       selectedPartition: partitionIndex,
              //       idParitionDigitsCount: 3,
              //     },
              //   },
              // });
            }
          } else {
            a({
              type: OPERATION_BEGIN,
              data: { request: activeReplicationRequest },
              action: 'REPLICATION_PUSH_BEGIN',
            });
            pouchApi.replicate({
              direction: 'push',
              replicaitonIdentifier: requestId,
            });
          }

          if (repositoryActivity !== REPOSITORY_ACTIVITY.REPOSITORY_SYNCING) {
            setRepositoryActivity({
              request: repeatRequestCheck,
              activity: REPOSITORY_ACTIVITY.REPOSITORY_SYNCING,
            });
          }
        } catch (error) {
          e({
            throwError: true,
            data: { error, replicationRequest },
            msg: error,
          });
        }
      };

      /**
       * TODO:  Review if halt is required.
       */
      const haltSyncEventHandler = () => {
        // pouchApi.halt();
        // TODO : fire halt performed once pouch fires event.
        // Need to register to pouchdb cancel completed event and fire HALT_ACK when it did.
      };

      const dispatchNextPartitionReplicationRequestEvent = replicationRequest => {
        const {
          priority,
          requestId,
          syncTaskId,
          syncDataType,
        } = replicationRequest;

        a({
          type: EVENT_FIRE,
          target: 'scheduler',
          data: {
            replicationRequest,
          },
          msg: `Dispatching replication request`,
          action: 'REPLICATION_REQUEST_EVENT',
        });

        eventsHub.dispatch({
          source: repositoryId,
          type: SYNC_EVENT_TYPES.START_PARTITION_REPLICATION_REQUEST_EVENT,
          target: 'scheduler',
          data: {
            priority,
            repositoryId,
            requestId,
            syncTaskId,
            syncDataType,
          },
        });
      };

      const handleReplicationOver = completedRequest => {
        setRepositoryActivity({
          activity: REPOSITORY_ACTIVITY.REPOSITORY_NO_ACTIVITY,
        });
        const { requestId, syncDataType, direction } = completedRequest;

        const updatedRequestStage = createRequestStageUpdates(
          completedRequest,
          REQUEST_STAGE.REQUEST_SYNC_COMPLETE,
        );

        updateRequest({
          request: completedRequest,
          updates: updatedRequestStage,
        });

        let action;
        if (direction === 'pull') {
          syncPullCount++;
          action = 'REPLICATION_PULL_END';
        } else if (direction === 'push') {
          syncPushCount++;
          action = 'REPLICATION_PUSH_END';
        }

        a({
          type: OPERATION_END,
          data: { request: completedRequest },
          action,
        });

        const event = {
          source: repositoryId,
          type: SYNC_EVENT_TYPES.COMPLETED_PARTITION_REPLICATION_EVENT,
          data: {
            repositoryId,
            repositoryState: repositorySyncState,
            requestId,
            syncDataType,
            direction,
          },
        };

        a({
          type: EVENT_FIRE,
          data: {
            event,
          },
          action: 'REPLICATION_COMPLETED_EVENT',
        });

        eventsHub.dispatch(event);

        currentReplicationRequest = undefined;
      };

      /**
       * returns a promise which will be resolved when repository state either equals the receieved repository state or comparator returns true,
       * note that the comparator
       * @param {*} param0
       * @returns
       */
      const waitState = ({ state, comparator }) => {
        v({
          msg: 'wait state called',
          data: { repositoryState: state, comparator },
        });
        if (state && comparator) {
          e({
            msg:
              'Cant have both repositoryState && comparator, only one may be passed.',
            throwError: true,
            data: { repositoryState: state, comparator },
          });
        }
        if (!state && !comparator) {
          e({
            msg: 'None repositoryState && comparator, were defined.',
            throwError: true,
            data: { repositoryState: state, comparator },
          });
        }
        const repositoryStateFaultIncluded = !comparator
          ? // eslint-disable-next-line no-bitwise
            state | REPOSITORY_STATE.REPOSITORY_FAULT
          : undefined;
        return new Promise(resolve => {
          // eslint-disable-next-line no-shadow
          const stateVerification = verifiedState => {
            if (verifiedState == null || typeof verifiedState === 'undefined') {
              e({ msg: 'new repository state received is null or undefined' });
              return;
            }
            const comparatorTrue = comparator && comparator(verifiedState);
            const stateEqualTrue =
              !comparator && repositoryStateFaultIncluded
                ? // eslint-disable-next-line no-bitwise
                  repositoryStateFaultIncluded & verifiedState
                : 0;
            if (comparatorTrue || stateEqualTrue) {
              v({
                msg: 'wait state repository state flag',
                data: {
                  waitRepositoryState: verifiedState,
                  repositoryStateFaultIncluded,
                  state: verifiedState,
                  comparator,
                  comparatorTrue,
                  stateEqualTrue,
                },
              });
              resolve({ state: verifiedState });
            }
          };
          // We perform an initial verifcation on the current repositoryState as the repository may already be in the requested wait state.
          stateVerification(repositorySyncState);
          repositoryEventsEmitter.on({
            eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_STATE_CHANGED,
            subscriber: repositoryId,
            callback: ({ eventType, data }) => {
              const { state: newRepositoryState } = data;
              const repositoryStatesFromBitMaskArray = repositoryStatesFromBitMask(
                newRepositoryState,
              );
              v({
                msg: 'wait state repository state changed, comparing states',
                data: { newRepositoryState, repositoryStatesFromBitMaskArray },
              });
              const repositoryStatChanged =
                eventType === REPOSITORY_EVENT_TYPES.REPOSITORY_STATE_CHANGED;
              if (repositoryStatChanged) {
                stateVerification(newRepositoryState);
              }
            },
          });
        }).catch(error => {
          e({ msg: 'wait state error', error });
          throw error;
        });
      };

      const all = (options = {}) => pouchApi.all(options);

      const info = () => pouchApi.info();

      /**
       * TODO: Backward compatability api name support - perhaps will entirely replace all - did not have time to refactor so added - will review.
       * @param {*} options
       * @returns
       */
      const allDocs = options => all(options);

      const get = ({ id, options = {} }) => pouchApi.get({ id, options });

      const state = () => repositorySyncState;

      const find = options => pouchApi.find(options);

      const search = () => {};

      const put = ({ id, rev, doc }) => {
        const retval = pouchApi.put({ id, rev, doc });
        startSync({ config: { syncDataType: SYNC_DATA_TYPE.MODIFICATIONS } });
        return retval;
      };

      const post = ({ doc }) => {
        const retval = pouchApi.post({ doc });
        startSync({ config: { syncDataType: SYNC_DATA_TYPE.MODIFICATIONS } });
        return retval;
      };

      const remove = ({ id, rev }) => {
        const retval = pouchApi.remove({ id, rev });
        startSync({ config: { syncDataType: SYNC_DATA_TYPE.MODIFICATIONS } });
        return retval;
      };

      const bulkDocs = ({ docs }) => {
        const retval = pouchApi.bulkDocs({ docs });
        startSync({ config: { syncDataType: SYNC_DATA_TYPE.MODIFICATIONS } });
        return retval;
      };

      const count = () => {};

      const getIndex = () => {};

      const createIndex = options => pouchApi.createIndex(options);

      const removeIndex = () => {};

      return {
        startSync,
        cancelSync,
        info,
        all,
        allDocs,
        count,
        createIndex,
        find,
        get,
        getIndex,
        post,
        put,
        remove,
        bulkDocs,
        removeIndex,
        search,
        on,
        state,
        waitState,
        localDbName,
        login,
        logout,
        repositoryId,
        // note that localDocsCount/remoteDocsCount refer to the local variable not the returned function wrapper with same name;
        localDocsCount: () => localDocsCount,
        remoteDocsCount: () => remoteDocsCount,
        __testAPI__: { initRepositorySyncState },
      };
    };
    /**
     *
     * @param {{repositories:repository[],onSyncProgressEvent
     * :({ progress: { totalLocalDocsCount:number, totalRemoteDocsCount:number })=>void}} param0
     */
    // eslint-disable-next-line no-shadow
    const groupSyncProgress = ({ repositories, onSyncProgressEvent }) => {
      const sumProgress = () => {
        const {
          totalLocalDocsCount,
          totalRemoteDocsCount,
        } = repositories.reduce(
          // eslint-disable-next-line no-shadow
          (total, repository) => {
            const {
              totalLocalDocsCount: currentTotalLocalDocsCount,
              totalRemoteDocsCount: currentTotalRemoteDocsCount,
            } = total;
            const { localDocsCount, remoteDocsCount } = repository;
            const retval = {
              totalLocalDocsCount: currentTotalLocalDocsCount + localDocsCount,
              totalRemoteDocsCount:
                currentTotalRemoteDocsCount + remoteDocsCount,
            };
            return retval;
          },
          { totalLocalDocsCount: 0, totalRemoteDocsCount: 0 },
        );
        return { progress: { totalLocalDocsCount, totalRemoteDocsCount } };
      };

      const initialProgress = sumProgress();

      if (!navigator.onLine) {
        const {
          progress: { totalLocalDocsCount, totalRemoteDocsCount },
        } = initialProgress;
        const singleOfflineProgress =
          totalLocalDocsCount === totalRemoteDocsCount
            ? initialProgress
            : { ...initialProgress, error: 'OFFLINE_UNABLE_TO_SYNC' };
        onSyncProgressEvent(singleOfflineProgress);
      }
      // TODO : set unique subscriber id.
      // eslint-disable-next-line no-shadow
      repositories.forEach(repository => {
        repository.on({
          eventType: REPOSITORY_EVENT_TYPES.REPOSITORY_DATA_SYNC,
          callback: () => {
            const progress = sumProgress();
            onSyncProgressEvent(progress);
          },
          subscriber: 'groupSyncProgress',
        });
      });
      onSyncProgressEvent(initialProgress);
    };

    const mon = () => {
      const schedulerData = syncScheduler.mon();
      return schedulerData;
    };

    /**
     * Private apo to enable straight forward test API access without performing too many applicative API modificatios to make class/function
     * testable (e.g dependcny injection of eventshub for no reason besdies testing...)
     */
    const __syncManagerPrivateTestAPI = {
      eventsHub,
      SYNC_EVENT_TYPES,
      getServerDbsDocsCount,
    };

    return {
      getRepository,
      hasRepository,
      initRepository,
      initRepositoryBulk,
      removeRepository,
      allRepositories,
      groupFullSyncProgress: groupSyncProgress,
      syncContext,
      login,
      logout,
      repositoriesStats: () => cloneDeep(repositoriesStats),
      mon,
      on,
      SYNC_DATA_TYPE,
      SYNC_MANAGER_EVENT_TYPES,
      __syncManagerPrivateTestAPI,
    };
  } catch (error) {
    e({ msg: 'sync manager init failed', error });
  }
};

const defaultSyncManager = (() => {
  let defaultSyncManagerInstance;

  const createDefaultSyncManager = async () =>
    defaultSyncManagerInstance ||
    (async () => {
      defaultSyncManagerInstance = await createSyncManager({});
      if (typeof window !== 'undefined') {
        window.winpcsSyncManager = defaultSyncManagerInstance;
      }
      return defaultSyncManagerInstance;
    })();

  return createDefaultSyncManager;
})();

export default defaultSyncManager;

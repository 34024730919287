import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';

import { dispatcher } from './eventshub';

const { dispatch } = dispatcher;

/* eslint-disable no-shadow */
export const SEVERITY = {
  info: 'info',
  error: 'error',
  debug: 'debug',
  verbose: 'verbose',
  warning: 'warning',
  fatal: 'fatal',
};

const DEFAULT_LOGGER_SEVERITY = SEVERITY.info;

const severityOrder = new Map([
  [SEVERITY.debug, 1],
  [SEVERITY.verbose, 2],
  [SEVERITY.info, 3],
  [SEVERITY.warning, 4],
  [SEVERITY.error, 5],
  [SEVERITY.fatal, 6],
]);

const DEFAULT_LOGGER_SEVERITY_ORDER = severityOrder.get(
  DEFAULT_LOGGER_SEVERITY,
);

const uuid = require('node-uuid').v4;

const severityTypeConsoleOut = new Map([
  [SEVERITY.debug, console.debug],
  [SEVERITY.verbose, console.log],
  [SEVERITY.info, console.info],
  [SEVERITY.warning, console.warn],
  [SEVERITY.error, console.error],
  [SEVERITY.fatal, console.error],
]);

// Utility for managing console logging level in localStorage
const consoleLoggingLevel = 'loggingLevel';

function setConsoleDebugOn() {
  setConsoleLoggingLevel(SEVERITY.debug);
}

function setConsoleDebugOff() {
  setConsoleLoggingLevel(SEVERITY.info);
}

/**
 * Sets the logging level in localStorage.
 * @param {string} level - The logging level to set (debug, info, warning, error, fatal).
 */
function setConsoleLoggingLevel(level) {
  localStorage.setItem(consoleLoggingLevel, level);
}

/**
 * Retrieves the current logging level from localStorage.
 * Defaults to 'info' if no level has been set.
 * @returns {string} The current logging level.
 */
function getConsoleLoggingLevel() {
  return localStorage.getItem(consoleLoggingLevel) || DEFAULT_LOGGER_SEVERITY;
}

/**
 * Very simple types validation, usually under utils in case Typescript is not used, currently single type validation.
 */
const typeValidation = ({ properties, type }) => {
  Object.keys(properties).forEach(property => {
    const value = properties[property];
    const valueType = typeof value;
    const validValueType = valueType === type;
    if (!validValueType) {
      throw new Error(
        `Property ${property} has wrong type, expected ${type} actual ${valueType}`,
      );
    }
  });
};

/**
 * Logs messages with various severities to the console, depending on the current logging level.
 * @param {object} param0 - Log message parameters.
 * @param {string} param0.source - The source of the log message.
 * @param {string} param0.identifier - A unique identifier of the source - for multiple object from same source.
 * @param {string} [param0.severity='info'] - The severity of the log message.
 * @param {string} param0.msg - The log message text.
 * @param {object} param0.data - Additional data to log.
 */
const log = ({
  source = 'empty',
  identifier = 'empty',
  context = 'empty',
  severity = 'info',
  msg = 'empty',
  data = {},
}) => {
  try {
    typeValidation({
      properties: { source, msg, severity },
      type: 'string',
    });

    const identifierComputed = !isObject(identifier)
      ? identifier
      : JSON.stringify(identifier);

    const contextComputed = !isObject(context)
      ? context
      : JSON.stringify(context);

    // Check if the severity of the current log meets or exceeds the saved logging level
    const currentLevel = getConsoleLoggingLevel();
    const callseverityOrder =
      severityOrder.get(severity) || DEFAULT_LOGGER_SEVERITY_ORDER;
    const loggerSeverityOrder =
      severityOrder.get(currentLevel) || DEFAULT_LOGGER_SEVERITY_ORDER;

    const severityDeclared = severityTypeConsoleOut.has(severity);
    const loggedSeverity = severityDeclared
      ? severity
      : DEFAULT_LOGGER_SEVERITY;
    const loggedSeverityUpperCase = loggedSeverity.toUpperCase();

    const dataJSON = JSON.stringify(data, circularJSONStringifyReplacer());
    const dataValidation = JSON.parse(dataJSON);

    const time = logTime();
    const logEpoch = epoch();

    const qualifiedSource = `${source}/${identifierComputed}/${contextComputed}`;

    const logOut = {
      time,
      epoch: logEpoch,
      severity: loggedSeverityUpperCase,
      source,
      identifier: identifierComputed,
      context: contextComputed,
      qualifiedSource,
      msg,
      data: dataValidation,
    };

    const logOutString = stringifyLogMessage(logOut);

    if (callseverityOrder >= loggerSeverityOrder) {
      const consoleOut = severityTypeConsoleOut.get(loggedSeverity);
      const { queueLogCall: queueConsoleLogCall } = consoleLogQueue;
      queueConsoleLogCall(consoleOut, logOutString);
    }

    const appLogEventData = { logData: logOut, logDataString: logOutString };
    const appLogEvent = {
      source: 'logger',
      data: appLogEventData,
      channel: 'log',
    };
    dispatch(appLogEvent);
  } catch (logError) {
    console.error(`logError ${logError}`);
  }
};

const stringifyLogMessage = ({
  time,
  severity,
  source,
  identifier,
  msg,
  data,
}) =>
  JSON.stringify(
    { time, severity, source, identifier, msg, data },
    circularJSONStringifyReplacer(),
  );

const logTime = () => new Date().toISOString();
const epoch = () => new Date().getTime();

const consoleLogQueue = (() => {
  const MAX_CALLS_PER_SEC = 25;
  const ONE_SEC = 1000;

  const QUEUE_MAX_CALLS_OVERFLOW = 5000;

  const QUEUE_MAX_CALLS_OVERFLOW_MESSAGE = () => {
    console.error(`LOG MAX CALLS OVERFLOW ${QUEUE_MAX_CALLS_OVERFLOW}`);
  };

  let maxCallsOverflowMessageQueued = false;
  // eslint-disable-next-line prefer-const
  let lastLogCall = 0;
  // eslint-disable-next-line prefer-const
  let callsThreshold = 0;
  const logCallsQueue = [];
  const queueLogCall = (outFunc, logOutString) => {
    try {
      const outFuncCall = () => outFunc(logOutString); // We log a string and not an object because with objects we currently will have to manually expand it with click and if we export all messages to file only un-expanded messages will be exported.

      const now = Date.now();
      const timeDelta = now - lastLogCall;
      if (timeDelta < ONE_SEC) {
        callsThreshold++;
      } else {
        callsThreshold = 0;
      }
      const queueCall =
        logCallsQueue.length > 0 || callsThreshold > MAX_CALLS_PER_SEC;
      lastLogCall = now;
      if (queueCall) {
        if (logCallsQueue.length < QUEUE_MAX_CALLS_OVERFLOW) {
          logCallsQueue.push(outFuncCall);
        } else if (!maxCallsOverflowMessageQueued) {
          logCallsQueue.push(QUEUE_MAX_CALLS_OVERFLOW_MESSAGE);
          maxCallsOverflowMessageQueued = true;
        }
      } else {
        outFuncCall();
      }
    } catch (logError) {
      console.error(logError);
    }
  };

  const flushQueue = () => {
    while (logCallsQueue.length > 0) {
      const logCall = logCallsQueue.pop();
      logCall();
    }
    maxCallsOverflowMessageQueued = false;
  };

  const logCallsInQueueCount = () => logCallsQueue.length;

  // eslint-disable-next-line no-unused-vars
  const logQueueInterval = setInterval(flushQueue, 1500);

  return { queueLogCall, logCallsInQueueCount };
})();

export default log;

export const __testApi__ = {
  logQueue: consoleLogQueue,
  stringifyLogMessage,
  logTime,
};

// TODO: This must be part of general project common/utils
const circularJSONStringifyReplacer = () => {
  const seen = new WeakSet();

  return (key, value) => {
    let retval = value;
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        retval = undefined;
      } else {
        if (value instanceof Map) {
          const mapEntries = value.entries();
          const logArray = Array.from(mapEntries);
          retval = logArray;
        }
        seen.add(value);
      }
    }
    return retval;
  };
};

export const logger = ({ source = 'empty', identifier = 'empty' }) => {
  const i = ({ msg, data, identifier: identifierOverride, context }) => {
    const logIdentifier = !identifierOverride ? identifier : identifierOverride;
    log({
      source,
      identifier: logIdentifier,
      context,
      severity: SEVERITY.info,
      msg,
      data,
    });
  };

  const w = ({ msg, error, data, identifier: identifierOverride, context }) => {
    const logIdentifier = !identifierOverride ? identifier : identifierOverride;
    const errorData = { error, data };
    log({
      source,
      identifier: logIdentifier,
      context,
      severity: SEVERITY.warning,
      msg,
      data: errorData,
    });
  };

  const e = ({
    msg,
    error,
    data,
    throwError,
    identifier: identifierOverride,
    context,
  }) => {
    const logIdentifier = !identifierOverride ? identifier : identifierOverride;

    const { msg: errorMessage, stack: errorStack } = error || {
      msg: undefined,
      stack: undefined,
    };
    const computedMessage = `${msg} \n ${errorMessage}`;
    const computedData = { ...data, errorMessage, errorStack };
    log({
      source,
      identifier: logIdentifier,
      context,
      severity: SEVERITY.error,
      msg: computedMessage,
      data: computedData,
    });
    a({ type: AUDIT_TYPE.ERROR, msg, data: computedData });
    if (throwError) {
      throw new Error(msg);
    }
  };

  const d = ({ msg, data, identifier: identifierOverride, context }) => {
    const logIdentifier = !identifierOverride ? identifier : identifierOverride;
    log({
      source,
      identifier: logIdentifier,
      context,
      severity: SEVERITY.debug,
      msg,
      data,
    });
  };

  const v = ({ msg, data, identifier: identifierOverride, context }) => {
    const logIdentifier = !identifierOverride ? identifier : identifierOverride;
    log({
      source,
      identifier: logIdentifier,
      context,
      severity: SEVERITY.verbose,
      msg,
      data,
    });
  };

  const f = ({
    msg,
    error,
    data,
    throwError,
    identifier: identifierOverride,
    context,
  }) => {
    const logIdentifier = !identifierOverride ? identifier : identifierOverride;
    const { msg: errorMessage, stack: errorStack } = error || {
      msg: undefined,
      stack: undefined,
    };
    const computedMessage = `${msg} \n ${errorMessage}`;
    const computedData = { ...data, errorMessage, errorStack };
    log({
      source,
      identifier: logIdentifier,
      context,
      severity: SEVERITY.fatal,
      msg: computedMessage,
      data: computedData,
    });
    a({ type: AUDIT_TYPE.ERROR, msg, data: computedData });
    if (throwError) {
      throw new Error(msg);
    }
  };

  /**
   *
   * @param {AuditRecord} record
   */
  const a = ({ type, target, data: auditData, msg, message, action }) => {
    const compuetedAuditSourceAndIdentifier =
      source + (identifier && identifier !== 'empty' ? `/${identifier}` : '');
    // TODO: mistake - update msg to message in all places ....
    const computedRecord = {
      type,
      target,
      data: auditData,
      message: message || msg,
      action,
      source: compuetedAuditSourceAndIdentifier,
    };

    const logData = { type, action, target, ...auditData };
    if (type !== AUDIT_TYPE.ERROR) {
      // Place additional audit fields in the data property.
      d({ msg, data: logData });
    }
    audit(computedRecord);
  };

  return { d, v, i, w, e, f, a };
};

export const auditor = (() => {
  // eslint-disable-next-line prefer-const
  const auditRecordsArrays = [];
  auditRecordsArrays.push([]); // We start with one empty array.

  // TODO: add basic eq filtering - mainly source, perhaps also action.
  const auditRecords = ({ source } = {}) => {
    let retval;
    if (source) {
      const sourceArray = isArray(source) ? source : [source];
      const filteredAuditRecords = auditRecordsArrays
        .reduce((reducedValue, recordsArray) => {
          const combinedArray = reducedValue.concat(recordsArray);
          return combinedArray;
        }, [])
        .filter(
          record =>
            // eslint-disable-next-line eqeqeq
            sourceArray
              ? sourceArray.includes(record.source)
              : record.source === source,
        );
      retval = cloneDeep(filteredAuditRecords);
    } else {
      retval = cloneDeep(auditRecordsArrays);
    }
    return retval;
  };

  /**
   * @typedef AUDIT_TYPE
   */
  const AUDIT_TYPE = {
    OPERATION_BEGIN: 'OPERATION_BEGIN',
    OPERATION_END: 'OPERATION_END',
    OPERATION_CANCEL: 'OPERATION_CANCEL',
    OPERATION_FAIL: 'OPERATION_FAIL',
    STATE_CHANGE: 'STATE_CHANGE',
    EVENT_FIRE: 'EVENT_FIRE',
    EVENT_RECEIVED: 'EVENT_RECEIVED',
    DATA_PULL: 'DATA_PULL',
    DATA_PUSH: 'DATA_PUSH',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    DECLINE: 'DECLINE',
  };

  /**
   * The complete Triforce, or one or more components of the Triforce.
   * @typedef {Object} AuditRecord
   * id - audit id, auto generated
   * type - AUDIT_TYPE
   * timestamp
   * source
   * target
   * data
   * message - custom essage
   * action - specific action performed (TBD)
   */
  /**
   *
   * @param {AuditRecord} record
   */

  const audit = ({ type, source, target, data, message, action }) => {
    const id = uuid();
    const now = new Date();
    const isoString = now.toISOString();
    const computedRecord = {
      id,
      timpestamp: isoString,
      type,
      source,
      target,
      data,
      message,
      action,
    };

    const auditRecordsArray = auditRecordsArrays[auditRecordsArrays.length - 1];

    auditRecordsArray.push(computedRecord);

    // Cyclic queue, we store only 4 bulks of 2500 audit records and enqueue (shift) the oldest bulk when we reach the limit.
    if (auditRecordsArray.length === 2500) {
      if (auditRecordsArrays.length === 4) {
        auditRecordsArrays.shift();
      }
      auditRecordsArrays.push([]);
      // TODO : See how to properly dump to CSV and upload to storage before clearing the audit logs, currently for R&D debugging non-production usage.
    }
  };

  const dump = () => {
    // TODO : implement
  };
  return { audit, AUDIT_TYPE, auditRecords, dump };
})();

const { audit, auditRecords, dump, AUDIT_TYPE } = auditor;

if (typeof window !== 'undefined') {
  window.setLoggingLevel = setConsoleLoggingLevel;
  window.getLoggingLevel = getConsoleLoggingLevel;
  window.setConsoleDebugOn = setConsoleDebugOn;
  window.setConsoleDebugOff = setConsoleDebugOff;
  window.auditRecords = auditRecords;
  window.dump = dump;
}

if (typeof global !== 'undefined') {
  global.setLoggingLevel = setConsoleLoggingLevel;
  global.getLoggingLevel = getConsoleLoggingLevel;
  global.setConsoleDebugOn = setConsoleDebugOn;
  global.setConsoleDebugOff = setConsoleDebugOff;
  global.auditRecords = auditRecords;
  global.dump = dump;
}

import { useState, useEffect, useCallback, useMemo } from 'react';

import { listener } from '../../../utils/eventshub';

const { listen, unlisten } = listener;

// COMMENT: This hook is intended to be decoupled of RA and can be called in an HOC or context wrapping RA, like in our scenario - this does not rely on RA hooks.
const useAppIdentity = () => {
  const initialAppIdentity = useMemo(() => {
    // TODO: localStroage used by authProvider should have a wrapped safe access to enable access across the app and prevent misuse.
    const username = localStorage.getItem('username');
    const permissions = localStorage.getItem('permissions');
    const awsAccessToken = localStorage.getItem('access_token');
    const loginState = username && permissions ? 'loggedIn' : 'loggedOut';
    const retval =
      loginState === 'loggedIn'
        ? { username, permissions, loginState, awsAccessToken }
        : { loginState };
    return retval;
  }, []);
  const [appIdentity, setAppIdentity] = useState(initialAppIdentity);

  const onAuthEventHandler = useCallback(event => {
    const {
      loginState,
      username,
      permissions,
      userId,
      awsAccessToken,
    } = event?.data;
    if (loginState === 'loggedIn') {
      setAppIdentity({
        loginState,
        username,
        permissions,
        userId,
        awsAccessToken,
      });
    } else {
      setAppIdentity({ loginState: 'loggedOut' });
    }
  });

  useEffect(() => {
    listen({
      channel: 'auth',
      callback: onAuthEventHandler,
      listenerName: 'useAppIdentity',
    });
    return () => {
      unlisten({ channel: 'auth', callback: onAuthEventHandler });
    };
  }, []);

  return appIdentity;
};

export default useAppIdentity;

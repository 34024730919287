export const SET_AUTH = 'SET_AUTH';
export const SET_ALL_DB_SYNCED = 'SET_ALL_DB_SYNCED';
export const SET_DB_SYNCED = 'SET_DB_SYNCED';

export const setAuth = isLoggedIn => ({
  type: SET_AUTH,
  payload: { isLoggedIn },
});

export const setAllDbSynced = allDbsSynced => ({
  type: SET_ALL_DB_SYNCED,
  payload: { allDbsSynced },
});

export const setDbSynced = (db, synced) => ({
  type: SET_DB_SYNCED,
  payload: { db, synced },
});

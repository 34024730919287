import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useRefresh } from 'react-admin';
import { Snackbar, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { logger } from '../../../utils/logger';

import { appMakeStyles } from './AppThemeManager';

const { d: loggerDebug } = logger({ source: 'SyncRefreshNotificationBar' });

const useStyles = appMakeStyles(theme => ({
  blue: { color: theme.palette.primary.main },
  dataGrid: {
    '& div > div > button': {
      display: 'none',
    },
  },
  refreshNotification: {
    backgroundColor: theme.palette.header.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      left: 0,
      zIndex: 10000,
    },
  },
  refreshNotificationButton: {
    background: 'transparent',
    border: 'none',
    color: theme.palette.primary.contrastText,
    padding: '6px 12px',
  },
}));

// As the component might be unmounted and remounted, we need to keep track of the active refresh call to prevent multiple refresh calls.
// TODO : consider using/implementing global cache service, check session persistency issues.
const EMPTY_CACHE = -1;
const dataChangesCache = (() => {
  const cache = new Map();

  const getItem = key => {
    const value = cache.get(key);
    const retval = value !== undefined ? value : EMPTY_CACHE;
    return retval;
  };

  const setItem = (key, value) => {
    cache.set(key, value);
  };

  return {
    getItem,
    setItem,
  };
})();

export const SyncRefreshNotificationBar = props => {
  const classes = useStyles();
  const [displayRefreshNotification, setDisplayRefreshNotification] = useState(
    false,
  );
  const refresh = useRefresh();
  const activeRefreshCall = useRef(false);
  const pendingRefreshNotification = useRef(false);
  const previousDataChangesCount = useRef(0);

  const { d } = useMemo(
    () => {
      const retval =
        props?.dbNames?.length > 0
          ? logger({
              source: 'SyncRefreshNotificationBar',
              identifier: props.dbNames,
            })
          : { d: loggerDebug };
      return retval;
    },
    [props.dbNames],
  );

  useEffect(
    () => {
      if (!props?.syncState || !props?.dbNames) {
        return;
      }

      const { syncState, dbNames } = props || {};

      const validDbNames = !!(dbNames?.length > 0);

      const validSyncState =
        syncState?.dataChangesCount !== undefined &&
        !!(syncState?.localDocsCount > 0);
      // HOTFIX: In  hierarchy, there are some missing databases, so checking if aggregated repositoryState>= 16 would return false (lowest common repositoryState is 1 = empty)
      // In practice, we are interested in verifying that syncData has been initialized with valid data from syncManager and that localDocsCount > 0.
      // While this is not formally fully correct, it suffices for our purposes here.
      // Need to go through all dbs in SYMT and clear inexistent ones.
      if (!validDbNames || !validSyncState) {
        return;
      }

      const { dataChangesCount } = props.syncState;

      const dbNamesKey = JSON.stringify(props.dbNames);

      const previousDataChangesCountValue = dataChangesCache.getItem(
        dbNamesKey,
      );

      const previousDataChangesCoutValueExists =
        previousDataChangesCountValue !== EMPTY_CACHE;

      // The naunce is that dataChangesCount > previousDataChangesValue as it can be passed as 0 again on remounts until data re-propogates from CouchDbReplicaProvierContext to SyncRefreshNotificationBar
      // thus, if simply dataChangesCount != previousDataChangesCountValue, would return a false positive.
      const newSyncStatsTrack =
        dataChangesCount &&
        previousDataChangesCoutValueExists &&
        dataChangesCount > previousDataChangesCountValue;

      // Simple turthiness check to avoid human 'enable' errors (true/'true'/1)
      const ENABLE = !!localStorage.getItem(
        'ENABLE_SYNC_REFRESH_NOTIFICATION_BAR',
      );

      if (newSyncStatsTrack && ENABLE) {
        d({
          msg: 'SyncRefreshNotificationBar Show',
          data: {
            newSyncStatsTrack,
            dataChangesCount,
            previousDataChangesCount: previousDataChangesCount.current,
          },
        });
        // In case of new data available, show the refresh notification, if active refresh call is in progress, then set pending refresh notification.
        if (displayRefreshNotification === false) {
          if (activeRefreshCall.current === false) {
            setDisplayRefreshNotification(true);
          } else {
            pendingRefreshNotification.current = true;
          }
        }
      }

      // Initial 0 state and subsequent data changes, 0 would be passed again un remounts till data is repropogates to the comopnent.
      const updateCache =
        (dataChangesCount === 0 &&
          previousDataChangesCountValue === EMPTY_CACHE) ||
        (dataChangesCount > 0 &&
          dataChangesCount > previousDataChangesCount.current);

      if (updateCache) {
        dataChangesCache.setItem(dbNamesKey, dataChangesCount);
      }
    },
    [props.dbNames, props.syncState],
  );

  const refreshNotificationOnClick = useCallback(() => {
    if (activeRefreshCall.current === true) {
      return;
      // TODO: do not do anything while alreaday refreshing.
    }
    activeRefreshCall.current = true;
    setDisplayRefreshNotification(false);
    refresh();
    // While refreshing we prevent from displaying the refresh notification for 15 seconds which is the estimated refresh time (refresh() does not offer a way to track reload progress), after which we check
    // if ther ehas been additional data change in that same period of time which would set the pendingRefreshNotification to true, in that case we display the refresh notification again.
    setTimeout(() => {
      activeRefreshCall.current = false;
      if (pendingRefreshNotification.current == true) {
        setDisplayRefreshNotification(true);
        pendingRefreshNotification.current = false;
      }
    }, 15000);
  });

  return (
    <Snackbar
      open={displayRefreshNotification}
      ContentProps={{
        className: classes.refreshNotification,
      }}
      message="New data is available, please refresh."
      action={
        <>
          <Button
            size="small"
            className={classes.refreshNotificationButton}
            onClick={refreshNotificationOnClick}
          >
            Refresh
          </Button>
          <Button
            className={classes.refreshNotificationButton}
            size="small"
            onClick={() => {
              setDisplayRefreshNotification(false);
            }}
          >
            Dismiss
          </Button>
        </>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    />
  );
};

export default SyncRefreshNotificationBar;

SyncRefreshNotificationBar.propTypes = {
  dbNames: PropTypes.arrayOf(PropTypes.string),
  syncState: PropTypes.object,
};

window.enableSyncRefreshNotificationBar = () => {
  localStorage.setItem('ENABLE_SYNC_REFRESH_NOTIFICATION_BAR', true);
};

window.disableSyncRefreshNotificationBar = () => {
  localStorage.setItem('ENABLE_SYNC_REFRESH_NOTIFICATION_BAR', false);
};

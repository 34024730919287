import React, { forwardRef, useEffect, useState, useContext } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import ChangelogIcon from '@material-ui/icons/Redeem';
import { useMediaQuery } from '@material-ui/core';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { deepPurple, deepOrange } from '@material-ui/core/colors';
import useOnlineStatus from '@rehooks/online-status';
import useDarkMode from 'use-dark-mode';

import { appMakeStyles } from './AppThemeManager';
import Logo from './Logo';
import AppSettingsContext from './AppSettingsContext';

const useStyles = appMakeStyles(theme => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  spacer: {
    flex: 1,
  },
  toolbar: {
    backgroundColor: theme.palette.header.main,
    '& .MuiIconButton-root': {
      [theme.breakpoints.down('sm')]: {
        paddingRight: '6px',
        paddingLeft: '6px',
      },
    },
  },
  avatar: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    maxWidth: '25px',
    maxHeight: '25px',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  onlineStatus: {
    color: '#fff',
    position: 'fixed',
    right: '129px',
    top: '12px',
    maxWidth: '25px',
    maxHeight: '25px',
  },
  offlineStatus: {
    color: '#FFA500',
    position: 'fixed',
    right: '127px',
    top: '12px',
    maxWidth: '25px',
    maxHeight: '25px',
  },
  network: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: '50%',
      position: 'fixed',
      top: '2px',
      right: '118px',
      width: '50px',
      height: '46px',
      cursor: 'pointer',
    },
  },
  networkBgColorDark: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}));

const ConfigurationMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  );
});

const ChangelogMenu = forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/changelog"
      primaryText={translate('pos.changelog')}
      leftIcon={<ChangelogIcon />}
      onClick={props.onClick}
    />
  );
});

const CustomUserMenu = props => {
  // Comment : this line would cause theme update re-rendering, withoutout would not always occur.
  const { theme } = useContext(AppSettingsContext);

  const classes = useStyles();
  const [firstInitial, setFirstInitial] = useState('');
  // TODO: remove darkmode dependency.
  const darkMode = useDarkMode(false);

  useEffect(() => {
    setFirstInitial((localStorage.getItem('username') || ' ')[0].toUpperCase());
  }, []);

  return (
    <UserMenu
      {...props}
      icon={
        <Avatar
          className={classnames(classes.avatar, {
            [classes.purple]: darkMode.value,
            [classes.orange]: !darkMode.value,
          })}
        >
          {firstInitial}
        </Avatar>
      }
    >
      <ConfigurationMenu />
      <ChangelogMenu />
    </UserMenu>
  );
};

const CustomAppBar = ({ className, ...props }) => {
  const { theme: appTheme } = useContext(AppSettingsContext);
  const classes = useStyles();
  const isNotSmall = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const onlineStatus = useOnlineStatus();
  const translate = useTranslate();
  const darkMode = useDarkMode(false);

  return (
    <AppBar
      className={classnames(className, classes.toolbar)}
      {...props}
      userMenu={<CustomUserMenu />}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {isNotSmall && <Logo />}
      <div
        className={classnames(classes.network, {
          [classes.networkBgColorDark]: darkMode.value,
        })}
      >
        <Tooltip title={translate(onlineStatus ? 'online' : 'offline')}>
          {onlineStatus ? (
            <CloudIcon className={classes.onlineStatus} />
          ) : (
            <CloudOffIcon className={classes.offlineStatus} />
          )}
        </Tooltip>
      </div>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;

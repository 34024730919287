const wrappers = require('pouchdb-wrappers');
const uuid = require('node-uuid').v4;

const enableUndo = function(options) {
  if (!options) {
    options = {};
  }
  if (!options.hasOwnProperty('limit')) {
    options.limit = 100;
  }
  const db = this;

  const PouchDB = this.constructor;

  const error = function(options) {
    const error = new Error(options.reason);
    error.status = options.status;
    error.error = options.error;
    error.reason = options.reason;
    return error;
  };

  const origBulkDocs = db.bulkDocs;

  const wrapUndo = function(orig, args) {
    const docs = args.docs;
    return orig().then(result => {
      const undoId = uuid();
      return db
        .get('_local/_undo')
        .catch(err => {
          if (err.status !== 404) {
            throw err;
          }
          return { _id: '_local/_undo', history: [], undos: {} };
        })
        .then(undoDoc => {
          undoDoc.history.push(undoId);
          undoDoc.undos[undoId] = result
            .filter(row => row.ok)
            .map((row, i) => ({
              id: row.id,
              oldRev: docs[i]._rev,
              newRev: row.rev,
            }));
          if (undoDoc.history.length > options.limit) {
            undoDoc.history.slice(0, -options.limit).forEach(undoId => {
              delete undoDoc.undos[undoId];
              undoDoc.history.shift();
            });
          }
          return origBulkDocs.call(db, [undoDoc]); // FIXME: pouchdb bug? doesn't throw conflict if rev is incorrect
        })
        .then(() =>
          result.map(row => {
            row.undoId = undoId;
            return row;
          }),
        );
    });
  };

  db.undo = function(undoId) {
    return db
      .get('_local/_undo')
      .then(undoDoc => {
        const revisions = undoDoc.undos[undoId];
        if (typeof revisions === 'undefined') {
          throw error({
            status: 404,
            error: 'not_found',
            reason: 'Undo with that ID not found',
          });
        }
        return Promise.all(
          revisions.map(revision =>
            // get latest revision
            db.get(revision.id, { open_revs: 'all' }).then(doc => {
              const latestRev = doc[0].ok._rev; // HACK: this will stop undo working where there are unresolved conflicts
              if (revision.newRev !== latestRev) {
                throw error({
                  status: 409,
                  error: 'conflict',
                  reason:
                    'The document has changed since this undoID was issued',
                });
              }
              return db.get(revision.id, { rev: revision.oldRev }).then(doc => {
                if (!revision.oldRev) {
                  doc._deleted = true;
                }
                doc._rev = revision.newRev;
                return doc;
              });
            }),
          ),
        )
          .then(docs => origBulkDocs.call(db, docs))
          .then(() => {
            delete undoDoc.undos[undoId];
            return origBulkDocs.call(db, [undoDoc]);
          });
      })
      .then(() => ({ id: undoId, ok: true }));
  };

  db.clearUndo = function() {
    return db
      .get('_local/_undo')
      .then(doc => {
        doc._deleted = true;
        return origBulkDocs.call(db, [doc]);
      })
      .catch(err => {
        if (err.status !== 404) {
          throw err;
        }
        // already deleted
      });
  };

  wrappers.installWrapperMethods(db, { bulkDocs: wrapUndo });
};

if (typeof window !== 'undefined' && window.PouchDB) {
  window.PouchDB.plugin({ enableUndo });
}

export default { enableUndo };

import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { appMakeStyles } from './AppThemeManager';

// import complanDarkLayoutLogo from './complan_logo_white.png';
import complanLightLayoutLogo from './complan_logo_white.png';

const useStyles = appMakeStyles(theme => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '10px',
      marginTop: '15px',
    },
  },
}));

const Logo = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <Link to="/">
      <img
        width={135.02}
        height={20.475}
        src={complanLightLayoutLogo}
        alt="Complan Logo"
        className={classnames(className, classes.logo)}
        {...props}
      />
    </Link>
  );
};

export default Logo;

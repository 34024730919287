import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Line } from 'rc-progress';
import Paper from '@material-ui/core/Paper';
import { CircularProgress } from '@material-ui/core';

import { appMakeStyles } from './layout/AppThemeManager';

const useStyles = appMakeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxHeight: '300px',
      height: '100%',
      paddingTop: '10px',
      [theme.breakpoints.down('sm')]: {
        gap: '5px',
      },
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    progressBar: {
      display: 'flex',
      gap: '5px',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        marginLeft: '30px',
        minHeight: '100px',
        alignContent: 'center',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    line: {
      marginTop: '15px',
      marginBottom: '15px',
      [theme.breakpoints.up('md')]: {
        maxHeight: '12px',
        flexBasis: '85%',
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: '20px',
        minHeight: '10px',
        width: '90%',
        order: 3,
      },
    },
    lineAlignment: {
      marginLeft: '10px',
    },
    recordsCount: {
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.7,
      padding: '10px 0',
      minWidth: '100px',
      [theme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    progressBarTitle: {
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.7,
      padding: '10px 0',
      [theme.breakpoints.down('sm')]: {
        order: 1,
        marginLeft: '35px',
      },
    },
    message: {
      fontFamily: 'Roboto, sans-serif',
      display: 'block',
      marginRight: '20px',
      overflow: 'hidden',
      textAlign: 'left',
      opacity: 0.7,
      marginBottom: '5px',
      minWidth: '80px',
      maxWidth: '80px',
    },
    messageWrapper: {
      order: 4,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-start',
      flexBasis: '85%',
      flexWrap: 'wrap',
      flexFlow: 'column',
      minHeight: '75px',
    },
    circularProgress: {
      color: theme.palette.primary.main,
      marginLeft: '20px',
      marginBottom: '5px',
    },
  }),
  { name: 'RaLoading' },
);

const RecordSyncIndicator = ({
  className,
  percentage,
  fromRecords = 0,
  toRecords,
  text,
  title,
  showHangLoading = false,
}) => {
  const classes = useStyles();

  const calcaultedPrecentage =
    toRecords > 0 ? (fromRecords / toRecords) * 100 : 0;
  const displayPrecentage =
    calcaultedPrecentage > 0 ? calcaultedPrecentage : percentage || 0;
  return (
    <Paper className={classnames(classes.container, className)}>
      <div className={classes.progressBar}>
        <div className={classes.progressBarTitle}>{title}</div>
        <Line
          strokeWidth="1"
          strokeColor="#00138E"
          percent={displayPrecentage}
          className={classes.line}
        />
        <div className={classes.recordsCount}>
          {fromRecords} / {toRecords}
        </div>
        <div className={classes.messageWrapper}>
          <>
            {text && <span className={classes.message}>{text}</span>}
            {showHangLoading && (
              <CircularProgress
                className={classes.circularProgress}
                size={30}
              />
            )}
          </>
        </div>
      </div>
    </Paper>
  );
};

RecordSyncIndicator.propTypes = {
  className: PropTypes.string,
  percentage: PropTypes.number,
  text: PropTypes.string,
  fromRecords: PropTypes.number,
  toRecords: PropTypes.number,
  title: PropTypes.string,
  showHangLoading: PropTypes.bool,
};

export default RecordSyncIndicator;
